import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Operator, SpinnerService } from '../core';
import { OperatorService } from '../core/services/operator.service'
import { JwtService } from '../core/services/jwt.service'
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  constructor(
    private operatorService: OperatorService,
    private spinner: SpinnerService,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService
  ) {
    this.currentOperator = {} as Operator;
  }

  currentOperator: Operator;

  ngOnInit(): void {

    this.spinner.show();
    this.mixpanelService.sendScreenVisitEvent("Welcome")
    this.operatorService.getJwtToken().subscribe({
      next: (data) => {
        if (data != "") {
          // this.operatorService.getOperatorDetail().subscribe(
          // {
          //   next:(data)=>{
          //     this.spinner.hide();
          //     this.currentOperator = data;
          //   },
          //   error: (e) => console.error(e),
          //   complete: () => this.spinner.hide() 
          // }

          // );
        }
      },
      error: (e) => { this.spinner.hide() },
      complete: () => this.spinner.hide()
    });

    this.renderer.addClass(document.body, 'translator');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'translator');
  }
}
