import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages, PropertyService, SpinnerService } from '../core';

@Component({
  selector: 'app-property-lock',
  templateUrl: './property-lock.component.html',
  styleUrls: ['./property-lock.component.scss']
})
export class PropertyLockComponent implements OnInit {

  accessPointId: number = 0;
  reservationId: number = 0;
  lockDetail: any;
  accessPoint: any;
  isAccessPointHaveLock: boolean = true;
  isTapToUnlockPress: boolean = false;
  isTapToUnlockResult: boolean = false;
  retryCount: number = 0;
  maxRetryAllow:number = 1;
  canUnlock:boolean = true;
  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
      this.reservationId = Number(this.route.snapshot.params['rid']);
      this.accessPointId = Number(this.route.snapshot.params['id']);
   }

  ngOnInit(): void {
    this.getPropertyAccessPoint(this.reservationId);
    this.getaccessPointLockDetail();
  }

  getPropertyAccessPoint(reservationId: number){
    this.propertyService.getPropertyAccessPoint(reservationId).subscribe(
      {
        next:(response)=>{
          if(response && response.code == HttpStatusCode.Ok && response.data){
              var accessPoints = response.data.accessPoints; 
              if(accessPoints){
                this.accessPoint = accessPoints.find((x:any) => x.accessPointId == this.accessPointId);
                if(this.accessPoint && this.accessPoint.locks && this.accessPoint.locks.length > 0){
                  this.isAccessPointHaveLock = true;
                }
                else{
                  this.isAccessPointHaveLock = false;
                }
              }
          }
        },
        error:(err)=>{
        }      
      }
    );
  }
  getaccessPointLockDetail(){
    this.spinner.show();

    this.propertyService.accessPointLockDetail(this.accessPointId)
        .subscribe(
          {
            next: (response) => {
              if(response && response.code == HttpStatusCode.Ok && response.data){
                this.lockDetail = response.data;
                this.isAccessPointHaveLock = true;
              }
              else{
                this.isAccessPointHaveLock = false;
              }
            },
            error: (e) => {

            },
            complete: () => this.spinner.hide()
          }
        )
  }

  SetOrUpdatedKeyCodeOnLock(){
    
    
    if(this.retryCount < 1){
      this.isTapToUnlockPress = true;
      var setUpdateKeycodeRequest = {
        accessPointId : this.accessPointId,
        reservationId : this.reservationId
      }
      this.propertyService.SetOrUpdatedKeyCodeOnLock(setUpdateKeycodeRequest)
      .subscribe(
        {
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.retryCount++;
              
              this.isTapToUnlockResult = true;
                
                setTimeout(()=>{
                  setTimeout(()=>{
                    this.isTapToUnlockResult = false;
                    this.isTapToUnlockPress = false;
                    this.canUnlock = true;
                  }, 2000);
                  this.getPropertyAccessPoint(this.reservationId);
                }, 3000);
            }
            else{
              this.toastr.error(ErrorMessages.InternalServerError);
              this.isTapToUnlockPress = false;
              this.canUnlock = true;
            }
          },
          error: (e) =>{
            this.toastr.error(ErrorMessages.InternalServerError);

            this.isTapToUnlockPress = false;
          }
        }
      )
    }
    else{
      this.canUnlock = false;
    }
    
  }
}
