<ng-container>
  
    <div class="title-bar-space d-block">
      
        <div class="container">
            <div class="guest_verification mt-4">
                <img src="../../../assets/images/checkin_required.svg" alt="">
                <h2 class="mt-5">It’s Time To Check-in</h2>
                <h4 class="heading-title mt-4"><strong>Your host is requesting a few details about your upcoming stay.</strong></h4>       
                <p class="mt-3">This is a required step to view access and property information.</p>         
            </div>

        </div>
      
    </div>
    
    <div class="container">
        <a href="javascript:void(0)" class="btn btn-primary w-100 mt-5" (click)="doChangeStep()">Get Started</a>
        <button type="button" class="btn btn-secondary w-100 mt-2 learn-more" (click)="showCommonTermsCondition()">Learn More</button>
    </div>
</ng-container>


<!-- <ng-container *ngIf="!adjustImage">
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <h2>Guest Verification</h2>
          </div>
        </div>
      </div>
    
      <div class="title-bar-space collect_government">
        <div class="alignitems-flex">
          
                <ng-container  *ngIf="!isShowCamera && !imageCaptured">
                    <div class="container">
                        <h2 class="mb-5">Almost done! We just need to verify government issued your ID.</h2>
          
                        <h2 class="mb-5">Take a photo of your government issued ID</h2>
                  
                  
                        <div class="notfound_box collect_government_box mt-5">
                          <div class="text-center"><img src="../../../assets/images/collect_government_Id.png" alt="icon"></div>
                          <a href="javascript:void(0)" class="btn btn-primary white_btn mt-3 w-100" (click)="useCamera()">Use Camera</a>
                        </div>
                    </div>
                  
                </ng-container>
                <ng-container *ngIf="isShowCamera && !imageCaptured">
                    <div class="container" *ngIf="isCameraAvaialable">
                        <div class="webcame_box">
                            <webcam
                            [width]="width"
                            [height]="height"
                            [imageQuality]="1"
                            [trigger]="invokeObservable"
                            (imageCapture)="captureImg($event)"
                            (initError)="handleInitError($event)"
                          ></webcam>
                        </div>
                    </div>
                    <div class="container" *ngIf="isCameraAvaialable">
                        <button class="webcome_startbutton" (click)="getSnapshot()">
                            <span></span>
                          </button>
                    </div>
                    <div class="alignitems-flex" style="justify-content: center;" *ngIf="!isCameraAvaialable">
                      <div class="container">
                        <div class="notconnect_camera">
                          <img src="../../../assets/images/nofound_camera.svg" alt="icon"/>
                          <p class="mt-4">{{cameraErrorText}}</p>
                        </div>
                      </div>
                    </div>                  
                  
                </ng-container>
                <ng-container *ngIf="!isShowCamera && imageCaptured">
                    <div class="container">
                        <div class="webcame_box">
                            <img [src]="webcamImage?.imageAsDataUrl" />
                        </div>
                    </div>
                    <div class="container">
                        <button class="button_text" (click)="retryImage()">Retry</button>
                  <button class="button_text" (click)="adjustingImage()">Ok</button>
                    </div>
                </ng-container>
                
                
             
        </div>
        
        
      </div>
</ng-container>

  <ng-container  *ngIf="adjustImage">
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <a href="javascript:void(0)" class="back-page"></a>
            <h2>Edit Photo</h2>
            <button class="check-tick" (click)="selectIDProof()"></button>
          </div>
        </div>
      </div>
      <div class="title-bar-space collect_government">
        <div class="alignitems-flex" style="justify-content: center;">
            <div class="container mt-5">
                <div class="webcame_box" >
                    <image-cropper
                        [imageBase64]="sysImage"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="3/4"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div> 
                
            </div>
        </div>
        
      </div>
    
  </ng-container> -->
