import { Component } from '@angular/core';
import { CommonService, User, UserService } from '../../core';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  today: number = Date.now();
  constructor(
    private userService: UserService,
    private commonService: CommonService
    ) {
    this.isAuth = false;
  }

  isAuth: boolean | undefined;
  isShowDownloadAppBanner: boolean = false;

  ngOnInit(): void {
    this.userService.isAuthenticated.subscribe(
      (isAuth) => {
        this.isAuth = isAuth;
      }
    );

    this.commonService.showDownloadApp.subscribe(
      (isShow) => {
        this.isShowDownloadAppBanner = isShow;
      }
    );
  }

  hideDownloadAppBanner(){
    this.commonService.setShowDownloadApp(false);
  }
}
