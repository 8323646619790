import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { GuestDetailComponent } from './guest-detail.component';
import { GuestDetailRoutingModule } from './guest-detail-routing.module';

@NgModule({
  imports: [
    SharedModule,
    GuestDetailRoutingModule
  ],
  declarations: [
    GuestDetailComponent
  ],
  providers: [    
  ]
})
export class GuestDetailModule {}
