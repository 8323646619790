import { Injectable } from '@angular/core'
import { map } from 'rxjs'
import { ApiService } from './api.service'

@Injectable()
export class CommonApiService{

    constructor(
        private apiService: ApiService
        ) {
        
    }

    getCmsPage(pageId: number){
      return this.apiService.get('/v1/cms/getmobilecmspage?MobileCMSPageID='+ pageId)
      .pipe(map(
        data => {          
          return data;
        }
      ));
    }
    

}
