import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckInModuleStageEnum, PropertyService, SpinnerService } from 'src/app/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-damage-waiver-landing',
  templateUrl: './damage-waiver-landing.component.html',
  styleUrls: ['./damage-waiver-landing.component.scss']
})
export class DamageWaiverLandingComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() unitId: number = 0;
  @Input() securityTransactionStageSkip:boolean = false;
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackToPreviousStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  propertyUnit: any;
  damageWaiverFee: number = 0;
  securityDepositFee: number = 0;
  damageWaiverCoverageAmount:number = 0;
  constructor(
    private propertyService: PropertyService,
    private spinner: SpinnerService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.getUserUnits(this.unitId, this.reservationId);
  }

  getUserUnits(unitId: number, reservationId: number){
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
        next:(response)=>{
          if(response && response.code == HttpStatusCode.Ok && response.data){
              if(response.data.units && response.data.units.length > 0){
                var propertyUnits = response.data.units.find((x:any) => x.reservationID == reservationId && x.unitID == unitId);
                if(propertyUnits){
                  this.propertyUnit = propertyUnits;

                  if(this.propertyUnit 
                    && this.propertyUnit.checkInSequence.securityTransactions
                    && (this.propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled
                      || this.propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled)
                    ){
                    this.damageWaiverFee = this.propertyUnit.checkInSequence.securityTransactions.damageWaiverFee;
                    this.securityDepositFee = this.propertyUnit.checkInSequence.securityTransactions.securityDepositFee;
                    this.damageWaiverCoverageAmount = this.propertyUnit.checkInSequence.securityTransactions.damageWaiverCoverageAmount;
                  }
                  // else{
                  //   this.router.navigateByUrl("/home/"+this.reservationId)
                  // }
                }            
              }            
          }
        },
        error:(e)=>{
          this.spinner.hide();
          //this.router.navigateByUrl("/home/"+this.reservationId)
        },
        complete:()=>{
          this.spinner.hide();
        }
      }
    );
  }

  doChangeStep(step:string) {
    
    this.changeChildStep.emit({"currentStep":step, "direction":"forward"});
  }

  goToPage(pageName:string){
    this.goBackToPreviousStep.emit();
    //this.router.navigateByUrl(`/${pageName}/${this.reservationId}`)
  }
  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.SecurityTransactions],"stepValue":CheckInModuleStageEnum.SecurityTransactions});

  }

  moduleComplete(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.SecurityTransactions],"stepValue":CheckInModuleStageEnum.SecurityTransactions, "action":"complete"});
  }
}
