import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs';

@Injectable()
export class UpsellService{

    constructor(
        private apiService:ApiService
    ){

    }

    getPropertyUpsells(reservationId: number, unitId: number,pageNumber: number){
        return this.apiService.get('/v6/upsells/upsellitems?ReservationId=' + reservationId + '&unitID='+ unitId)
            .pipe(map(
                response =>{
                    return response;
                }
            ))
    }

    getPropertyUpsellDetail(reservationId: number, upsellId: number){
        return this.apiService.get('/v2/upsells/GetReservationUpsellDetails?itemID='+ upsellId + '&ReservationId=' + reservationId)
            .pipe(map(
                response =>{
                    return response;
                }
            ))
    }

    getGuestRequestedUpsells(){
        return this.apiService.get("/v5/Transactions/GuestPurchases")
            .pipe(map(
                response => {
                    return response;
                }
            ))
    }

    getStripePaymentMethods(){
        return this.apiService.get("/v5/payment/methodkeys")
            .pipe(map(
                response => {
                    return response;
                }
            ))
    }

    addPaymentMethod(request:any){
        return this.apiService.post("/v5/payment/methodkey/create", request)
        .pipe(map(
            response => {
                return response;
            }
        ))
    }

    sendUpsellRequest(request:any){
        return this.apiService.post("/v5/upsells/SendUpsellGuestRequestDetails", request)
        .pipe(map(
            response => {
                return response;
            }
        ))
    }

    
}
