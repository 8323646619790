<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/home" class="back-page"></a>
        <h2>Reservation Lookup</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">
          <h1 class="heading-title">Having issues with your reservation?</h1>
          <form class="mt-5 form-flex" [formGroup]="findReservationInfoForm">
            <div>
              <label class="form-label">Reservation Number</label>
              <input type="text" class="form-control" formControlName="reservationNumber" [ngClass]="{ 'is-invalid': submitted && f.reservationNumber.errors }" autocomplete="off" />

              <div *ngIf="submitted && f.reservationNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.reservationNumber.errors['required']">Reservation number is required</div>
              </div>
            </div>
            <button type="button" class="btn btn-primary w-100 mt-5" (click)="doChangeStep()">Find Reservation</button>
          </form>
      </div>
    
  </div>