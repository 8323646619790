<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        
        <a href="javascript:void(0)" class="help_icon" (click)="showCommonTermsCondition()"></a>
        <h2>Guest Verification</h2>
        <a class="skip" (click)="skipModule()" *ngIf="verificationStageSkip">SKIP</a>
        
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">

          <h4 class="heading-title">Please add your Date of Birth</h4>

          <form class="form-flex" [formGroup]="dobInfoForm">
            <div class="row">
              <span #dp="bsDatepicker" bsDatepicker [(bsValue)]="currentDate" class="date-piker" [bsConfig]="{containerClass:'theme-default'}"></span>
              <div class="col form-group d-block mt-4 relative">

                <input type="text" class="form-control" formControlName="date" placeholder="DD" [ngClass]="{ 'is-invalid': submitted && f.month.errors }" autocomplete="off" [value]="currentDate | date: 'dd'"
                />
              </div>
              <div class="col form-group d-block mt-4 relative">

                <input type="text" class="form-control" formControlName="month" placeholder="MM" [ngClass]="{ 'is-invalid': submitted && f.month.errors }" autocomplete="off" [value]="currentDate | date: 'MM'"
                />
               
              </div>
              
              <div class="col form-group d-block mt-4 relative" >
                <input type="text" class="form-control" formControlName="year" placeholder="YYYY" [ngClass]="{ 'is-invalid': submitted && f.month.errors }" autocomplete="off" [value]="currentDate | date: 'YYYY'"
                />
              </div>
              
            </div>
            <div class="form-group mt-5">
                  <button type="button" class="btn btn-primary w-100" (click)="doChangeStep()">Next</button>
            </div>
          </form>
      </div>
    
  </div>