<ng-container *ngIf="conciergeDataAvailable">
  <div class="banner-with-header" *ngIf="conciergeData">
    <div class="banner-top">
      <a (click)="goToBack()" class="backbtn_banner"></a>
      <img [src]="conciergeData.unitImage" alt="">
    </div>
    <h1 class="page-name">Contact Host</h1>
  </div>

  <div class="container" *ngIf="conciergeData">
    <div class="contact-info-box" *ngIf="conciergeData.isCallNowEnabled">
      <strong>Phone:</strong>
      <label><a href="tel:{{conciergeData.messageCountryCode}}{{conciergeData.operatorPhone}}">{{conciergeData.operatorPhone}}</a></label>
    </div>
    <div class="contact-info-box mt-4" *ngIf="conciergeData.isContactEmailEnabled">
      <strong>Email:</strong>
      <label><a href="mailto:{{conciergeData.operatorEmail}}">{{conciergeData.operatorEmail}}</a></label>
    </div>
    <div class="desc-min-height">
        <p class="sub-title mt-5">{{conciergeData.contactInstructions}}</p>
    </div>
    <a href="tel:{{conciergeData.messageCountryCode}}{{conciergeData.operatorPhone}}" class="btn btn-primary mt-5 w-100" *ngIf="conciergeData.isCallNowEnabled">Call Now</a>
    <a href="sms:{{conciergeData.messageCountryCode}}{{conciergeData.messagePhoneNumber}}" class="btn btn-primary mt-3 w-100" *ngIf="conciergeData.isMessageNowEnabled">Message Now</a>
    
  </div>
</ng-container>
<ng-container *ngIf="!conciergeDataAvailable">
  <div class="v_flexbxx notfound_container">
    <div class="container">
      <div class="notfound_box">
        <div class="notfound_icon text-center">
          <img src="../../assets/images/reservations-empty.png" alt=""/>
        </div>
  
        <h3 class="mb-3">No Reservation Found</h3>
        <a routerLink="/pair-reservation" class="btn btn-primary mt-3 w-100">Pair Reservation</a>
      </div>
    </div>
  </div>
</ng-container>  

