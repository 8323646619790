import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable()

export class SupportService{

    constructor(
        private apiService: ApiService
    ) {

    }

    supportForCreateAccount(formValue:any){
        return this.apiService.post('/v1/support/createaccount', formValue)
          .pipe(map(
            response => {          
              return response;
            }
        ));
      }
}