<div class="remove-footer-space">
  <div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/sign-up" class="back-page"></a>
        <h2>Create Account</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">

      <div class="container">
          <h1 class="heading-title">Please enter the <br>verification code we just <br>sent you</h1>
          <form class="mt-3 form-flex" (ngSubmit)="VerifyPhoneCode()" [formGroup]="form">
            <div>
              <div class="otp_blocks">
                <ng-container *ngFor="let input of formInput;  index as i">
                  <input #formRow type="text" formControlName="{{input}}" class="line-input" maxlength="1" (keyup)="keyUpEvent($event, i)" numbersOnly>
                </ng-container>
                
              </div>
              <div class="resend-otp-block">
                <a href="javascript:void(0)" (click)="resendPhoneVerificationCode()">Resend OTP</a>  

              </div>
            </div>
            
            <button class="btn btn-primary w-100 mt-5">Next</button>
          </form>
      </div>
    
  </div>
</div>