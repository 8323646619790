<app-confirm-dialog></app-confirm-dialog>
<ng-container [ngSwitch]="currentStep">
    
    <app-find-reservation *ngSwitchCase="'findReservation'"      
      [reservationId]="reservationId"      
      (changeChildStep)="changeStep($event)"
      (subformInitialized)="subformInitialized('findReservation', $event)" 
      ></app-find-reservation>

    <app-match-reservation *ngSwitchCase="'matchReservation'"
      [startingForm]="userForm.get('matchReservation')?.value" 
      [reservationId]="reservationId"
      [maskedMobileNumber]="maskedMobileNumber"
      (subformInitialized)="subformInitialized('matchReservation', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitFormStep)="submitForm()"
    ></app-match-reservation>

    <app-result-reservation *ngSwitchCase="'resultReservation'"
    [startingForm]="userForm.get('resultReservation')?.value" 
    [reservationId]="reservationId"
  ></app-result-reservation>
</ng-container>
