import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SwitchReservationRoutingModule } from './switch-reservation-routing.module';
import { SharedModule } from '../shared';
import { SwitchReservationComponent } from './switch-reservation.component';

@NgModule({
  imports: [
    SharedModule,
    SwitchReservationRoutingModule
  ],
  declarations: [
    SwitchReservationComponent
  ],
  providers: [    
  ]
})
export class SwitchReservationModule {}
