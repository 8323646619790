import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckInModuleStageEnum, CheckInService, ConfirmDialogService, ErrorMessages, ReservationService, SpinnerService, UserService } from 'src/app/core';

@Component({
  selector: 'app-additional-guests',
  templateUrl: './additional-guests.component.html',
  styleUrls: ['./additional-guests.component.scss']
})
export class AdditionalGuestsComponent implements OnInit {

  @Input() reservationId:number = 0;
  @Input() unitId:number = 0;
  @Input() additionalGuestStageSkip:boolean = false;
  additionalGuests:any;
  maxGuestsAllowed:number = 0;
  isAddGuest:boolean = false;
  submitted: boolean = false;
  countryCodes: any;
  selectedCountryCode: string ='';
  selectedCountryFlag: string ='';
  @Output() skipParentModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getPreviousCheckInSequence: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum

  guestForm = this.fb.group({
    reservationId: new FormControl(0, [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),  
    phoneNumber: new FormControl('', [Validators.required, Validators.minLength(5)]),
    countryId: new FormControl<number>(0),
    countryCode: new FormControl('')
  })
  constructor(
    private reservationService:ReservationService,
    private userService: UserService,
    private fb: FormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private router:Router,
    private spinner:SpinnerService,
    private toastr: ToastrService,
    private checkInService: CheckInService
  ) { }

  ngOnInit(): void {
    this.getCountryCodes();
    this.getGuestDetail();
    this.guestForm.patchValue({
      reservationId:this.reservationId
    });
  }

  get f() {
    return this.guestForm.controls;
  }
  getCountryCodes(){
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;

            if(this.countryCodes){
              var selectedCountry = this.countryCodes[0];
              if(selectedCountry){
                this.selectedCountryFlag = selectedCountry.countryCodeImage;
                this.selectedCountryCode = selectedCountry.countryCode;
                this.guestForm.patchValue({
                  countryId: selectedCountry.countryID,
                  countryCode: selectedCountry.countryCode,
                });
              }
            }
            
          }
        }
      }
    );
  }
  getGuestDetail(){
    this.spinner.show();
    this.reservationService.getGuestDetail(this.reservationId)
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              if(response && response.code == HttpStatusCode.Ok && response.data && response.data.additionalGuestInformation){

                this.additionalGuests = response.data.additionalGuestInformation.filter((x:any) => !x.primaryGuest);
                this.maxGuestsAllowed = response.data.maxGuestsAllowed;
              }
            }
          },
          error:(e) => { 
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.selectedCountryCode = selectedCountry.countryCode;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;

        this.guestForm.patchValue({
          countryCode: this.selectedCountryCode
        });
      }
    }
  }

  submitForm(){

    this.submitted = true;
   
    if(this.guestForm.invalid){
      if(this.guestForm.controls.phoneNumber?.errors?.['minlength']){
        this.confirmDialogService.alertThis('Please enter valid mobile number', '', 'Ok', () => { });
      }
      return;
    }

    if(this.maxGuestsAllowed <= this.additionalGuests.length){
      this.confirmDialogService.alertThis("We're sorry, you have reached the maximum number of guests allowed.", 'Maximum Guests Reached', 'Ok', () =>  {
        
      });
      return;
    }

    this.spinner.show();
    this.addGuest();
    
  }

  addGuest(){
    this.reservationService.addAdditionalGuest(this.guestForm.value)
    .subscribe({
      next:(response) => {
        if(response && response.code == HttpStatusCode.Ok && response.data){
          this.getGuestDetail();
          this.toastr.info("Guest added successfully.");
          this.isAddGuest = false;
          this.guestForm.reset();
          this.submitted = false;
        }
        else{
          this.toastr.error(ErrorMessages.InternalServerError);
        }
      },
      error:(e) => {
        this.spinner.hide();
        if(e && e.data && e.data.length > 0){
          this.toastr.error(e.data[0]);
        }
        else{
          this.toastr.error(e.message);
        }
      },
      complete:() => this.spinner.hide()
    })
  }

  
  removeAdditionalGuest(reservationAdditionalGuestID:number){
    this.spinner.show();

    this.reservationService.removeAdditionalGuest({ reservationAdditionalGuestID: reservationAdditionalGuestID, reservationID: this.reservationId })
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.additionalGuests = response.data.additionalGuestInformation.filter((x:any) => { return !x.primaryGuest});
            }
          },
          error: (e) => {
            this.toastr.error(e.message);
            this.spinner.hide();
          },
          complete: () => this.spinner.hide()
        })
  }


  completeAdditionalGuestStep(){
    this.checkInService.completeAdditionalGuest({ reservationId: this.reservationId})
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.completeModule();
            }
          },
          error:(e) => {
            if(e && e.data && e.data.length > 0){
              this.toastr.error(e.data[0]);
            }
            else{
              this.toastr.error(e.message);
            }
          }
        })
  }

  goToBack(){
    if(this.isAddGuest){
      this.isAddGuest = false;
    }
    else{
      this.getPreviousCheckInSequence.emit();
    }
  }

  skipModule(){
    this.skipParentModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.AdditionalGuests],"stepValue":CheckInModuleStageEnum.AdditionalGuests});
  }

  completeModule(){
    this.skipParentModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.AdditionalGuests],"stepValue":CheckInModuleStageEnum.AdditionalGuests,"action":"complete"});
  }
}
