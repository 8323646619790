<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/profile" class="back-page"></a>
        <h2>Create Support Ticket</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
      <div class="container">
        <div class="support-host">
            <img src="../../../assets/images/support_host.svg" alt="">
            <h2 class="mt-3">Host Support</h2>
            <p class="mt-2 mb-3">Issues with your accomadation that your host can help you with</p>
            <div class="linkBox outlined">
                <a routerLink="/contact-host" class="anchor_cover"></a>                
                <div class="linkBox_content">
                  <strong>Problem with accommodation</strong>
                </div>
              </div>
              <div class="linkBox outlined">
                <a routerLink="/contact-host" class="anchor_cover"></a>                
                <div class="linkBox_content">
                  <strong>I have a request for my host</strong>
                </div>
              </div>
              <div class="linkBox outlined">
                <a routerLink="/contact-host" class="anchor_cover"></a>                
                <div class="linkBox_content">
                  <strong>Something else</strong>
                </div>
              </div>
        </div>
        <div class="support-app mt-4">
            <img src="../../../assets/images/support_app.svg" alt="">
            <h2 class="mt-3">App Support</h2>
            <p class="mt-3 mb-3">Tehcnicaal Issues with the DACK guest applicaiton</p>
            <div class="linkBox outlined">
                <a routerLink="/support-app" class="anchor_cover"></a>                
                <div class="linkBox_content">
                  <strong>Technical issues with the app</strong>
                </div>
              </div>
        </div>
      </div>
    
  </div>