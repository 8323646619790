import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { ApiService } from './api.service';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import { Operator } from '../models';
import { environment } from '../../../environments/environment';
import { JwtService } from './jwt.service';

@Injectable()
export class OperatorService {

    public currentOperatorSubject = new BehaviorSubject<Operator>(localStorage.getItem('operatorDetail')? JSON.parse(localStorage.getItem('operatorDetail')!):{} as Operator);
    public currentOperator  = this.currentOperatorSubject.asObservable().pipe();

    constructor(private apiService : ApiService,private jwtService: JwtService){
        let operatorDetail = localStorage.getItem('operatorDetail');
        if (operatorDetail)
          this.setOperator(JSON.parse(operatorDetail), false); 
    }

    getOperatorDetail(): Observable<Operator> {
        return this.apiService.get('/v5/operator/profile?OperatorID='+environment.operatorId)
          .pipe(map(
          data => {
            if(data && data.data){
                this.setOperator(data.data, true);
                return data.data;
            }
            return data;
          }
        ));
      }

      setOperator(operator: Operator, storeProp: boolean = false) {
        if (storeProp)
            localStorage.setItem('operatorDetail', JSON.stringify(operator));
        // Set current operator data into observable
        this.currentOperatorSubject.next(operator);
      }

      getJwtToken(): Observable<String> {
        return this.apiService.get('/v1/Authentication/GetToken?ClientSecret='+ environment.clientSecret)
          .pipe(map(
          data => {
            if(data && data.data){
                this.jwtService.saveToken(data.data.token,true);
                return data.data.token;
            }
            return "";
          }
        ));
      }
}