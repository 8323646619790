import { Injectable } from '@angular/core'
import { ApiService } from './api.service';
import { map } from 'rxjs'

@Injectable()
export class RecommendationService{

    constructor(
        private apiService : ApiService
    ){}

    getOperatorRecommendationTypes(propertyId: number){
       return this.apiService.get("/v3/property/recommendationcategories?PropertyId=" + propertyId)
            .pipe(
                map(response=>{
                return response;
                })
            );
    }

    getPropertyRecommendations(propertyId: number, unitId: number, recommendationTypeId: any){
        var recommendationUrl = recommendationTypeId == -1 ? "/v2/property/recommendations?PropertyID=" + propertyId+"&UnitID="+unitId : "/v2/property/recommendations?PropertyID=" + propertyId+"&RecommendationTypeID="+recommendationTypeId+"&UnitID="+unitId;
        return this.apiService.get(recommendationUrl)
             .pipe(
                 map(response=>{
                 return response;
                 })
             );
     }

     getRecommendationDetail(recommendationId: number){
        return this.apiService.get("/v2/property/recommendationitem?RecommendationID="+ recommendationId)
            .pipe(map(
                response => {
                    return response;
                }
            ));
     }
}