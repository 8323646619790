import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckInModuleStageEnum, CheckInService, ConfirmDialogService, SpinnerService } from 'src/app/core';

@Component({
  selector: 'app-arrival-complete',
  templateUrl: './arrival-complete.component.html',
  styleUrls: ['./arrival-complete.component.scss']
})
export class ArrivalCompleteComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() unitId: number = 0;
  @Output() skipParentModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() getPreviousCheckInSequence: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum
  arrivalCompleteForm= new FormGroup({
    reservationId: new FormControl(0, Validators.required),
    arrivalTimeId: new FormControl(0, Validators.required),
  });

  purchaseForm = new FormGroup({
    reservationId: new FormControl(0, Validators.required),
    paymentMethodId: new FormControl('', Validators.required)
  });

  cardInfo:any = { paymentMethodKey:'', lastDigits:'', brand:''};
  showPaymentScreen:boolean = false;
  showRequestResultScreen:boolean = false;

  upsellInfo:any;
  totalPrice:number = 0;
  isMoreActionPopupVisible:boolean = false;
  upsellRequestResult:any;
  isEarlyCheckinCartSkipped:boolean = false
  isPropertyHaveUpsells:boolean = true;

  constructor(
    private checkInService: CheckInService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private toastr: ToastrService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.getCheckInSummary();
  }
  

  getCheckInSummary(){
    this.spinner.show();
    this.checkInService.getCheckInSummary(this.reservationId)
        .subscribe({
          next:(response) => {
              if(response && response.code == HttpStatusCode.Ok && response.data){
                this.upsellInfo = response.data.checkInUpsell[0];  
                this.totalPrice = response.data.total;              
              }
              else{
                this.isPropertyHaveUpsells = false;
              }
          },
          error:(e) => {
            this.spinner.hide();
            this.isPropertyHaveUpsells = false;
          },
          complete:() => this.spinner.hide()
        })
  }

  abandonCart(isCartEmpty:boolean){  
    if(isCartEmpty){
      this.confirmDialogService.confirmThis('This action will clear your arrival time selection and complete checkin.'
        ,'','Abandon','Cancel',
        ()=>{
          this.removeFromCart();
        },
        ()=>{
          
        }
      )
    }
    else{
      this.removeFromCart();
    }
   
  }

  removeFromCart(){
    this.spinner.show();
    this.checkInService.abandonCart({reservationid:this.reservationId})
    .subscribe({
      next:(response) => {
        if(response && response.code == HttpStatusCode.Ok){
          this.isEarlyCheckinCartSkipped = true;
          this.showRequestResultScreen = true;
          setTimeout(()=>{
            //this.skipParentModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.ArrivalTime],"stepValue":CheckInModuleStageEnum.ArrivalTime,"action":"complete"});
            this.router.navigateByUrl(`/home/${this.reservationId}`)
          },3000);
        }
      },
      error:(e) => {
        this.spinner.hide();
        this.toastr.error(e.message);
      },
      complete:()=>this.spinner.hide()
    })
  }

  selectPaymentMethod(request:any){
    this.cardInfo = request;
    this.showPaymentScreen = false;
    this.purchaseForm.patchValue({
      reservationId: this.reservationId,
      paymentMethodId: request.paymentMethodKey
    });
  }

  purchaseNow(){
    if(this.purchaseForm.invalid){
      this.showPaymentScreen = true;
      return;
    }
    
    this.spinner.show();
    this.checkInService.earlyCheckInRequest(this.purchaseForm.value)
        .subscribe({
          next:(response) => {
            this.upsellRequestResult = response.data;
            this.showRequestResultScreen = true;
              setTimeout(() => {
                this.router.navigateByUrl(`/home/${this.reservationId}`)
              },3000)
          },
          error:(e) => {
            this.spinner.hide();
            if(e && e.data && e.data.length > 0){
              this.toastr.error(e.data[0]);
            }
            else{
              this.toastr.error(e.message);
            }
          },
          complete:()=> this.spinner.hide()
        })
  }
 
  goToBack(){
    this.getPreviousCheckInSequence.emit();
  }
}
