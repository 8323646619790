import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import { FindReservationComponent } from './find-reservation/find-reservation.component';
import { MatchReservationComponent } from './match-reservation/match-reservation.component';
import { PairReservationRoutingModule } from './pair-reservation-routing.module';
import { PairReservationComponent } from './pair-reservation.component';
import { ResultReservationComponent } from './result-reservation/result-reservation/result-reservation.component';

@NgModule({
  imports: [
    SharedModule,
    PairReservationRoutingModule,
  ],
  declarations: [
    PairReservationComponent,
    FindReservationComponent,
    MatchReservationComponent,
    ResultReservationComponent
  ],
  providers: [    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PairReservationModule {}
