import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyAmenitiesComponent } from './property-amenities.component';
import { SharedModule } from '../shared';
import { PropertyAmenitiesRoutingModule } from './property-amenities-routing.module';
import { ConfirmDialogService } from '../core';

@NgModule({
  imports: [
    SharedModule,
    PropertyAmenitiesRoutingModule
  ],
  declarations: [
    PropertyAmenitiesComponent
  ],
  providers: [
    ConfirmDialogService
  ]
})
export class PropertyAmenitiesModule {}
