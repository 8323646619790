<app-layout-header></app-layout-header>
<div class="main_outer">
  <div class="innerpage footer_space"  id="google_translate_element">
  <router-outlet></router-outlet>
  
  <app-layout-footer></app-layout-footer>
</div>
</div>
  <!-- <div class="loader" id="loader"><span></span></div> -->
  <!--<app-layout-footer></app-layout-footer>-->
