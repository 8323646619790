<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/support" class="back-page"></a>
        <h2>Support</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
      <div class="container">
        <form class="mt-5 form-flex" [formGroup]="supportForm"  (ngSubmit)="submitForm()" autocomplete="off">
            <p class="sub-title" style="color: #000;">For app issues contact us here, <strong>for all other issues please contact your host direclty</strong>. </p>
            
            <div class="form-group d-block mt-4">
                <textarea placeholder="Describe issue.." class="form-control supporttextarea mt-5" formControlName="notes" [ngClass]="{ 'is-invalid': submitted && f.notes.errors }" autocomplete="off"></textarea>
                <div *ngIf="submitted && f.notes.errors" class="invalid-feedback">
                    <div *ngIf="f.notes.errors['required']">Notes is required</div>
                </div>                
            </div>
            
            
            <button class="btn btn-primary w-100 mt-5">Submit</button>
        </form>
      </div>
    
  </div>