<ng-container>
    <div class="title-bar-space d-block">      
        <div class="container">
            <div class="mt-5 d-block text-center">
                <img src="../../../assets/images/check_tick.svg" alt="">
                <h2 class="mt-5">Finished!</h2>
                <p class="mt-4">We've submitted your details for review. We'll let you know if your host needs anything else.</p>                
            </div>            
        </div>      
    </div>
</ng-container>