import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SignUpStep1Component } from './sign-up-step1.component';
import { SignUpStep2Component } from './sign-up-step2.component';
import { SignUpStep3Component } from './sign-up-step3.component';
import { SharedModule } from '../shared';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { CommonService, CustomValidators } from '../core';

@NgModule({
  imports: [
    SharedModule,
    SignUpRoutingModule
  ],
  declarations: [
    SignUpStep1Component,
    SignUpStep2Component,
    SignUpStep3Component
  ],
  providers: [
    CustomValidators,
    CommonService
  ]
})
export class SignUpModule {}
