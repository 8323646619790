<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a (click)="doChangeChildStep()" class="back-page"></a>
        <h2>Reservations Details</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container" >
          <h1 class="heading-title">Confirm Reservation Details</h1>
          <form class="mt-5 form-flex" style="min-height: calc(100vh - 430px);" [formGroup]="matchReservationInfoForm">
            <div class="groupthis">
              <div>
                <label class="form-label">Reservation Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" autocomplete="off"/>
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
                </div>    
              </div>
              <p class="mt-4">To update your phone number, you'll need to confirm your existing reservation details.</p>
              <p class="mt-4" style="display: flex; align-items: center;"><img src="../../../assets/images/phonelight-icon.svg"><strong style="line-height: 1;padding-left: 5px;padding-top: 3px;">{{maskedMobileNumber}}</strong></p>


              <div class="mt-4">
                <label class="form-label">Enter Phone Number Listed Above</label>
                <!-- <input type="text" class="form-control"/> -->
                <div class="form-group d-block">
                    <div class="with-country-code">
                        <span class="custom-select">                                
                            <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                            <input type="text" formControlName="countryCode" class="selectform_control" />
                            <select class="form-control" formControlName="countryId" (change)="onCountryChange($event)">
                                <option *ngFor="let country of countryCodes" [ngValue]="country.countryID" >{{country.countryName}}</option>
                            </select>
                        </span>
                        <input type="text" class="form-control" formControlName="mobileNumber"  [maxLength]="13" numbersOnly [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }" autocomplete="off" />
                    </div>
                    
                    <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.mobileNumber.errors['required']">Phone is required</div>
                    </div>                    
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary w-100 mt-5" (click)="submitForm()">Pair Reservation</button>
          </form>
      </div>
    
  </div>