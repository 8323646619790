<app-confirm-dialog></app-confirm-dialog>
<ng-container *ngIf="propertyUnit && currentReservation">
  <ng-container *ngIf="(isContentBlocked); then blockingContent else nonBlockingContent" >

  </ng-container>
  <ng-template #blockingContent>
    
    <ng-container>
      <ng-container *ngIf="contentBlockingStage">
        <ng-container *ngIf="!propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                    <div class="text-center">
                      <img src="../../../assets/images/checkin_required.svg" alt="">
                    </div>
                    <h2 class="mt-5">It's Time To Check-In</h2>
                    <p class="mt-4"><strong>Your host is requesting a few details about your upcoming stay.</strong></p>                
                    <p class="mt-4">This is a required step to view access and property information.</p>                
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a routerLink="/check-in/{{currentReservation.reservationID}}/{{currentReservation.unitID}}" class="btn btn-primary w-100 mt-5">Get Started</a>
            <a routerLink="/contact-host/{{currentReservation.reservationID}}" class="btn btn-white w-100 mt-4 btn-secondary red-text">Contact Host</a>  
             
          </div>
        </ng-container>
        <ng-container *ngIf="propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                  <div class="text-center">
                    <img src="../../../assets/images/checkin_required.svg" alt="">
                  </div>
                    
                    <h2 class="mt-5">Waiting on primary guest to check-in. </h2>
                    <p class="mt-4">We're waiting on the primary guest to complete check-in at this accommodation </p>                
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a (click)="getUserUnits(currentReservation.unitID, currentReservation.reservationID)" class="btn btn-primary w-100 mt-5">Refresh</a>
             
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!contentBlockingStage">
        <ng-container *ngIf="guestVerificationStatus=='rejected' && !propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                  <div class="text-center">
                    <img src="../../../assets/images/guest_icons.svg" alt="">
                  </div>
                    <h2 class="mt-5">Verification Failed</h2>
                    <p class="mt-4">Please reach out to your host for more information.</p>                
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a routerLink="/contact-host/{{currentReservation.reservationID}}" class="btn btn-primary w-100 mt-5">Contact Host</a>         
          </div>
        </ng-container>
        <ng-container *ngIf="guestVerificationStatus=='rejected' && propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                  <div class="text-center">
                    <img src="../../../assets/images/guest_icons.svg" alt="">
                  </div>
                    <h2 class="mt-5"></h2>
                    <p class="mt-4">Sorry, your accommodation address and directions are hidden until the reservation owner completes verification </p>                
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a (click)="getUserUnits(currentReservation.unitID, currentReservation.reservationID)" class="btn btn-primary w-100 mt-5">Refresh</a>
             
          </div>
        </ng-container>
    
        
        <ng-container *ngIf="guestVerificationStatus=='submitted' && !propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                    <div class="text-center">
                      <img src="../../../assets/images/guest_icons.svg" alt="">
                    </div>
                    <h2 class="mt-5">Verification in progress…</h2>
                    <p class="mt-4">Sorry this page is unavailable until we've completed reviewing your details. Verification usually takes a few minutes…</p>                
                                 
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a (click)="getUserUnits(currentReservation.unitID, currentReservation.reservationID)" class="btn btn-primary w-100 mt-5">Refresh</a>
            <a routerLink="/contact-host/{{currentReservation.reservationID}}" class="btn btn-white w-100 mt-4 btn-secondary red-text">Contact Host</a>  
             
          </div>
        </ng-container>
        <ng-container *ngIf="guestVerificationStatus=='submitted'  && propertyUnit.isAdditionalGuest">
          <div class="title-bar-space d-block">      
            <div class="container">
                <div class="guest_verification mt-4">
                  <div class="text-center">
                    <img src="../../../assets/images/guest_icons.svg" alt="">
                  </div>
                    
                    <h2 class="mt-5">Verification in progress… </h2>
                    <p class="mt-4">Sorry, your accommodation address and directions are hidden until the reservation owner completes verification </p>                
                </div>            
            </div>      
          </div>    
          <div class="container">     
            <a (click)="getUserUnits(currentReservation.unitID, currentReservation.reservationID)" class="btn btn-primary w-100 mt-5">Refresh</a>
             
          </div>
        </ng-container>
      </ng-container>
     
    </ng-container>
    
    
    
  </ng-template>
  <ng-template #nonBlockingContent>
    <ng-container>
      <div class="banner-with-header">
        <div class="banner-top"><img [src]="propertyUnit.unitImage" alt="header"/></div>          
      </div>
      <div class="container">
        <div class="mystay-info">
          <div class="logo-circle"><img [src]="propertyUnit.operatorLogo" alt=""></div>
          <div>
            <h3>{{propertyUnit.customUnitName}}</h3>
            <span *ngIf="currentReservation">RESERVATION # {{currentReservation.reservationNumber}}</span>
          </div>
        </div>
    
        <div class="time_zone">
          <div class="r">
            <!-- <strong>{{propertyUnit.unitCheckInTime | date:'h:mm a'}}</strong>  -->
            {{propertyUnit.unitCheckInTime | date:'EEE MMM d, y'}}</div>
          <div class="l">
            <!-- <strong>{{propertyUnit.unitCheckOutTime | date:'h:mm a'}}</strong>  -->
            {{propertyUnit.unitCheckOutTime | date:'EEE MMM d, y'}}</div>
        </div>
        <ng-container *ngIf="contentBlockingStage">
          <div class="alert_link mt-3 mb-3">
            <a routerLink="/check-in/{{currentReservation.reservationID}}/{{currentReservation.unitID}}" class="anchor_cover"></a>
            <div class="alert_link_content">
              <strong style="color:#EA6969">Complete Pre-Arrival Info!</strong>
              <label>Add details for your stay</label>
            </div>  
          </div>
        </ng-container>

        <ng-container *ngIf="!contentBlockingStage && propertyUnit.reservationStatus == reservationStatusEnum.InStay && propertyUnit.availableSurveys.length > 0">
          <div class="alert_link mt-3 mb-3">
            <a routerLink="/survey/{{currentReservation.reservationID}}/{{propertyUnit.availableSurveys[0].surveyId}}" class="anchor_cover"></a>
            <div class="alert_link_content">
              <strong>{{propertyUnit.availableSurveys[0].title}}</strong>
              <label>{{propertyUnit.availableSurveys[0].subTitle}}</label>
            </div>  
          </div>
        </ng-container>
    
       
    
        <div class="keycode_box mt-3" *ngIf="accessPoints">
          <div class="keycode">
            <strong>Your Keycode</strong>
            <div *ngIf="accessPoints[0].keyCode==null"></div>
            <div *ngIf="accessPoints[0].keyCode!=null" [ngClass]="accessPoints[0].keyCode.toLowerCase()=='access revoked'? 'access-revoked':'access'">{{accessPoints[0].keyCode}}</div>
            <a routerLink="/property-access/{{currentReservation.reservationID}}" class="accessdetail-link">View Access Details</a>
          </div>
        </div>
    
        <a  routerLink="/property-direction/{{currentReservation.reservationID}}/{{currentReservation.unitID}}">
          <div class="location-box">
            <span>{{propertyUnit.propertyFullAddress}}</span>
          </div>
        </a>
        
    
        <a routerLink="/property-direction/{{currentReservation.reservationID}}/{{currentReservation.unitID}}" class="goto-link">Parking & Directions</a>
    
        <div class="d-block w-100" id="options-box">
          <div class="options-box">
          <div class="ob-box" *ngIf="propertyUnit.propertyAmenityID > 0">
            <a routerLink="/property-wifi/{{propertyUnit.propertyAmenityID}}/{{currentReservation.reservationID}}">
              <span><img src="assets/images/wifi.svg" alt="icon"/></span>
              <strong>WiFi<br>Info</strong>
            </a>
          </div>
          <div class="ob-box">
            <a routerLink="/property-guide/{{currentReservation.unitID}}/{{currentReservation.reservationID}}">
              <span><img src="assets/images/property.svg" alt="icon"/></span>
              <strong>Property<br/>Info</strong>
            </a>
          </div>
          <div class="ob-box">
            <a routerLink="/passport/{{currentReservation.unitID}}/{{currentReservation.reservationID}}">
              <span><img src="assets/images/action_passport.svg" alt="icon"/></span>
              <strong>Local <br/>Pass</strong>
            </a>
          </div>
          <div class="ob-box">
            <a routerLink="/contact-host/{{currentReservation.reservationID}}">
              <span><img src="assets/images/contact_host.svg" alt="icon"/></span>
              <strong>Contact<br>Host</strong>
            </a>
          </div>
          
          <div class="ob-box" *ngIf="currentReservation.rebookUrl">
            <a [href]="currentReservation.rebookUrl" target="_blank">
              <span><img src="assets/images/book_again.svg" alt="icon"/></span>
              <strong>Book<br>Again</strong>
            </a>
          </div>
        </div>
        </div>
    
        
    
        <ng-container *ngIf="propertyEnhancements && propertyEnhancements.length > 0">
          <h3 class="font-16 d-block w-100 mt-5"><a routerLink="/property-enhancements/{{currentReservation.reservationID}}/{{currentReservation.unitID}}" class="rightaction">Enhance Your Stay </a></h3>
         
          <div class="horizontal-scroll-box" id="horizontal-scroll-box-enhancement">

            <div class="listScrolls withTwoCols" >
              <div class="list-atricle afterImg mt-3" *ngFor="let upsell of propertyEnhancements">
                <a routerLink="/property-enhancement/{{currentReservation.reservationID}}/{{currentReservation.unitID}}/{{upsell.itemID}}" class="anchor_cover" *ngIf="upsell.itemID != null && upsell.externalLink == null"></a>
                <a href="{{upsell.externalLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID != null && upsell.externalLink != null"></a>
                <a href="{{upsell.externalLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID == null && upsell.externalLink != null"></a>
                <a href="{{upsell.androidLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID == null && upsell.externalLink == null && upsell.androidLink"></a>
            
                <div class="articleimg-box"><img [src]="upsell.image" alt=""/></div>
                <div class="articledetail">
                  <strong>{{upsell.itemName}}</strong>
                  <p *ngIf="upsell.itemID != null && upsell.price">{{upsell.price.displayPrice}}</p>
                </div>
              </div>
            </div>
          </div>
          
        </ng-container>

        <ng-container *ngIf="recommendations && recommendations.length > 0">
          <h3 class="font-16 d-block w-100 mt-4"><a routerLink="/recommendations/{{currentReservation.unitID}}/{{propertyUnit.propertyID}}/{{currentReservation.reservationID}}" class="rightaction">Local Recommendations </a></h3>

          <div class="horizontal-scroll-box" id="horizontal-scroll-box-recommendation">

            <div class="listScrolls ">
              <div class="list-atricle verImg mt-3" *ngFor="let recommendation of recommendations">
                <ng-container *ngIf="recommendation.source == 'Yelp' && recommendation.destinationURL; then yelpLink else dackLink "></ng-container>
                <ng-template #yelpLink>
                  <a target="_blank" [href]="recommendation.destinationURL" class="anchor_cover"></a>
                </ng-template>
                <ng-template #dackLink>
                  <a routerLink="/recommendation/{{recommendation.recommendationID}}/{{currentReservation.unitID}}/{{propertyUnit.propertyID}}/{{currentReservation.reservationID}}/0/0" class="anchor_cover"></a>

                </ng-template>
                <div class="articleimg-box"><img [src]="recommendation.image" alt="" onerror="this.onerror=null;this.src='../../assets/images/default_black_img.svg'"/></div>
                <div class="articledetail">
                  <strong>{{recommendation.name}}</strong>
                  <p class="startratings" [innerHTML]="GetRating(recommendation.rating)">
                          
                  </p>
                </div>
              </div>
              
            </div>

          </div>

      </ng-container>

        <ng-container *ngIf="propertyAmenities && propertyAmenities.length > 0">
            <h3 class="font-16 d-block w-100 mt-4">
              <a routerLink="/property-amenities/{{currentReservation.reservationID}}/{{this.propertyUnit.propertyID}}/{{currentReservation.unitID}}" class="rightaction">Amenities 
              </a>
            </h3>

            <div class="horizontal-scroll-box" id="horizontal-scroll-box-amenity">
              <div class="listScrolls">
                <div class="list-atricle squarebox mt-3" *ngFor="let amenity of propertyAmenities">
                  <a routerLink="/property-amenity/{{currentReservation.reservationID}}/{{amenity.propertyAmenityID}}/default" class="anchor_cover"></a>
                  <div class="articleimg-box"><img [src]="amenity.banner" alt="icon"/></div>
                  <div class="articledetail">
                    <strong>{{amenity.name}}</strong>
                  </div>
                </div>                
              </div>
            </div>
        </ng-container>
        

        <ng-container *ngIf="additionalGuests">

          <div class="userslists mb-4">
            <a routerLink="/guests/{{currentReservation.reservationID}}" class="mt-4 mb-4 goto-link">Your Party</a>
            <p style="font-size:14px">Here's who has access to your accommodation</p>
            <div class="users">             
              <ul>
                <li class="addNewuser" (click)="addGuest()"><span></span></li>
                <li *ngFor="let guest of additionalGuests" (click)="goToGuestDetail(guest.reservationAdditionalGuestID)">
                  <span><img [src]="guest.profilePicture" alt="" onError="this.src='/assets/images/profile.png'"/></span>
                </li>                 
              </ul>
            </div>
          </div>
          
        </ng-container>
      </div>
    </ng-container>
    
  </ng-template>
</ng-container>

<ng-container *ngIf="!guestHaveReservation">
  <div class="v_flexbxx notfound_container">
    <div class="container">
      <div class="notfound_box">
        <div class="notfound_icon text-center">
          <img src="../../assets/images/reservations-empty.png" alt=""/>
        </div>
  
        <h3 class="mb-3">No Reservation Found</h3>
        <a routerLink="/pair-reservation" class="btn btn-primary mt-3 w-100">Pair Reservation</a>
      </div>
    </div>
  </div>
  
</ng-container>

