<ng-container>
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <a routerLink="/profile" class="back-page"></a>
            <h2>Purchases</h2>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isPurchaseRequestsAvailable && purchases">
        <div class="title-bar-space d-block">
      
          <div class="container">
              <div class="linkBox outlined" *ngFor="let item of purchases">
                <a routerLink="/purchase-detail/{{item.id}}/{{item.type}}" class="anchor_cover"></a>
                <div class="linkBox_img">
                    <img [src]="item.imageUrl" alt=""/>
                </div>
                <div class="linkBox_content">
                  <strong>{{item.title}}</strong>
                  <label class="statusshow {{item.status.toLowerCase()}}">{{getPurchasedStatus(item.status)}}</label>
                </div>
              </div>             
          </div>        
      </div>
      </ng-container>
      <ng-container *ngIf="!isPurchaseRequestsAvailable">
        <div class="v_flexbxx notfound_container">
          <div class="container">
            <div class="notfound_box">
              <div class="notfound_icon text-center">
                <img src="../../assets/images/upsells-empty.png" alt=""/>
              </div>
        
              <h3 class="mb-3">No Enhancements Found</h3>
              <p class="mb-0">You have not made any purchases to enhance your stay.</p>
            </div>
          </div>
        </div>
      </ng-container>
</ng-container>

