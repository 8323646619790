import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PurchaseItemType, SpinnerService, TransactionService } from '../core';

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchase-detail.component.html',
  styleUrls: ['./purchase-detail.component.scss']
})

export class PurchaseDetailComponent implements OnInit {

  purchaseDetail:any;
  purchaseItemId:number = 0;
  purchasedItemType: PurchaseItemType = (<any>PurchaseItemType)[1];
  purchaseItemType = PurchaseItemType;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,    
    items:1,
    autoplay:true
  }


  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private transactionService: TransactionService,
    private location: Location
  ) { 
    this.purchaseItemId = parseInt(this.route.snapshot.params['id']);
    this.purchasedItemType = (<any>PurchaseItemType)[this.route.snapshot.params['type']];
  }

  ngOnInit(): void {
    this.getPurchaseItemDetail(this.purchaseItemId, this.purchasedItemType);
  }

  getPurchaseItemDetail(itemId:number, itemType: PurchaseItemType){
    switch(itemType)
    {
      case PurchaseItemType.Upsell:
          this.getUpsellItemDetail(itemId);
          break;
      case PurchaseItemType.SecurityDeposit:
          this.getSecurityDepositItemDetail(itemId);
          break;
      case PurchaseItemType.DamageWaiver:
          this.getDamageWaiverItemDetail(itemId);
          break;
    }
  }

  getUpsellItemDetail(itemId:number){
    this.spinner.show();

    this.transactionService.purchaseUpsellItemDetail(itemId)
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.purchaseDetail = response.data;
            }
          },
          error: (e) => {
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  getSecurityDepositItemDetail(itemId:number){
    this.spinner.show();

    this.transactionService.purchaseSecurityItemDetail(itemId)
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.purchaseDetail = response.data;
            }
          },
          error: (e) => {
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  getDamageWaiverItemDetail(itemId:number){
    this.spinner.show();

    this.transactionService.purchaseDamageWaiverItemDetail(itemId)
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.purchaseDetail = response.data;
            }
          },
          error: (e) => {
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  goToBack(){
    this.location.back()
  }

  getPurchasedStatus(status:string):any{
    if(status.toLowerCase()=="captured"){
      return "Approved";
    }
    else if (status.toLowerCase()=="onhold"){
      return "On Hold";
    }
    else if (status.toLowerCase()=="fullcapture"){
      return "Charged In Full";
    }
    else{
      return status;
    }
  }
}
