import { Injectable } from '@angular/core';
import { map } from 'rxjs'
import { ApiService } from "./api.service";

@Injectable()
export class PropertyService{

    constructor(private apiService: ApiService){

    }

    getPropertyGuide(unitId: number){
        return this.apiService.get('/v2/property/staydetails?UnitID='+ unitId)
        .pipe(map(
        data => {          
          return data;
        }
      ));
    }

    getPropertyDetail(unitStayDetailItemId: number){
      return this.apiService.get('/v2/Property/StayDetailItem?UnitStayDetailItemID='+ unitStayDetailItemId)
      .pipe(map(
      data => {          
        return data;
      }
    ));
  }

  getPropertyAccessPoint(reservationId: number){
    return this.apiService.get('/v5/AccessPoint?ReservationId='+ reservationId)
    .pipe(map(
      data => {          
        return data;
      }
    ));  
  }

  getUserUnits(){
    return this.apiService.get('/v5/property/GetUserUnits')
    .pipe(map(
      data => {          
        return data;
      }
    ));
  }

  getPropertyAmenityItem(propertyAmenityId: number){
    return this.apiService.get('/v2/property/AmenityItem?PropertyAmenityId='+ propertyAmenityId)
    .pipe(map(
      data => {          
        return data;
      }
    ));
  }

  lockUnlockProperty(request: any){
    return this.apiService.post("/v5/lock/unlock", request)
      .pipe(map(
        data => {
          return data;
        }
      ))
  }

  accessPointLockDetail(accessPointId: number){
    return this.apiService.get("/v4/accesspoint/lockdetails?accessPointId="+ accessPointId)
      .pipe(map(
        data => {
          return data;
        }
      ))

  }

  SetOrUpdatedKeyCodeOnLock(request:any){
    return this.apiService.post("/v5/accesspoint/pushguestcode", request)
      .pipe(map(
        data => {
          return data;
        }
      ))
  }
}