import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckInModuleStageEnum, ErrorMessages, PropertyService, SpinnerService, TransactionService, UpsellService } from 'src/app/core';
declare var $: any; 

@Component({
  selector: 'app-damage-waiver-payment',
  templateUrl: './damage-waiver-payment.component.html',
  styleUrls: ['./damage-waiver-payment.component.scss']
})
export class DamageWaiverPaymentComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() unitId: number = 0;
  @Input() securityTransactionStageSkip:boolean = false;
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  propertyUnit: any;
  damageWaiverFee: number = 0;
  securityDepositFee: number = 0;
  isShowPaymentDetail: boolean = false;
  
  showRequestResultSection:boolean = false;
  submitted: boolean = false;
  isSuccessResult: boolean = false;

  purchaseForm= new FormGroup({
    reservationId: new FormControl(0, Validators.required),
    paymentMethodKey: new FormControl('',Validators.required),
  });


  cardInfo:any = { paymentMethodKey:'', lastDigits:'', brand:''};
  constructor(
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private router: Router,
    private spinner: SpinnerService,
    private upsellService: UpsellService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private transactionService: TransactionService
  ) {
    this.purchaseForm.patchValue({
      reservationId : this.reservationId
    });
   }

  ngOnInit(): void {
    this.getUserUnits(this.unitId, this.reservationId);
  }

  doChangeStep(step:string) {
    
    this.changeChildStep.emit({"currentStep":step, "direction":"forward"});
  }

  getUserUnits(unitId: number, reservationId: number){
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
        next:(response)=>{
          if(response && response.code == HttpStatusCode.Ok && response.data){
              if(response.data.units && response.data.units.length > 0){
                var propertyUnits = response.data.units.find((x:any) => x.reservationID == reservationId && x.unitID == unitId);
                if(propertyUnits){
                  this.propertyUnit = propertyUnits;

                  if(this.propertyUnit 
                    && this.propertyUnit.checkInSequence.securityTransactions
                    && (this.propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled
                      || this.propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled)
                    ){
                    this.damageWaiverFee = this.propertyUnit.checkInSequence.securityTransactions.damageWaiverFee;
                    this.securityDepositFee = this.propertyUnit.checkInSequence.securityTransactions.securityDepositFee;
                  }
                  else{
                    this.router.navigateByUrl("/home/"+this.reservationId)
                  }
                }            
              }            
          }
        },
        error:(e)=>{
          this.spinner.hide();
          this.router.navigateByUrl("/home/"+this.reservationId)
        },
        complete:()=>{
          this.spinner.hide();
        }
      }
    );
  }

  selectPaymentMethod(request:any){
    this.cardInfo = request;
    this.isShowPaymentDetail = false;
    this.purchaseForm.patchValue({
      reservationId: this.reservationId,
      paymentMethodKey: request.paymentMethodKey
    });
  }
  
  submit(){
    this.submitted = true;
    if(this.purchaseForm.invalid){
      this.isShowPaymentDetail = true;
      return;
    }

    this.spinner.show();

    this.transactionService.purchaseDamageWaiver(this.purchaseForm.value)
        .subscribe({
          next:(response) => {
            this.showRequestResultSection = true;

            if(response && response.code == HttpStatusCode.Ok){
              this.isSuccessResult = true;
            }
            // else{
            //   this.toastr.error(ErrorMessages.InternalServerError);
            // }
          },
          error:(e) => {
            this.spinner.hide();
            this.showRequestResultSection = true;
            // if(e.code == HttpStatusCode.BadRequest){
            //   this.toastr.error(e.message);
            // }
            // else{
            //   this.toastr.error(ErrorMessages.InternalServerError);
            // }
          },
          complete: () => this.spinner.hide()
        })

  }

  goToPage(pageName:string){
    this.router.navigateByUrl(`/${pageName}/${this.reservationId}`)
  }

  moduleCompleteFailed(){
    this.showRequestResultSection = false;
    this.isShowPaymentDetail = false;
  }

  moduleComplete(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.SecurityTransactions],"stepValue":CheckInModuleStageEnum.SecurityTransactions, "action":"complete"});
  }

  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.SecurityTransactions],"stepValue":CheckInModuleStageEnum.SecurityTransactions});

  }
}
