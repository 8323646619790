<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
          <a href="javascript:void(0)" class="help_icon" (click)="showCommonTermsCondition()"></a>
          <h2>Guest Verification</h2>
          <a class="skip" (click)="skipModule()" *ngIf="verificationStageSkip">SKIP</a>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
    <div class="container">
        <div class="title-bar-box mb-3">
            <h3>
                Verification Provider Terms and Conditions
            </h3>
        </div>
        
        <form class="form-flex">
            <div class="row">
                <h6 class="mb-3">
                    SUPERHOG – Guest Agreement
                </h6>
                <p class="mb-3">
                    This Agreement is dated and arranged by SUPERHOG Ltd (“SUPERHOG") and governs the relationship between you (“Guest"), SUPERHOG and your Host in any Booking. You should read this Agreement carefully and only continue if you wish to be bound by the Agreement in its entirety.
                </p>
        
                <h6 class="mb-3">1. GUEST CONSENT</h6>
                <p class="mb-3">
                    1.1 To protect the Host's interest in the Property, the Guest agrees to complete a Booking Validation. This may mean the Guest is required to verify their identity (ID) in advance of the Booking and if asked, the Guest agrees to submit a photographic ID. 1.2 The Host or SUPERHOG, acting as the Host's Agent, may pass the Guest's details to a thirdparty provider to carry out ID verification, and other checks. 1.3 NO SUPERHOG guarantees will be active in relation to a Booking if the SUPERHOG Booking Validation has not been completed in advance of the Booking.
                </p>
        
                <h6 class="mb-3">2. PROPERTY DAMAGE</h6>
                <p class="mb-3">
                    2.1 The Guest acknowledges that they are liable to pay for all Property Damage to, at or in the Property during their Booking which was directly or indirectly caused by their own action or inaction. 2.2 If the Guest does not report Property Damage to the Host or SUPERHOG which can be demonstrated to have been caused during the Booking, then it will be assumed that the Property Damage was caused by the Guest and the Guest accepts liability for the damage. 2.3 The Guest agrees to compensate the Host for any fees or charges the Host might have to pay, including compensation to occupiers of neighbouring premises caused by the Guest's actions or failure to fulfil any of the obligations in this Agreement.
                </p>
        
                <h6 class="mb-3">3. SECURITY DEPOSIT</h6>
                <p class="mb-3">
                    3.1 Where the payment of a Security Deposit by the Guest is required for a Booking, the Guest agrees to provide details of a payment card which will remain valid until at least 30 days after the Booking has been completed. 3.2 If Property Damage has occurred, SUPERHOG has the right to draw against the payment card up to the Security Deposit amount for the Booking as part of compensation for damage that is payable to the Host. 3.3 SUPERHOG's right to draw against the payment card will expire at the end of 14 days after the checkout time and date of the booking unless SUPERHOG have notified the Guest by this time of potential Property Damage which requires further investigation. As long as SUPERHOG have given such notice within this timeframe the right to draw against the payment card will extend until the end of any investigation. 3.4 In all cases SUPERHOG will give the Guest three working days written notice in advance of taking any payment. 3.5 Superhog may provide alternative Security Deposit options to applicable Guests.
                </p>
        
                <h6 class="mb-3">4. PROPERTY USE</h6>
                <p class="mb-3">
                    4.1 The Property may only be used for purposes permitted by the Host. 4.2 Any appliance, fixtures and fittings must be operated in accordance with provided instructions and in a reasonable and careful manner. 4.3 There is a strict policy against Parties in or at the Property, unless pre-approved by the Host.
                </p>
        
                <h6 class="mb-3">5. GUEST OBLIGATIONS AND ACKNOWLEDGEMENTS</h6>
                <p class="mb-3">
                    5.1 The Guest acknowledges that they are Tiable for all costs associated with damage to, at or in the Property during their Booking which was directly or indirectly caused by their own action or inaction. 5.2 The Guest always agrees to use the Property only as permitted, by the Host and always in a reasonable and responsible manner. 5.3 The Guest takes full responsibility for the behaviour of any person they have permitted to enter the Property. 5.4 The Guest agrees to act as soon as they become aware of any Property Damage or problem to minimise its effects and prevent it becoming worse or causing nuisance to the occupier of any neighbouring premises, 5.5 Wherever possible the Guest will take a photographic record of the property at the start and the end of the Booking and upload to SUPERHOG. If the Guest chooses not to do this the Guest acknowledges that it will be hard, for them to prove that they did not cause the damage. 5.6 The Guest will take steps to ensure the security of the Property. 5.7 The Guest will take care to avoid blocking or causing damage to drains or sanitary appliances. 5.8 The Guest will keep the Property appropriately and safely ventilated to ensure that there is no build-up of damp or moisture or of cooking smells, smoke, or grease. 5.9 All obligations on the Guest in respect of the Property apply equally in relation to any building of which the Property forms part and in relation to any access, parking, or right of way associated with the Property. 5.10 The Guest acknowledges that the cost of Property Damage will be determined at SUPERHOG's sole discretion.
                </p>
        
                <h6 class="mb-3">6. AGREEMENT</h6>
                <p class="mb-3">
                    6.1 The Agreement sets out the terms on which SUPERHOG provides services in respect of a Booking. By entering into the Agreement you also agree to our Website Terms and Privacy and Data Protection policy which you can find at www.superhog.com. It is important that you read the Agreement carefully as your continuation through the SUPERHOG process constitutes acceptance of these terms and the associated obligations. 6.2 To the extent this Agreement is signed by an agent or representative of a Guest, it is deemed to also bind the Guest, and that the Guest has expressly agreed to the terms in this Agreement. 6.3 You agree that SUPERHOG may pass on certain information to the Host in connection with the Booking if this is deemed relevant. 6.4 You agree that if you have caused Property Damage and not met your associated liabilities then your details may be held by SUPERHOG on a Watchlist.
                </p>
        
                <h6 class="mb-3">7. GENERAL</h6>
                <p class="mb-3">
                    71 Any notices required under this agreement will be deemed if sent by email to the Guest, provided that no such notification of non-delivery is received, and the notice will be deemed served eight hours after sending of the email. 7.2 The unenforceability or invalidity of any clause in this Agreement shall not have an impact on the enforceability or validity of any other clause. 7.3 This agreement is governed by and construed in accordance with the laws of England and Wales. The Host and Guest agree to submit to the exclusive jurisdiction of the English courts
                </p>
        
                <div class="title-bar-box mb-3">
                    <h3 [innerHTML]="title"></h3>
                </div>
                
                <p class="mb-3" [innerHTML]="body"></p>
            </div>
            <div class="form-group mt-5">
                  <button type="button" class="btn btn-primary w-100" (click)="doChangeStep()">I Agree</button>
            </div>
        </form>
        

    </div>
    
</div>