import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, Renderer2, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { CheckInModuleStageEnum, CheckInModuleStageEnumLabel, CmsPageType, CommonApiService, ErrorMessages, PropertyService, ReservationService, SpinnerService } from '../../core';
type Step = 'landingInfo' | 'personalInfo' | 'dobInfo' | 'personalAddressInfo' | 'termsConditionInfo' | 'idProofInfo' | 'selfieInfo' | 'progressBarInfo';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() verificationStageSkip: boolean = false;
  @Output() skipParentModuleStep: EventEmitter<any> = new EventEmitter<any>();
  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('landingInfo');
  private currentStep$: Observable<Step> = this.currentStepBs.asObservable().pipe();

  public currentStep: string = '';
  public userForm: FormGroup;
  isShowVerificationTerms: boolean = false;
  termsConditions: string = "";
  checkInSequence: any;
  propertyUnit: any;
  checkInModuleStageEnum = CheckInModuleStageEnum

  constructor(
    private _fb: FormBuilder,
    private spinner: SpinnerService,
    private commonApiService: CommonApiService,
    private reservationService: ReservationService,
    private propertyService: PropertyService,
    private renderer: Renderer2,
    private toastr: ToastrService
  ) {
    this.userForm = this._fb.group({
      personalInfo: null,
      dobInfo: null,
      personalAddressInfo: null,
      termsConditionInfo: null,
      idProofInfo: null
    });
  }

  ngOnInit(): void {
    this.currentStep$.subscribe(

      (currentStep) => {
        this.currentStep = currentStep;
      }
    )
    this.renderer.addClass(document.body, 'verification');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'verification');
  }

  showCommonTermsCondition(isShow: any) {
    if (!this.isShowVerificationTerms && isShow) {
      this.getTermsAndConditions();
    }
    this.isShowVerificationTerms = isShow;
  }

  getTermsAndConditions() {
    this.spinner.show();
    this.commonApiService.getCmsPage(CmsPageType.GuestVerificationTerms).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.termsConditions = response.data.pageContent;
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => this.spinner.hide()
      }
    );
  }

  showHideSpinner(isShow: any) {
    if (isShow) {
      this.spinner.show();
    }
    else {
      this.spinner.hide();
    }
  }

  subformInitialized(name: string, formGroup: any) {

    setTimeout(() => {
      this.userForm.setControl(name, formGroup);
    },);

  }
  changeStep(request: any) {
    var currentStep = request.currentStep;
    var direction = request.direction;

    switch (currentStep) {
      case 'landingInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('personalInfo');
        }
        break;
      case 'personalInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('dobInfo');
        }
        break;
      case 'dobInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('personalAddressInfo');
        }
        break;
      case 'addressInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('termsConditionInfo');
        }
        break;
      case 'termsConditionInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('idProofInfo');
        }
        break;
      case 'idProofInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('selfieInfo');
        }
        break;
      case 'selfieInfoStep':
        if (direction === 'forward') {
          this.currentStepBs.next('progressBarInfo');
        }
        break;
    }
  }
  submitForm() {
    const formValues = this.userForm.value;
    var requestModel = {
      reservationId: this.reservationId,
      title: formValues.personalInfo.title,
      firstName: formValues.personalInfo.firstName,
      lastName: formValues.personalInfo.lastName,
      email: formValues.personalInfo.email,
      dateOfBirth: formValues.dobInfo.year + '-' + formValues.dobInfo.month + '-' + formValues.dobInfo.date,
      telephoneType: formValues.personalInfo.phoneList[0].phoneType,
      telephoneCode: formValues.personalInfo.phoneList[0].countryCode,
      telephoneNumber: formValues.personalInfo.phoneList[0].phone,
      addressLine1: formValues.personalAddressInfo.addressLine1,
      addressLine2: formValues.personalAddressInfo.addressLine2,
      town: formValues.personalAddressInfo.city,
      county: formValues.personalAddressInfo.countryName,
      countryIso: formValues.personalAddressInfo.countryCode,
      postcode: formValues.personalAddressInfo.zipCode,
      governmentIdBase64: formValues.idProofInfo.idProofBase64,
      governmentIdContentType: formValues.idProofInfo.governmentIdContentType,
      selfieBase64: formValues.selfieInfo.selfieBase64,
      selfieContentType: formValues.selfieInfo.selfieContentType,
      termsAccepted: formValues.termsConditionInfo.termsAccepted
    }

    this.spinner.show();
    this.reservationService.verifyReservationUserDetail(requestModel)
      .subscribe({
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.changeStep({ "currentStep": "selfieInfoStep", "direction": "forward" });
          }
          else {
            this.toastr.error(ErrorMessages.InternalServerError);
          }
        },
        error: (e) => {
          this.spinner.hide();
          this.toastr.error(ErrorMessages.InternalServerError);

        },
        complete: () => this.spinner.hide()
      })
  }

  get isRedirectToDamageWaiver() {
    if (this.checkInSequence
      && this.checkInSequence.securityTransactions
      && this.checkInSequence.securityTransactions.status && this.checkInSequence.securityTransactions.status.toLowerCase() == "incomplete"
    ) {
      return true;
    }
    return false;
  }

  skipModule(request: any) {
    this.skipParentModuleStep.emit(request);
  }
}
