import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PropertyService, SpinnerService } from '../core';

@Component({
  selector: 'app-property-guide',
  templateUrl: './property-guide.component.html',
  styleUrls: ['./property-guide.component.scss']
})
export class PropertyGuideComponent implements OnInit {

  propertyGuides: any;
  unitId: number = 0;
  reservationId: number = 0;
  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private spinner:SpinnerService
    ) {
    this.unitId = route.snapshot.params['id'];
    this.reservationId =  parseInt(this.route.snapshot.params['rid']);
   }

  ngOnInit(): void {
    this.fillPropertyGuide();
  }

  fillPropertyGuide(){
    this.spinner.show();
    this.propertyService.getPropertyGuide(this.unitId).subscribe({
      next:(response)=>{
        if(response && response.code == HttpStatusCode.Ok && response.data){
            this.propertyGuides = response.data;
        }
      },
      error:(e)=>this.spinner.hide(),
      complete:()=>this.spinner.hide()
    }
      
    );
  }

 
}
