import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class MixpanelService {
    private userId: any

    constructor(
        private userService: UserService) { }
    init(): void {
        mixpanel.init(environment.mixpanelKey);
        mixpanel.identify(this.getDistinctId());
    }

    getDistinctId(): string {
        this.userService.currentUser.subscribe(
            (userData) => {
                this.userId = userData.userID;
            }
        );

        if (environment.production) {
            return this.userId
        } else {
            return "dev-" + this.userId
        }
    }

    sendScreenVisitEvent(screenName: string): void {
        mixpanel.track("ScreenVisitEvent", { screenName: screenName, environment: this.getEnvironment(), userId: this.userId });
    }

    getEnvironment(): string {
        if (environment.production) {
            return "production"
        } else {
            return "development"
        }
    }
}
