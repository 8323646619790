<ng-container *ngIf="showPaymentScreen; then paymentScreenContent else findOtherThenPayment"></ng-container>
<ng-template #findOtherThenPayment>
  <ng-container *ngIf="showRequestResultScreen; then showRequestResultContent else completeEarlyCheckIn"></ng-container>
</ng-template>

<ng-template #completeEarlyCheckIn>
  <ng-container *ngIf="upsellInfo">
    <div class="title-bar">
      <div class="container">
        <div class="title-bar-box">
          <a (click)="goToBack()" class="back-page"></a>
          <h2>Add Extras</h2>
        </div>
      </div>
    </div>
    <div class="title-bar-space d-block" >
        
      <div class="container vflexcheck">
        <div>
          <h1 class="heading-title">Complete Check-in</h1>
  
          <div class="seprationPart mt-5 ">                
            <div class="font-14"><strong>Your Stay Ehancements</strong></div>
          </div>
          <div class="seprationPart">
            <div class="productcheckinBox">
              <div class="pcb_img"><img [src]="upsellInfo.imageUrl" alt="image"/></div>
              <div class="pcb_text font-14">
                <strong>{{upsellInfo.upsellName}}</strong>
                <span>${{upsellInfo.price}}</span>
                <p>({{upsellInfo.quantity.quantity}} {{upsellInfo.quantity.quantityName}} early)</p>
              </div>
              <div class="clicktodots" (click)="abandonCart(true)"></div>
            </div>
          </div>
          <div class="seprationPart">                
            <div class="clearcart font-14" (click)="abandonCart(true)"><strong>Clear Cart</strong></div>
          </div>
        </div>
  
        <div class="mt-4">
          <div class="listwithTotal font-14">
            <ul>
              <li><strong>Charge to</strong></li>
              <li>
                <ng-container *ngIf="cardInfo.lastDigits">
                  {{cardInfo.brand}} {{cardInfo.lastDigits}}
                  <br>
                  <span style="color: #809DA7; cursor: pointer;" (click)="showPaymentScreen=true"><strong>Change</strong></span>
                </ng-container>
                <ng-container *ngIf="!cardInfo.lastDigits">
                  -
                  <br>
                  <span style="color: #809DA7; cursor: pointer;" (click)="showPaymentScreen=true"><strong>Add Payment</strong></span>
                </ng-container>
                
                
              </li>
            </ul>
            <ul class="mt-4">
              <li><strong>Total</strong></li>
              <li>${{totalPrice}}</li>
            </ul>
          </div>
          <button type="button" class="btn btn-primary w-100 mt-5" (click)="purchaseNow()">Purchase</button>
        </div>
      </div>
    
  
      <!-- <div class="bottom_popup" *ngIf="isMoreActionPopupVisible">
        <div class="closeThisPanel" (click)="isMoreActionPopupVisible=false"></div>
  
        <div class="options_link">
          <ul>
            <li><a (click)="abandonCart(false)"><span><img src="../../../assets/images/deleteicon.svg" alt="icon"/></span> Remove From Cart</a></li>
            <li><a href="#"><span><img src="../../../assets/images/editicon.svg" alt="icon"/></span> Edit Item Details</a></li>
            <li><a href="#"><span><img src="../../../assets/images/questinicon.svg" alt="icon"/></span> What is this charge?</a></li>
          </ul>
        </div>
      </div> -->
    </div>
  </ng-container>
  
  <ng-container *ngIf="!isPropertyHaveUpsells">
    <div class="v_flexbxx notfound_container">
      <div class="container">
        <div class="notfound_box">
          <div class="notfound_icon text-center">
            <img src="../../assets/images/upsells-empty.png" alt=""/>
          </div>
    
          <h3 class="mb-3">No Enhancements Found</h3>
          <p class="mb-0">Your operator doesn't offer any stay enhancements at this time </p>
        </div>
      </div>
    </div>
  </ng-container>  
</ng-template>
<ng-template #paymentScreenContent>
  <app-payment
    [selectedPaymentMethod]="cardInfo"
   (getPaymentMethod)="selectPaymentMethod($event)">
  </app-payment>
</ng-template>
<ng-template #showRequestResultContent>
  <div class="title-bar-space d-block">
      
    <div class="container">
      <ng-container *ngIf="(upsellRequestResult && (upsellRequestResult.status=='Success' || upsellRequestResult.status=='Approved')) || isEarlyCheckinCartSkipped; then successRequestResultContent else failureRequestResultContent">

      </ng-container>
      
      <ng-template #successRequestResultContent>
        <div class="guest_verification mt-4">
          <img src="../../../assets/images/success-request.svg" alt="">
          <h2 class="mt-5">You're all set!</h2>
          <h4 class="heading-title mt-4">Your host will notify you if they need anything else.</h4>                
        </div>
      </ng-template>
      <ng-template #failureRequestResultContent>
        <div class="guest_verification mt-4">
          <div class="mt-5 d-block text-center"><img src="../../../assets/images/request-failed-icon.svg" alt=""/></div>
          <div class="request_result_box mt-5 text-start">
              <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-{{upsellRequestResult.status.toLowerCase()}}">
                <img src="../../assets/images/doller-icon.svg" alt="icon" />Request {{upsellRequestResult.status}}</a>
              </p>
              <p class="mt-3"><strong>{{upsellInfo.upsellName}}</strong></p>
              <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span>${{totalPrice}}</span></p>
            </div>
        </div>
      </ng-template>
      
        
    </div>
        
  </div>
  

</ng-template>

