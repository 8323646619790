import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PropertyAmenitiesComponent } from './property-amenities.component';
import { AuthGuard } from '../core/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'property-amenities/:rid/:pid/:uid',
    component: PropertyAmenitiesComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertyAmenitiesRoutingModule {}
