import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.isAuth = false;
  }

  isAuth: boolean | undefined;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    this.userService.isAuthenticated.subscribe(
      (isAuth) => {
        this.isAuth = isAuth;
      }
    );
    
    if(this.isAuth){
      return true;
    }
    else{
      this.router.navigate(['/login']);
      return false;
    }
    

  }
}
