import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { RecommendationComponent } from './recommendation.component';
import { RecommendationRoutingModule } from './recommendation-routing.module';

@NgModule({
  imports: [
    SharedModule,
    RecommendationRoutingModule
  ],
  declarations: [
    RecommendationComponent
  ],
  providers: [    
  ]
})
export class RecommendationModule {}
