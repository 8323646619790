import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import {
  FooterComponent,
  HeaderComponent,
  SharedModule
} from './shared';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { GetStartedModule } from './get-started/get-started.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { ProfileModule } from './profile/profile.module';
import { PropertyGuideModule } from './property-guide/property-guide.module';
import { PropertyDetailModule } from './property-detail/property-detail.module';
import { PropertyAccessModule } from './property-access/property-access.module';
import { NoAuthGuard } from './core/services/no-auth-guard.service';
import { ReservationNotFoundModule } from './reservation-not-found/reservation-not-found.module';
import { PropertyDirectionModule } from './property-direction/property-direction.module';
import { PropertyWifiModule } from './property-wifi/property-wifi.module';
import { ContactHostModule } from './contact-host/contact-host.module';
import { TermsModule } from './terms/terms.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { PersonalDetailModule } from './personal-detail/personal-detail.module';
import { SwitchReservationModule } from './switch-reservation/switch-reservation.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { PropertyEnhancementsModule } from './property-enhancements/property-enhancements.module';
import { PropertyEnhancementModule } from './property-enhancement/property-enhancement.module';
import { RecommendationsModule } from './recommendations/recommendations.module';
import { RecommendationModule } from './recommendation/recommendation.module';
import { PurchaseHistoryModule } from './purchase-history/purchase-history.module';
import { SupportAppModule } from './support-app/support-app.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { PropertyLockModule } from './property-lock/property-lock.module';
import { SupportModule } from './support/support.module';
import { SurveyModule } from './survey/survey.module';
import { PairReservationModule } from './pair-reservation/pair-reservation.module';
import { GuestDetailModule } from './guest-detail/guest-detail.module';
import { GuestsModule } from './guests/guests.module';
import { PropertyAmenitiesModule } from './property-amenities/property-amenities.module';
import { PropertyAmenityModule } from './property-amenity/property-amenity.module';
import { PurchaseDetailModule } from './purchase-detail/purchase-detail.module';
import { SignInSupportModule } from './sign-in-support/sign-in-support.module';
import { CheckInFlowModule } from './checkin-flow/checkin-flow.module';
import { PassportModule } from './passport/passport.module';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent, FooterComponent, HeaderComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    HomeModule,
    GetStartedModule,
    SignUpModule,
    ProfileModule,
    PropertyGuideModule,
    PropertyDetailModule,
    PropertyAccessModule,
    ReservationNotFoundModule,
    PropertyDirectionModule,
    PropertyWifiModule,
    ContactHostModule,
    TermsModule,
    PrivacyPolicyModule,
    ChangePasswordModule,
    PersonalDetailModule,
    SwitchReservationModule,
    ForgotPasswordModule,
    PropertyEnhancementsModule,
    PropertyEnhancementModule,
    RecommendationsModule,
    RecommendationModule,
    PurchaseHistoryModule,
    SupportAppModule,
    SupportModule,
    PropertyLockModule,
    SurveyModule,
    PairReservationModule,
    GuestDetailModule,
    GuestsModule,
    PropertyAmenitiesModule,
    PropertyAmenityModule,
    PurchaseDetailModule,
    SignInSupportModule,
    CheckInFlowModule,
    PassportModule,

    AuthModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    NoAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

