<ng-container *ngIf="!showRequestResultSection && surveyDetail">
  <div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/home/{{reservationId}}" class="back-page"></a>
        <h2>{{surveyDetail.surveyTitle}}</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">
        
        <ng-container *ngFor="let item of surveyDetail.surveyQuestionModels">
          <h2 class="mt-3" *ngIf="item.questionType==surveyQuestionTypeEnum[surveyQuestionTypeEnum.Rating]">{{item.questionText}}</h2>
          <h4 class="mt-4" *ngIf="item.questionType==surveyQuestionTypeEnum[surveyQuestionTypeEnum.ShortAnswer]"><strong>{{item.questionText}}</strong></h4>
          <div class="smile-box mt-3" *ngIf="item.questionType==surveyQuestionTypeEnum[surveyQuestionTypeEnum.Rating]">
            <img [className]="activeSmileTab == checkoutRatingType.Positive ? 'active':''" src="../../assets/images/positive_smile.svg" alt="" (click)="activateSmileTab(checkoutRatingType.Positive)"/>
            <img [className]="activeSmileTab == checkoutRatingType.Neutral ? 'active':''" src="../../assets/images/nutrul_smile.svg" alt="" (click)="activateSmileTab(checkoutRatingType.Neutral)"/>
            <img [className]="activeSmileTab == checkoutRatingType.Negative ? 'active':''" src="../../assets/images/negative_smile.svg" alt="" (click)="activateSmileTab(checkoutRatingType.Negative)"/>

          </div>
          <div [class]="surveyDetail.surveyTitle=='Checkout'?'form-group d-block mt-4':'form-group d-block mt-4 mb-5'" *ngIf="item.questionType==surveyQuestionTypeEnum[surveyQuestionTypeEnum.ShortAnswer]">
            <textarea [(ngModel)]="checkoutNotes" class="form-control" placeholder="Comments..." rows="3"></textarea>
            
          </div>  
          
        </ng-container>
        
        <p class="mt-4" *ngIf="surveyDetail.surveyTitle=='Checkout'">{{surveyDetail.submitDescription}}</p>
    </div>
    
  </div>

  <div class="container">
    <button type="button" class="btn btn-primary w-100" (click)="submitSurveyForm()">{{surveyDetail.submitButtonText}}</button>
    <a routerLink="/home/{{reservationId}}" class="btn btn-secondary w-100 mt-2 go-back">Go Back</a>

  </div>

</ng-container>
<!-- <ng-container *ngIf="showRequestResultSection">
  <div class="center_flex" style="min-height:calc(100vh - 150px)">
    <div class="w-100">
      <div class="container">
        <div class="mt-5 d-block text-center"><img src="../../assets/images/check_tick.svg" alt="check"/></div>
         <div class="request_result_box mt-5 text-start">
            <h3><strong>Confirmed!</strong></h3>
            <p class="mt-3"><strong>You've checked out! We will let your host know.</strong></p>
          </div>
      </div>
    </div>
    
  </div>

</ng-container> -->

