import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { nextTick } from 'process';
import { PropertyService, SpinnerService } from '../core';

@Component({
  selector: 'app-property-wifi',
  templateUrl: './property-wifi.component.html',
  styleUrls: ['./property-wifi.component.scss']
})
export class PropertyWifiComponent implements OnInit {

  propertyAmenityId: number = 0;
  amenityItem: any;
  wifiName:string = "";
  wifiPassword:string = "";
  reservationId:number = 0;
  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private clipboardApi: ClipboardService,
    private location: Location,
    private toastr: ToastrService
    ) { 
      this.propertyAmenityId =  this.route.snapshot.params['id'];
      this.reservationId =  parseInt(this.route.snapshot.params['rid']);
    }

  ngOnInit(): void {
    this.getPropertyAmenity(this.propertyAmenityId);
  }

  getPropertyAmenity(propertyAmenityId: number){
    this.spinner.show();
    this.propertyService.getPropertyAmenityItem(propertyAmenityId).subscribe(
      {
        next:(response)=>{
          if(response && response.code == HttpStatusCode.Ok && response.data){
              this.amenityItem = response.data;  
              if(this.amenityItem.yourNetwork){
                this.wifiName = this.amenityItem.yourNetwork.wifiName 
                this.wifiPassword = this.amenityItem.yourNetwork.password 

              }
          }
          
        },
        error:(err)=>{
          this.spinner.hide()
        },
        complete:()=>this.spinner.hide()
      }
    );
  }

  copyCredential(){
    if(this.wifiName && this.wifiPassword){
      var credential = "Wi-Fi Name:"+ this.wifiName + "\n Password:"+ this.wifiPassword
      this.clipboardApi.copyFromContent(credential);
      this.toastr.info("Your wifi details has been copied!");
    }
    
  }

  goToBack() {
    this.location.back();
  }
}
