<ng-container *ngIf="isShowPaymentDetail; then paymentScreenContent else findOtherThenPayment"></ng-container>
<ng-template #findOtherThenPayment>
  <ng-container *ngIf="showRequestResultSection; then showRequestResultContent else damageWaiverPayment"></ng-container>
</ng-template>

<ng-template #damageWaiverPayment>
  <div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        
        <a (click)="doChangeStep('damageWaiverLanding')" class="back-page"></a>
        <h2>Damage Waiver</h2>
        <a class="skip" (click)="skipModule()" *ngIf="securityTransactionStageSkip">SKIP</a>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
      <div class="container verticleFlexdiv">
        
        <div class="">
            <h2 class="mt-3">Your host requires a damage waiver at this property.</h2>
            <p class="mt-3">A damage waiver fee of ${{damageWaiverFee}} is required to complete your check-in.</p>
            
        </div> 
        
        <div>
          <div class="listwithTotal font-14">
            <ul>
              <li><strong>Charge to</strong></li>
              
              <li>
                <ng-container *ngIf="cardInfo.lastDigits">
                  {{cardInfo.brand}} {{cardInfo.lastDigits}}
                  <br>
                  <span style="color: #809DA7; cursor: pointer;" (click)="isShowPaymentDetail=true"><strong>Change</strong></span>
                </ng-container>
                <ng-container *ngIf="!cardInfo.lastDigits">
                  -
                  <br>
                  <span style="color: #809DA7; cursor: pointer;" (click)="isShowPaymentDetail=true"><strong>Add Payment</strong></span>
                </ng-container>
                
                
              </li>
            </ul>
            <ul class="mt-4">
              <li><strong>Total</strong></li>
              <li>${{damageWaiverFee}}</li>
            </ul>
          </div>
         
          <button class="btn btn-primary w-100 mt-4" type="button" (click)="submit()">Purchase</button>
        </div>
        
      </div>
    
  </div>
</ng-template>
<ng-template #paymentScreenContent>
  <app-payment
    [selectedPaymentMethod]="cardInfo"
    (getPaymentMethod)="selectPaymentMethod($event)">
  </app-payment>
</ng-template>
<ng-template #showRequestResultContent>
  <div class="center_flex" style="min-height:calc(100vh - 150px)">
    <div class="w-100">
      <div class="container">
        <ng-container *ngIf="isSuccessResult">
          <div class="mt-5 d-block text-center"><img src="../../assets/images/check_tick.svg" alt="check"/></div>
          <div class="request_result_box mt-5 text-start">
             <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-approved"><img src="../../assets/images/doller-icon.svg" alt="icon" />Approved</a></p>
             <p class="mt-3"><strong>Damage Waiver</strong></p>
             <p class="sub-title colordark">Your host received your damage waiver. This covers accidental damages that may occur during your stay.</p>
             <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span>${{damageWaiverFee}}</span></p>
             <p class="sub-title colordark" >Charged to {{cardInfo.brand}} {{cardInfo.lastDigits}}</p>
 
           </div>
          <a (click)="moduleComplete()" class="btn btn-primary mt-5 w-100">Done</a>

        </ng-container>
        <ng-container *ngIf="!isSuccessResult">
          <div class="mt-5 d-block text-center"><img src="../../assets/images/payment-failed.svg" style="width:70px" alt="check"/></div>
          <div class="request_result_box mt-5 text-start">
             <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-failed"><img src="../../assets/images/doller-icon.svg" alt="icon" />Waiver Failed</a></p>
             <p class="mt-3"><strong>Damage Waiver</strong></p>
             <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span>${{damageWaiverFee}}</span></p>
             <p class="sub-title colordark" >Failed with {{cardInfo.brand}} {{cardInfo.lastDigits}}</p>
 
           </div>
          <a (click)="moduleCompleteFailed()" class="btn btn-primary mt-5 w-100">Done</a>

        </ng-container>
         
      </div>
    </div>
    
  </div>
</ng-template>
