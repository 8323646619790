import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckoutRatingType, ErrorMessages, ReservationService, SpinnerService, SurveyQuestionTypeEnum, SurveyService } from '../core';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})


export class SurveyComponent implements OnInit {

  reservationId: number = 0;
  surveyId:number = 0;
  activeSmileTab: string = '';
  checkoutRatingType = CheckoutRatingType;
  showRequestResultSection:boolean = false;
  surveyDetail:any;
  surveyQuestionTypeEnum = SurveyQuestionTypeEnum;
  reservationRating:string = '';
  checkoutNotes:string = '';
  surveyForm = this.fb.group({
    reservationId: new FormControl(0, [Validators.required]),
    surveyId: new FormControl(0, [Validators.required]),
    surveyQuestionAnswers: this.fb.array([])
  })
  constructor(
    private reservationService: ReservationService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private router:Router,
    private surveyService: SurveyService,
    private fb: FormBuilder
  ) { 
    this.reservationId = parseInt(this.route.snapshot.params['rid'])
    this.surveyId = parseInt(this.route.snapshot.params['sid'])
  }

  ngOnInit(): void {
    
    this.getSurveyDetail();
    
  }

  getSurveyDetail(){
    this.surveyService.getSurveyDetailById(this.reservationId, this.surveyId)
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.surveyDetail = response.data;

              this.surveyForm.patchValue({
                reservationId: this.reservationId,
                surveyId: this.surveyId,
              })

              
            }
          },
          error:(e) => {
            this.router.navigateByUrl('/home/'+ this.reservationId);
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  get f(){
    return this.surveyForm.controls;
  }

  get questionList() {
    return this.surveyForm.controls["surveyQuestionAnswers"] as FormArray;
  }

  addQuestionForm(surveyQuestionId:number, surveyAnswer:string):any{
    
    let questionForm = this.fb.group({
      surveyQuestionId: new FormControl(surveyQuestionId),
      surveyAnswer: new FormControl(surveyAnswer),        

    });
    return this.questionList.push(questionForm);
  }
  activateSmileTab(smileType: CheckoutRatingType){
    this.reservationRating= smileType;
    this.activeSmileTab = smileType;
    
  }

  submitSurveyForm(){
    
    (this.surveyForm.controls["surveyQuestionAnswers"] as FormArray).clear();
    this.surveyDetail.surveyQuestionModels.forEach((data:any) => {
      if(data.questionType == this.surveyQuestionTypeEnum[SurveyQuestionTypeEnum.Rating]){
        this.surveyForm.patchValue({
          surveyQuestionAnswers: this.addQuestionForm(data.surveyQuestionId,this.reservationRating)
        });
      }
      if(data.questionType == this.surveyQuestionTypeEnum[SurveyQuestionTypeEnum.ShortAnswer]){
        this.surveyForm.patchValue({
          surveyQuestionAnswers: this.addQuestionForm(data.surveyQuestionId,this.checkoutNotes)
        });
      }
      
    });
    
    this.spinner.show();
    this.surveyService.submitSurvey(this.surveyForm.value)
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.router.navigateByUrl('/home/'+ this.reservationId);
            }
          },
          error:(e) => {
            this.toastr.error(ErrorMessages.InternalServerError);
            this.spinner.hide();
          },
          complete: () => this.spinner.hide()
        })
  }
}
