import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorMessages, PaymentType, SpinnerService, UpsellService, UpsellType } from '../core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any; 
@Component({
  selector: 'app-property-enhancement',
  templateUrl: './property-enhancement.component.html',
  styleUrls: ['./property-enhancement.component.scss']
})
export class PropertyEnhancementComponent implements OnInit {

  reservationId: number = 0;
  upsellId: number = 0;
  unitId: number = 0;
  propertyEnhancement: any;
  upsellType = UpsellType;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,    
    items:1,
    autoplay:true
  }

  submitted: boolean = false;
  totalPrice: number = 0;
  showUpsellDetail: boolean = true;
  showConfirmRequestSection: boolean = false;
  showSelectPaymentMethodSection: boolean = false;
  showRequestResultSection: boolean = false;
  isSuccessResult: boolean = false;
  upsellRequestResult: any;
  isShowPayNow: boolean = true;
  stripePaymentType:number = PaymentType.Stripe;
  isPropertyHaveUpsells:boolean = true;
  isShowPaymentMethods:boolean = false;
  paymentMethods: any;
  isPaymentMethodSelected: boolean = false;

  upsellForm= new FormGroup({
    itemId: new FormControl(0, Validators.required),
    itemNotes: new FormControl(''),
    paymentMethodKey: new FormControl(''),
    reservationId: new FormControl(0, Validators.required),
    paymentType: new FormControl(0),
    price:new FormControl(0),
    quantityFields: this.fb.array([])
  });


  activeTab:number = 0;
  cardInfo:any = { paymentMethodKey:'', lastDigits:'', brand:''};

  constructor(
    private upsellService: UpsellService,
    private route: ActivatedRoute,
    private location: Location,
    private spinner: SpinnerService,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.upsellId = Number(route.snapshot.params['upid']);
    this.unitId = Number(route.snapshot.params['uid']);
    this.reservationId = Number(route.snapshot.params['rid']);
   }

  ngOnInit(): void {
    this.getPropertyEnhancement();
  }

  changeTab(tabIndex:number, price:number, quantity: number){
    this.activeTab = tabIndex;
    this.totalPrice = price;
    this.upsellForm.setControl('quantityFields', this.fb.array([]));
    this.upsellForm.setControl('quantityFields', this.getHourlyQuantityFields(quantity));
  }

  get quantityFields() {
    return this.upsellForm.controls["quantityFields"] as FormArray;
  }

  getQuantityFields(quantityFieldsArr:any):any{
      quantityFieldsArr.forEach((element:any) => {
        var initialQuantity = element.quantityName == "Reservation Length" ? element.quantity : 1;
        var perQuantityName = element.quantityName == "Reservation Length" ? "Reservation Length" : element.perQuantityName;
        this.quantityFields.push(
          this.fb.group({
            quantityNo: new FormControl(element.quantityNo),
            quantity: new FormControl(initialQuantity),
            perQuantityName: new FormControl(perQuantityName)
          })
        )
      });
      
    return this.quantityFields;
  }

  getHourlyQuantityFields(quantity: number){
    this.quantityFields.push(
      this.fb.group({
        quantityNo: new FormControl(1),
        quantity: new FormControl(quantity),
        perQuantityName: new FormControl('')
      })
    )
    return this.quantityFields;
  }

  getPropertyEnhancement(){
    this.spinner.show()
    this.upsellService.getPropertyUpsellDetail(this.reservationId, this.upsellId).subscribe(
      {
        next: (response) => {
          
          if(response && response.code == HttpStatusCode.Ok && response.data){
            this.propertyEnhancement = response.data; 

            this.upsellForm.patchValue({
              itemId: this.upsellId,
              reservationId: this.reservationId,
              price: this.propertyEnhancement.price.price
            });
            this.totalPrice = this.propertyEnhancement.price.price;
            if(this.propertyEnhancement.upsellType == this.upsellType.EarlyCheckIn || this.propertyEnhancement.upsellType == this.upsellType.LateCheckout){
              this.setHoursTabScrollBar();
              if(this.propertyEnhancement.hourlyPricing){
                
                this.upsellForm.setControl('quantityFields', this.getHourlyQuantityFields(this.propertyEnhancement.hourlyPricing[0].quantity));
              }
            }
            else{
              this.upsellForm.setControl('quantityFields', this.getQuantityFields(this.propertyEnhancement.quantityFields));

            }
            
            this.onQuantityChange();

            
          }
        },
        error: (e)=>{
          this.spinner.hide();
          this.isPropertyHaveUpsells=false;
        },
        complete: ()=> {
          this.spinner.hide() 
        }
      }
      
    );
  }

  goToBack() {    
    this.location.back();    
  }

  GetQuantityOptions(quantityValue: number){
    return new Array(quantityValue);
  }

  getUpsellPriceDetail(){
    var quantityFieldsValue = this.upsellForm.get('quantityFields')?.value;
    var upsellPrice = this.upsellForm.get('price')?.value;
    var priceDetailStr = "";
    var priceDetailQuantityStr = "";
    
    if(quantityFieldsValue && quantityFieldsValue.length > 0){
      quantityFieldsValue.forEach((element:any)=>{
        if(element.perQuantityName == "Reservation Length"){
          priceDetailQuantityStr += " * "+ element.quantity +" day";
        }
        else{
          priceDetailQuantityStr += " * "+ element.quantity;
        }
      });   
    }

    if(upsellPrice != null){
         priceDetailStr = `($${upsellPrice.toString() + priceDetailQuantityStr})`;
    }
    return priceDetailStr;
  }

  onQuantityChange() {
    var quantityFieldsValue = this.upsellForm.get('quantityFields')?.value;
    var upsellPrice = this.upsellForm.get('price')?.value;
    var priceDetailQuantityStr = 1;
    if(quantityFieldsValue && quantityFieldsValue.length > 0){
      quantityFieldsValue.forEach((element:any)=>{
        priceDetailQuantityStr *=  element.quantity;
      });   
    }

    this.totalPrice = Number(upsellPrice) * priceDetailQuantityStr;    
  }

  getTotalPrice(){
    return `$${this.totalPrice.toFixed(2)}`;
  }

  onPurchaseNow(){
    
    this.showUpsellDetail = false;
    this.showConfirmRequestSection = true;
  }

  onPaymentCardSelection(){
    this.showConfirmRequestSection = true;
  }

  onSelectPaymentType(paymentType: number){
    this.upsellForm.patchValue({
      paymentType: paymentType
    });

    if(paymentType == PaymentType.Stripe){
      this.isShowPayNow = false;
      this.isShowPaymentMethods = true;
      this.showConfirmRequestSection = false;
      this.setCustomScrollBar();
    }
    else{
      this.submitForm();
    }
  }

  goToUpsellDetail(){
    this.isShowPayNow = true;
    this.showConfirmRequestSection = false;
    this.showUpsellDetail = true;
    this.isShowPaymentMethods = false;
    this.isPaymentMethodSelected = false;
    this.paymentMethods = undefined;
  }

  selectPaymentMethod(request:any){
    this.cardInfo = request;
    this.isShowPaymentMethods = false;
    this.isPaymentMethodSelected = true;
    this.showConfirmRequestSection = true;
    this.upsellForm.patchValue({
      paymentMethodKey : request.paymentMethodKey
    });
    
  }

  
  setCustomScrollBar(){
    setTimeout(() => {
      $("#add-card-box").mCustomScrollbar({
        axis:"y",
        advanced:{
          autoExpandHorizontalScroll:true
        }
      });
      
    });
  }

  setHoursTabScrollBar(){
    
    setTimeout(() => {   
      $("#hoursTab").mCustomScrollbar({
        axis:"x",
        advanced:{
          autoExpandHorizontalScroll:true
        }
      });
    });
  }

  submitForm(){       

    if(this.propertyEnhancement.isFree){
      this.upsellForm.patchValue({
        paymentType: PaymentType.Free
      });
    }
    if(this.upsellForm.invalid){
      return;
    }

    if(this.upsellForm.value.quantityFields && this.upsellForm.value.quantityFields.length > 0){
      this.upsellForm.value.quantityFields = this.upsellForm.value.quantityFields.filter(function(obj:any) {
          return obj.perQuantityName !== "Reservation Length";
      });
    }
    this.spinner.show();

    this.upsellService.sendUpsellRequest(this.upsellForm.value)
      .subscribe({
        next: (response) =>{
          this.showRequestResultSection = true;
          this.showConfirmRequestSection = false;

          if(response && response.code == HttpStatusCode.Ok && response.data && response.data.status && response.data.status.toLowerCase() != "failure"){
            this.upsellRequestResult = response.data;
            this.isSuccessResult = true;
            this.showUpsellDetail = false;
          }
          // else{
          //   this.toastrService.error(ErrorMessages.InternalServerError);
          // }
          
        },
        error: (e) => {
          this.spinner.hide();
          this.showRequestResultSection = false;
          this.showConfirmRequestSection = false;

         // this.toastrService.error(ErrorMessages.InternalServerError);
        },
        complete: () => this.spinner.hide()
      });

  }
}
