import { Component, OnInit } from '@angular/core';
import { UserService } from './core';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './core/services/mixpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private userService: UserService, private mixpanelService: MixpanelService) { }

  ngOnInit() {
    console.log(environment.production)
    this.userService.populate();
    this.mixpanelService.init();
  }
}
