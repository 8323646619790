import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from './user.service';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.isAuth = false;

  }

  isAuth: boolean | undefined;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.userService.isAuthenticated.subscribe(
      (isAuth) => {
        this.isAuth = isAuth;
      }
    );
    
    if(this.isAuth){
      this.router.navigate(['/home']);
      return false;
    }
    else{
      return true;
    }
    
  }
}
