import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class JwtService {

  public jwtTokenSubject = new BehaviorSubject<string>(localStorage.getItem('jwtToken')? (localStorage.getItem('jwtToken')!):{} as string);
  public jwtToken  = this.jwtTokenSubject.asObservable().pipe();

  constructor(private apiService : ApiService){
    let token = localStorage.getItem('jwtToken');
        if (token)
          this.saveToken(token, false); 
  }

  getToken(): any {    
    return localStorage.getItem('jwtToken');
  }

  saveToken(token: string, isSave: boolean) {    
    if(isSave)
      localStorage.setItem('jwtToken', token);
  }

  destroyToken() {
    localStorage.removeItem('userDetail');  
    localStorage.removeItem('jwtToken');
  }

  refreshToken(){
    this.apiService.get('/v1/Authentication/GetToken?ClientSecret='+ environment.clientSecret)
        .subscribe({
          next:(response) => {
                if(response && response.data){
                this.saveToken(response.data.token,true);
                return response.data.token;
            }
          },
          error:(e) => {
            console.log(e)
          }
        })
        
  }
}
