<ng-container *ngIf="purchaseDetail">
<ng-container *ngIf="purchasedItemType==purchaseItemType.Upsell">
    <div class="page-box" *ngIf="purchaseDetail">
        <div class="banner-with-header">
            <div class="banner-top">
                <a (click)="goToBack()" class="backbtn_banner"></a>
                <img [src]="purchaseDetail.banner" >           
            </div>
            <h1 class="page-name">
              {{purchaseDetail.title}} 
              <span [class]="purchaseDetail.status.toLowerCase()">
                {{getPurchasedStatus(purchaseDetail.status)}}
                
              </span>
            </h1>
        </div>
        <div>
            <div class="container">
                <div class="sub-title">{{purchaseDetail.description}}</div>
                <div class="mt-4" *ngIf="purchaseDetail.status.toLowerCase()=='declined' && purchaseDetail.notes">
                  <strong>Host Note:</strong> 
                  <p class="mt-4">{{purchaseDetail.notes}}</p> 
                </div>
                <div class="mt-4">
                   <strong>Total:</strong> ${{purchaseDetail.totalCharged.toFixed(2)}} 
                    
                </div>
            </div>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="purchasedItemType==purchaseItemType.SecurityDeposit">
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <a (click)="goToBack()" class="back-page"></a>
            <h2>Security Deposit</h2>
          </div>
        </div>
      </div>
      <div class="title-bar-space d-block">
  
        <div class="container sd_full">
          <div class="sd_top">
            <div class="sd_titles">
              <h2>Security Deposit</h2>
              <span class="{{purchaseDetail.status.toLowerCase()}}">{{getPurchasedStatus(purchaseDetail.status)}}</span>
            </div>
            <div class="sub-title mt-4">{{purchaseDetail.description}}</div>
          </div>


          <div class="sd_bottom">
            <div class="showTotal_val mt-4">
                <strong>Host:</strong>
                <div>
                  <label class="host_name">{{purchaseDetail.operatorName}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Date Submitted:</strong>
                <div>
                  <label>{{purchaseDetail.dateSubmitted | date:'EEE MMM d, y'}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Status:</strong>
                <div>
                  <label>{{getPurchasedStatus(purchaseDetail.status)}}</label>                
                </div>
            </div>
            <ng-container *ngIf="purchaseDetail.status.toLowerCase()=='onhold'">
              <div class="showTotal_val mt-4">
                <strong>Total Held:</strong>
                <div>
                  <label>${{purchaseDetail.total}}</label>                  
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="purchaseDetail.status.toLowerCase()!='onhold'">
              <div class="showTotal_val mt-4">
                <strong>Total Released:</strong>
                <div>
                  <label>${{purchaseDetail.captureTotal>0?purchaseDetail.captureTotal-purchaseDetail.total:purchaseDetail.total}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Total Charged:</strong>
                <div>
                  <label>${{purchaseDetail.captureTotal}}</label>                  
                </div>
            </div>
            </ng-container>
            
          </div>

        </div>
    </div>
</ng-container>
<ng-container *ngIf="purchasedItemType==purchaseItemType.DamageWaiver">
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <a (click)="goToBack()" class="back-page"></a>
            <h2>Damage Waiver</h2>
            
          </div>
        </div>
      </div>
      <div class="title-bar-space d-block">
  
        <div class="container sd_full">
          <div class="sd_top">
            <div class="sd_titles">
              <h2>Damage Waiver</h2>
              <span class="{{purchaseDetail.status.toLowerCase()}}">{{getPurchasedStatus(purchaseDetail.status)}}</span>
            </div>
            <div class="sub-title mt-4">{{purchaseDetail.description}}</div>
          </div>


          <div class="sd_bottom">
            <div class="showTotal_val mt-4">
                <strong>Host:</strong>
                <div>
                  <label class="host_name">{{purchaseDetail.operatorName}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Date Submitted:</strong>
                <div>
                  <label> {{purchaseDetail.dateSubmitted | date:'EEE MMM d, y'}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Status:</strong>
                <div>
                  <label>{{getPurchasedStatus(purchaseDetail.status)}}</label>                  
                </div>
            </div>
            <div class="showTotal_val mt-4">
                <strong>Total Charged:</strong>
                <div>
                  <label>${{purchaseDetail.total}}</label>                  
                </div>
            </div>
            
          </div>
        </div>
    </div>
</ng-container>
</ng-container>
