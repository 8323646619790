import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CmsPageType, CommonApiService, SpinnerService } from '../core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  termsAndCondition: any;

  constructor(
    private commonApiService: CommonApiService,
    private spinner: SpinnerService,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.getTermsAndConditions();
  }

  getTermsAndConditions(){
    this.spinner.show();
    this.commonApiService.getCmsPage(CmsPageType.TermsAndCondition).subscribe(
       {
        next:(response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            this.termsAndCondition = response.data;
          }
        },
        error:(err) => {
          this.spinner.hide();
        },
        complete:() => this.spinner.hide()
      }
    );
  }

  goToBack() {
    this.location.back();
  }
}
