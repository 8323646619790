<ng-container *ngIf="showUpsellDetail">
  <div class="page-box" *ngIf="propertyEnhancement">
    <div class="banner-with-header" *ngIf="propertyEnhancement.upsellType == upsellType.Standard">
        <div class="banner-top">
            <a (click)="goToBack()" class="backbtn_banner"></a>
            <owl-carousel-o [options]="customOptions" class="sliderowl">   
              <ng-container *ngFor="let slideBanner of propertyEnhancement.image_urls">
                  <ng-template carouselSlide>
                  <img [src]="slideBanner" >
                  </ng-template>
              </ng-container>             
            </owl-carousel-o>            
        </div>
        <h1 class="page-name">
          {{propertyEnhancement.itemName}} 
          <span *ngIf="propertyEnhancement.isFree">Free</span>
          <span *ngIf="!propertyEnhancement.isFree">
            {{propertyEnhancement.price.displayPrice}} 
            
          </span>
        </h1>
    </div>
    <div class="title-bar" *ngIf="propertyEnhancement.upsellType == upsellType.EarlyCheckIn || propertyEnhancement.upsellType == upsellType.LateCheckout">
      <div class="container">
        <div class="title-bar-box">
          <a (click)="goToBack()" class="back-page"></a>
          <h2>{{propertyEnhancement.itemName}}</h2>
        </div>
      </div>
    </div>
      
    <div [className]="propertyEnhancement.upsellType == upsellType.Standard ? '' : 'title-bar-space d-block'">
      <div class="container">
        <form [formGroup]="upsellForm" autocomplete="off">
          
          <ng-container *ngIf="propertyEnhancement.upsellType == upsellType.Standard">
            <div class="mb-4" *ngIf="propertyEnhancement.upsellType == upsellType.Standard && propertyEnhancement.externalURL">
              <a class="action-link" [href]="propertyEnhancement.externalURL" target="_blank"><strong>Read More</strong></a>
            </div>
            <div class="sub-title" [innerHTML]="propertyEnhancement.itemDescription">
  
            </div>
            <ng-container *ngIf="propertyEnhancement.quantityFields" formArrayName="quantityFields">
              <ng-container *ngFor="let item of quantityFields.controls;let index= index" >
                <div [formGroupName]="index" class="bxdatashown">
                  <label># {{propertyEnhancement.quantityFields[index].quantityName}}</label>
                  <ng-container *ngIf="propertyEnhancement.quantityFields[index].quantityName=='Reservation Length'">
                      <span class="rvalues">{{propertyEnhancement.quantityFields[index].quantity}} {{propertyEnhancement.quantityFields[index].perQuantityName}}</span>
                  </ng-container>
  
                  <ng-container *ngIf="propertyEnhancement.quantityFields[index].quantityName!='Reservation Length'">
                    <select formControlName="quantity" (change)="onQuantityChange()" class="form-select form-control">
                      <option *ngFor='let optionValue of GetQuantityOptions(propertyEnhancement.quantityFields[index].quantity);let j= index' [ngValue]="j+1" >{{j+1}} {{propertyEnhancement.quantityFields[index].perQuantityName}}</option>
                    </select>
                  </ng-container>
                    
                </div>
            </ng-container>
  
            </ng-container>
            
            <p class="sub-title mt-4" style="color: #000;"><strong>Notes or Special Requests</strong></p>
            <textarea placeholder="Notes..." formControlName="itemNotes" class="form-control supporttextarea mt-2"></textarea>
              
            <p class="sub-title mt-4" style="color: #AAAAAA;" *ngIf="propertyEnhancement.provider"><strong>Provided by {{propertyEnhancement.provider}}</strong></p>
          </ng-container> 
          
          <ng-container *ngIf="propertyEnhancement.upsellType == upsellType.EarlyCheckIn">
            <h2 class="mt-2">Good News! Early check-in may be available for your stay. </h2>
            <div class="mt-3 sub-title" [innerHTML]="propertyEnhancement.itemDescription">
  
            </div>
            <h6 class="mt-5"><b>This unit's standard check-in is scheduled at {{propertyEnhancement.checkInTime | date:'h:mm a'}} </b></h6>
            <div class="mt-4"> 
              <div class="options-box" id="hoursTab">
                <div [className]="activeTab == i? 'ob-box time-scheduled active' : 'ob-box time-scheduled'" *ngFor="let hoursAvailable of propertyEnhancement.hourlyPricing;let i = index">
                  <a href="javascript:void(0)" (click)="changeTab(i, hoursAvailable.price, hoursAvailable.quantity)">
                    <p><b>{{hoursAvailable.name}} </b><br>${{hoursAvailable.price}}</p>
                  </a>
                </div>                
              </div>
            </div>
          </ng-container>     
          <ng-container *ngIf="propertyEnhancement.upsellType == upsellType.LateCheckout">
            <h2 class="mt-2">Good News! Late checkout may be available. </h2>
            <div class="mt-3 sub-title" [innerHTML]="propertyEnhancement.itemDescription">
  
            </div>
            <h6 class="mt-5"><b>This unit's standard checkout is scheduled at {{propertyEnhancement.checkOutTime | date:'h:mm a'}} </b></h6>
            <div class="mt-4"> 
              <div class="options-box" id="hoursTab">
                <div [className]="activeTab == i? 'ob-box time-scheduled active' : 'ob-box time-scheduled'" *ngFor="let hoursAvailable of propertyEnhancement.hourlyPricing;let i = index">
                  <a href="javascript:void(0)" (click)="changeTab(i, hoursAvailable.price, hoursAvailable.quantity)">
                    <p><b>{{hoursAvailable.name}} </b><br>${{hoursAvailable.price}}</p>
                  </a>
                </div>                
              </div>
            </div>
          </ng-container>       

            <div class="showTotal_val mt-4">
              <strong>Total</strong>
              <div>
                <label [innerHTML]="getTotalPrice()"></label>
                <span [innerHTML]="getUpsellPriceDetail()" *ngIf="propertyEnhancement.upsellType == upsellType.Standard">
                </span>
              </div>
            </div>
            <button class="btn btn-primary w-100 mt-4" type="button" (click)="onPurchaseNow()">Purchase Now</button>
           
        </form>
      </div>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="showConfirmRequestSection">
  <div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a (click)="goToUpsellDetail()" class="back-page"></a>
        <h2>Confirm Request</h2>
      </div>
    </div>
  </div>
  <div class="title-bar-space d-block">
      
    <div class="container">
       <div class="paymentbox_sec mt-5">
        <div class="pr_items">
          <div><img [src]="propertyEnhancement.image_urls[0]" alt="image"/></div>
          <!-- <span>3 items</span> -->
        </div>
        <div class="pr-name mt-4">{{propertyEnhancement.itemName}}</div>
        <div class="pr-total mt-2" *ngIf="propertyEnhancement.isFree" >Free</div>
        <div class="pr-total mt-2" *ngIf="!propertyEnhancement.isFree" [innerHTML]="getTotalPrice()"></div>
        <ng-container *ngIf="!propertyEnhancement.isFree">
          <ng-container  *ngFor="let item of propertyEnhancement.paymentTypes;let i = index">
            <ng-container *ngIf="isShowPayNow">
              <ng-container *ngIf="i==0">
                <button class="btn btn-primary w-100 mt-5" type="button" (click)="onSelectPaymentType(item.paymentType)">{{item.paymentTypeLabel}}</button>
              </ng-container>
              <ng-container *ngIf="i > 0">
                <div class="orpaywith">Or</div>
                <button class="btn btn-primary w-100" type="button" *ngIf="isShowPayNow" (click)="onSelectPaymentType(item.paymentType)">{{item.paymentTypeLabel}}</button>  
              </ng-container>
            </ng-container>
            
          </ng-container>

          
        </ng-container>
        <ng-container *ngIf="isPaymentMethodSelected || propertyEnhancement.isFree">
          <button class="btn btn-primary w-100 mt-4" type="button" (click)="submitForm()" >Proceed</button>

        </ng-container>
        
       </div>
    </div>
  
</div>
  
</ng-container>
<ng-container *ngIf="isShowPaymentMethods">
  <app-payment
    [selectedPaymentMethod]="cardInfo"
    (getPaymentMethod)="selectPaymentMethod($event)">
  </app-payment>

</ng-container>

<ng-container *ngIf="showRequestResultSection">
  <div class="center_flex" style="min-height:calc(100vh - 150px)">
    <div class="w-100">
      <div class="container">
        <ng-container *ngIf="isSuccessResult">
          <div class="mt-5 d-block text-center"><img src="../../assets/images/check_tick.svg" alt="check"/></div>
          <div class="request_result_box mt-5 text-start">
             <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-{{upsellRequestResult.status.toLowerCase()}}"><img src="../../assets/images/doller-icon.svg" alt="icon" />Request {{upsellRequestResult.status}}</a></p>
             <p class="mt-3"><strong>{{propertyEnhancement.itemName}}</strong></p>
             <p class="sub-title colordark" *ngIf="upsellRequestResult.status.toLowerCase()!='approved'">You'll receive a notification once your purchased is approved by host</p>
             <p class="sub-title colordark" >{{upsellRequestResult.guestCheckInUnitTime | date:'EEE MMM d, y - hh:mm a'}}</p>
             <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span [innerHTML]="getTotalPrice()"></span></p>
           </div>
        </ng-container>
        <ng-container *ngIf="!isSuccessResult">
          <div class="mt-5 d-block text-center"><img src="../../assets/images/payment-failed.svg" style="width:70px" alt="check"/></div>
          <div class="request_result_box mt-5 text-start">
             <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-failed"><img src="../../assets/images/doller-icon.svg" alt="icon" />Request Failed</a></p>
             <p class="mt-3"><strong>{{propertyEnhancement.itemName}}</strong></p>
             <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span [innerHTML]="getTotalPrice()"></span></p>
 
           </div>
        </ng-container>
        
      </div>
    </div>
    <div class="w-100">
      <div class="container">
        <a routerLink="/property-enhancements/{{reservationId}}/{{unitId}}" class="btn btn-primary mt-5 w-100">Done</a>
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="!isPropertyHaveUpsells">
  <div class="v_flexbxx notfound_container">
    <div class="container">
      <div class="notfound_box">
        <div class="notfound_icon text-center">
          <img src="../../assets/images/upsells-empty.png" alt=""/>
        </div>
  
        <h3 class="mb-3">No Enhancements Found</h3>
        <p class="mb-0">Your operator doesn't offer any stay enhancements at this time </p>
      </div>
    </div>
  </div>
</ng-container>  
