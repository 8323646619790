
 <ng-container *ngIf="!isShowVerificationTerms"> 
  <ng-container [ngSwitch]="currentStep">
    
    <app-guest-verification *ngSwitchCase="'landingInfo'"      
      [reservationId]="reservationId"   
      [verificationStageSkip]="verificationStageSkip"   
      (changeChildStep)="changeStep($event)"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"
      ></app-guest-verification>

    <app-confirm-detail *ngSwitchCase="'personalInfo'"
      [startingForm]="userForm.get('personalInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('personalInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (showHideSpinner)="showHideSpinner($event)"
      (skipModuleStep)="skipModule($event)"
    ></app-confirm-detail>
    
    <app-collect-dob 
      *ngSwitchCase="'dobInfo'" 
      [startingForm]="userForm.get('dobInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('dobInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"
    ></app-collect-dob>
    <app-address 
      *ngSwitchCase="'personalAddressInfo'" 
      [startingForm]="userForm.get('personalAddressInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('personalAddressInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"
    ></app-address>
    <app-terms 
      *ngSwitchCase="'termsConditionInfo'" 
      [startingForm]="userForm.get('termsConditionInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('termsConditionInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"

    ></app-terms>
    <app-id-proof 
      *ngSwitchCase="'idProofInfo'" 
      [startingForm]="userForm.get('idProofInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('idProofInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"

    ></app-id-proof>
    <app-selfie 
      *ngSwitchCase="'selfieInfo'" 
      [startingForm]="userForm.get('selfieInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('selfieInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (showCommonTermsConditions)="showCommonTermsCondition($event)"
      (skipModuleStep)="skipModule($event)"

    ></app-selfie>
    <app-verification-progress 
      *ngSwitchCase="'progressBarInfo'" 
      [startingForm]="userForm.get('progressBarInfo')?.value" 
      [reservationId]="reservationId"
      [verificationStageSkip]="verificationStageSkip"   
      (subformInitialized)="subformInitialized('progressBarInfo', $event)" 
      (changeChildStep)="changeStep($event)"
      (submitForm)="submitForm()"
      (skipModuleStep)="skipModule($event)"
    ></app-verification-progress>
  </ng-container>
</ng-container>
  
  <ng-container *ngIf="isShowVerificationTerms">
  
    <div class="error_flex">
        <div class="d-block">        
            <div class="container">
                <div class="guest_verification" [innerHTML]="termsConditions">
                    
                </div>
          </div>          
        </div>  
        <div class="container text-center">
            <div class="ft_box_close">
                <a class="close_icon_fix" href="javascript:void(0)" (click)="showCommonTermsCondition(false)"> <img src="../../../assets/images/close_icons.svg" alt=""></a>
            </div>
        </div>
      </div>
     
  </ng-container>
