import { Observable ,  BehaviorSubject } from 'rxjs';

export class CommonService{
    public phoneNumberSubject = new BehaviorSubject<string>('');
    public newGuestPhoneNumber  = this.phoneNumberSubject.asObservable().pipe();

    public phoneNumberWithCodeSubject = new BehaviorSubject<string>('');
    public newGuestPhoneNumberWithCode  = this.phoneNumberWithCodeSubject.asObservable().pipe();

    public phoneNumberCodeSubject = new BehaviorSubject<string>('');
    public newGuestPhoneNumberCode  = this.phoneNumberCodeSubject.asObservable().pipe();

    public countryIdSubject = new BehaviorSubject<number>(0);
    public newGuestCountryId  = this.countryIdSubject.asObservable().pipe();

    public reservationIdSubject = new BehaviorSubject<number>(0);
    public currentReservationId  = this.reservationIdSubject.asObservable().pipe();

    public showDownloadAppSubject = new BehaviorSubject<boolean>(true);
    public showDownloadApp  = this.showDownloadAppSubject.asObservable().pipe();

    constructor(){

    }

    setPhoneNumber(phoneNumber: string) {       
        this.phoneNumberSubject.next(phoneNumber);
    }

    destroyPhoneNumber(){
        this.phoneNumberSubject.next('');
    }

    setPhoneNumberWithCode(phoneNumber: string) {       
        this.phoneNumberWithCodeSubject.next(phoneNumber);
    }

    destroyPhoneNumberWithCode(){
        this.phoneNumberWithCodeSubject.next('');
    }

    setPhoneNumberCode(phoneCodePrefix: string) {       
        this.phoneNumberCodeSubject.next(phoneCodePrefix);
    }

    destroyPhoneNumberCode(){
        this.phoneNumberCodeSubject.next('');
    }

    setCountryID(countryID: number) {       
        this.countryIdSubject.next(countryID);
    }

    destroyCountryID(){
        this.countryIdSubject.next(0);
    }

    setCurrentReservationID(reservationID: number) {       
        this.reservationIdSubject.next(reservationID);
    }

    destroyCurrentReservationID(){
        this.reservationIdSubject.next(0);
    }

    setShowDownloadApp(isShow: boolean) {       
        this.showDownloadAppSubject.next(isShow);
    }
}