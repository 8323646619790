import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PropertyAmenityComponent } from './property-amenity.component';
import { AuthGuard } from '../core/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'property-amenity/:rid/:pid',
    component: PropertyAmenityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'property-amenity/:rid/:pid/:rpage',
    component: PropertyAmenityComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertyAmenityRoutingModule {}
