<ng-container *ngIf="passportDetail">
    <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <a routerLink="/home/{{reservationId}}" class="back-page"></a>
            <h2>Passport</h2>
          </div>
        </div>
    </div>
    
    <div class="title-bar-space d-block footer_space">
      
        <div class="container" >
            <div class="logo-circle mt-3"><img src={{passportDetail.profileImageUrl}} alt="" onError="this.src='/assets/images/profile.png'"></div>
            <h2 class="mt-5">{{passportDetail.guestName}}</h2>
            <ng-container *ngIf="passportDetail.reservationStatus != reservationStatus[reservationStatus.InStay]">
                <p class="mt-3">We're unable to generate a QR code at this time. Please try again later.</p>
            </ng-container>
            <ng-container *ngIf="passportDetail.reservationStatus == reservationStatus[reservationStatus.InStay]">
                <div class="passport-info-box mt-3" style="font-size:16px;">
                    <strong style="min-width:50px;">Status: </strong>
                    <label style=" padding-left: 5px;"> In-Stay</label>
                </div>
                <div class="passport-info-box  mt-3">
                    <strong> {{passportDetail.checkInTime | date:'EEE MMM d hh:mm a'}} - {{passportDetail.checkOutTime | date:'EEE MMM d hh:mm a'}}</strong>
                </div>
                <div class="passport-info-box  mt-3">
                    <strong style="min-width:20px;"> Host: </strong>
                    <label style="color:#1e1e1e; font-weight:500; padding-left: 5px;"> {{passportDetail.operatorName}}</label>
                </div>
                <div class="d-block w-100 text-center mt-5" *ngIf="qrCodeUrl">
                    <qrcode [qrdata]="qrCodeUrl" [errorCorrectionLevel]="'H'" [colorLight] = "'#eaeaea'"
                    ></qrcode>
                </div>
            </ng-container>
            
        </div>
    </div>
</ng-container>
