<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">  
    <div class="modal-dialog custom-alert modal-dialog-centered" role="document">  
        <div class="modal-content">  
  
            <div *ngIf="message?.type == 'confirm'" class="modal-body">  
                <div class="row">  
                    <div class="col-md-12">  
                        <h6>{{message.title}}</h6>
                        <p class="mt-3 sub-title">{{message.text}}</p>  
                    </div>  
                </div>  
                <div class="row">  
                    <div class="col-md-12">  
                        <div class="row confirm-button">  
                            <div class="col">
                                <a (click)="message.noFn()">  
                                    <button class="btn btn-secondary text-uppercase" style="padding-left: 5px;
                                    padding-right: 5px;">{{message.cancelBtnText}}</button>  
                                </a>  
                            </div>
                            <div class="col">
                                <a class="mr-2" (click)="message.yesFn()">  
                                    <button class="btn btn-primary text-uppercase" style="padding-left: 5px;
                                    padding-right: 5px">{{message.confirmBtnText}}</button>  
                                </a> 
                            </div>
                            
                             
                            
                        </div>  
                    </div>  
                </div>  
            </div>  
            <div *ngIf="message?.type == 'alert'" class="modal-body">
                <div class="row">  
                    <div class="col-md-12">  
                        <h6>{{message.title}}</h6>
                        <p class="mt-3 sub-title">{{message.text}}</p>  
                    </div>  
                </div> 
                <div class="row">  
                    <div class="col-md-12">  
                        <div class="row confirm-button">  

                            <div class="col">
                                <a class="mr-2" (click)="message.yesFn()">  
                                    <button class="btn btn-primary text-uppercase">{{message.confirmBtnText}}</button>  
                                </a> 
                            </div>
                            
                        </div>  
                    </div>  
                </div> 
            </div>
        </div>  
    </div>  
</div>  