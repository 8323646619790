<div class="footer" *ngIf="isAuth">
  
  <div class="download-app" *ngIf="isShowDownloadAppBanner">
    <h3>For the best experience download our mobile app</h3>
    <a href="https://dackinc.app.link/download" target="_blank" class="btn-download">Download</a>
    <a class="close_icon" href="javascript:void(0)" (click)="hideDownloadAppBanner()"><img src="../../../assets/images/close-icon.svg"> </a>
  </div>

  <div class="nav-bar">
    <div><a routerLink="/profile" class="profile-btn"></a></div>
    <div><a routerLink="/home" class="home-btn"></a></div>
    <div><a routerLink="/contact-host" class="contact-btn"></a></div>
  </div>
</div>

