import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { CheckInModuleStageEnum } from 'src/app/core';

@Component({
  selector: 'app-id-proof',
  templateUrl: './id-proof.component.html',
  styleUrls: ['./id-proof.component.scss']
})
export class IdProofComponent implements OnInit {

  @Input() startingForm: FormGroup;
  @Input() verificationStageSkip: boolean = false;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  idProofForm: FormGroup;
  isShowCamera: boolean = false;
  imageCaptured: boolean = false;
  adjustImage: boolean = false;
  private triggerCamera: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';

  isCameraAvaialable: boolean = true;
  cameraErrorText: string = "";
  width:number = 340;


  constructor(
    private fb: FormBuilder,
    private router:Router
  ) { 
    this.startingForm = this.idProofForm = this.fb.group({
      idProofBase64: new FormControl('',Validators.required),
      governmentIdContentType: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
    if (this.startingForm) {
      this.idProofForm = this.startingForm;
    } 
    this.subformInitialized.emit(this.idProofForm);
  }

  doChangeStep() {
    if(this.idProofForm.invalid){
      return;
    }
    this.changeChildStep.emit({"currentStep":"idProofInfoStep", "direction":"forward"});
  }

  useCamera(){
    this.isShowCamera = true;
  }
  public getSnapshot(): void {
    this.triggerCamera.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    
    this.imageCaptured = true;
    this.isShowCamera = false;
  }

  public retryImage(){
    this.imageCaptured = false;
    this.isShowCamera = false;

  }

  public adjustingImage(){
    this.imageCaptured = false;
    this.adjustImage = true;
  }


  public get invokeObservable(): Observable<any> {
    return this.triggerCamera.asObservable();
  }
  // public get nextWebcamObservable(): Observable<any> {
  //   return this.nextWebcam.asObservable();
  // }

  public handleInitError(error: WebcamInitError): void {

    this.isCameraAvaialable = false;

    if(error.mediaStreamError && error.mediaStreamError.name === "NotFoundError"){
      this.isCameraAvaialable = false;
      this.cameraErrorText = "We could not connect to your selected Camera Device!";
    }
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      this.cameraErrorText = "Please provide access of your Camera Device!";      

    }
  }
  

  imageCropped(event: ImageCroppedEvent) {  
    this.croppedImage = event.base64;
    var base64Image = this.croppedImage.split(';base64,');
    if(base64Image.length > 1){
      this.idProofForm.patchValue({
        governmentIdContentType: base64Image[0].replace('data:',''),
        idProofBase64: base64Image[1]
      }); 
    }
     
  }

  cancelCapturedImage() {
    this.croppedImage = "";
    this.isShowCamera = false;
    this.imageCaptured = false;
    this.adjustImage = false;
    this.sysImage = "";
    this.isCameraAvaialable = true;
    this.cameraErrorText = "";
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }
  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification});
  }
}
