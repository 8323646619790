import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService, PropertyService, ReservationStatusEnum, SpinnerService, UserService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

declare var $: any;

@Component({
  selector: 'app-property-access',
  templateUrl: './property-access.component.html',
  styleUrls: ['./property-access.component.scss']
})
export class PropertyAccessComponent implements OnInit {

  accessPoints: any;
  propertyUnits: any;
  reservationId: number = 0;
  activeTab: number = 0;
  showAccessPointImages: boolean = false;
  accessPointImages: any = [];
  tapOnLock: boolean = false;
  isTapToUnlockPress: boolean = false;
  isTapToUnlockResult: boolean = false;
  tapToUnlockText: string = "Tap to unlock";
  isReadMore: boolean = true;
  reservationStatusEnum = ReservationStatusEnum;
  accessInstructions: string = "";

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    items: 1,
    autoplay: true
  }

  constructor(
    private propertyService: PropertyService,
    private userService: UserService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private location: Location,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private mixpanelService: MixpanelService
  ) {
    this.reservationId = parseInt(route.snapshot.params['id']);
  }

  ngOnInit(): void {
    this.getUserUnits();
    this.mixpanelService.sendScreenVisitEvent("Access")
  }

  getUserUnits() {
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data.units && response.data.units.length > 0) {
              this.propertyUnits = response.data.units.find((x: any) => x.reservationID == this.reservationId);
              if (this.propertyUnits) {

                this.getPropertyAccessPoint(this.reservationId);
                if (this.propertyUnits.reservationStatus == ReservationStatusEnum.UnitReady) {
                  this.confirmDialogService.alertThis('Door access and key codes are not functional until check-in time', '', 'Ok', () => { });
                }
              }

            }
          }
        },
        error: (err) => {
          this.spinner.hide()
        },
        complete: () => this.spinner.hide()
      }

    );
  }

  getPropertyAccessPoint(reservationId: number) {
    this.propertyService.getPropertyAccessPoint(reservationId).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.accessPoints = response.data.accessPoints;
            this.setCustomScrollBar();

          }
        }
      }
    );
  }

  changeTab(tabIndex: number) {
    this.activeTab = tabIndex;
    this.isReadMore = true;
  }

  setCustomScrollBar() {
    setTimeout(() => {
      $("#tabs").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    });
  }

  goToBack() {
    this.location.back();
  }

  toggleAccessPointImages(isShow: boolean, accessPointId: number) {
    this.accessPointImages = [];
    this.showAccessPointImages = isShow;
    if (isShow) {
      var accessPoint = this.accessPoints.find((x: any) => x.accessPointId == accessPointId)
      if (accessPoint) {
        this.accessPointImages = accessPoint.images;
      }
    }
  }

  lockUnlockProperty(accessPointId: number, keyCode: string) {
    if (this.propertyUnits.reservationStatus == ReservationStatusEnum.UnitReady) {
      this.confirmDialogService.alertThis('Unit not ready. This feature will become available at check-in time', '', 'Ok', () => {

      });

    }
    else {
      var accessPoint = this.accessPoints.find((x: any) => x.accessPointId == accessPointId);
      var accessPointUnit = this.accessPoints.find((x: any) => x.unitId != null);
      if ((accessPoint.locks && accessPoint.keyCode == null) || (accessPoint.locks && accessPoint.keyCode && accessPoint.keyCode.toLowerCase() != 'access revoked')) {
        var accessPointLock = accessPoint.locks[0];
        if (accessPointLock) {

          var lockUnlockRequest = {
            vendorDeviceID: accessPointLock.vendorDeviceID,
            isBuildingLock: true,
            openDoor: true,
            unitID: accessPointUnit.unitId,
            lockId: accessPointLock.lockID,
            reservationId: this.reservationId
          }
          this.isTapToUnlockPress = true;
          this.propertyService.lockUnlockProperty(lockUnlockRequest).subscribe({
            next: (response) => {
              if (response && response.code == HttpStatusCode.Ok) {
                this.tapOnLock = !this.tapOnLock;
                this.isTapToUnlockResult = true;
                this.tapToUnlockText = "Unlocking";

                setTimeout(() => {
                  this.tapToUnlockText = "Unlocked";
                  setTimeout(() => {
                    this.isTapToUnlockResult = false;
                    this.tapToUnlockText = "Tap to unlock";
                    this.isTapToUnlockPress = false;
                  }, 1000);
                }, 1000);


              }
              else {
                this.toastr.error(response.message);
                this.isTapToUnlockPress = false;
              }
            },
            error: (e) => {
              this.toastr.error(e.message);
              this.isTapToUnlockPress = false;
            }
          })
        }
      }

    }

  }


  showAccessInstruction() {
    this.isReadMore = false;
  }

  isShowSlideToUnlockButton(accessPoint: any) {
    if (accessPoint && accessPoint.locks.length > 0) {
      let slideToUnlockEnabled = accessPoint.locks.find((x: any) => x.isSlideToUnlockEnabled) != null;
      let accessRevokedAndButtonShouldDisplay = accessPoint.locks[0].lockID != null && accessPoint.keyCode == "Access Revoked"
      if (accessPoint.keyCode && accessPoint.keyCode.toLowerCase() == "access revoked") {
        this.tapToUnlockText = "Access revoked by host";
      }
      return slideToUnlockEnabled || accessRevokedAndButtonShouldDisplay;
    }
    return false;
  }
}
