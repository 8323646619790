<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/property-access/{{reservationId}}" class="back-page"></a>
        <h2>Lock Assistance</h2>
      </div>
    </div>
  </div>
  <div class="title-bar-space d-block" *ngIf="accessPoint">
    <ng-container *ngIf="canUnlock">
      <div class="container" *ngIf="!isTapToUnlockPress">
        <div *ngIf="lockDetail && isAccessPointHaveLock" class="tap-unlock-box">

            <h2>Tap to unlock isn't working, let's try your keycode instead.</h2>
            <h3 class="keycodetext mt-4 mb-4">{{accessPoint.keyCode}}</h3>
            <h4 class="lock-valuetext mb-4">Lock Instructions</h4>
            <p>{{lockDetail.additionalInstructions}}</p><br>
            <div *ngIf="lockDetail.videoExplanationUrl">
                <a [href]="lockDetail.videoExplanationUrl" class="watchvideo-link" target="_blank">Watch a Video</a>
            </div>
            
        </div>
        
        <div *ngIf="!isAccessPointHaveLock" class="lock-manage-bx">
          
          <div class="mt-5">
            <div class="text-center"><div class="lockIcon mb-5"></div></div>
            <h3>DACK does not manage this keycode or lock.</h3>
            <h3 class="mt-4"><strong>Please contact the host for assistance if the keycode is incorrect.</strong></h3>
          </div>
            <a routerLink="/contact-host/{{reservationId}}" class="btn btn-primary mt-4">Contact Host</a>
        </div>
    </div>
    <div *ngIf="!isTapToUnlockPress">
      <div *ngIf="lockDetail && isAccessPointHaveLock" class="buttonfixed-position">
        <a routerLink="/property-access/{{reservationId}}" class="btn btn-primary w-100">That Worked!</a>
        <button class="btn btn-primary mt-2 w-100" (click)="SetOrUpdatedKeyCodeOnLock()" style="color: #EA6969">This still isn't working.</button>
      </div>
    </div>
    
    <div *ngIf="isTapToUnlockPress" class="lock-manage-bx">
      <div class="container">
        <h3 style="font-size: 28px">Ok, we're updating your code, give us a few moments.</h3>
        <div class="mt-4 text-center"><span class="processing_loader"></span></div>
        <!-- <a href="javascript:void(0)" [ngClass]="isTapToUnlockResult ? 'lock-unlock-btn animation-allow changedstatus mt-5' : 'lock-unlock-btn animation-allow mt-5'" ><span></span></a> -->
      </div>
    </div>
    </ng-container>
    <ng-container *ngIf="retryCount >= maxRetryAllow && !canUnlock">
      <div class="container">        
        <div class="lock-manage-bx">
          <div class="mt-5">
            <div class="text-center"><div class="lockIcon mb-5"></div></div>
            <h3>Dack is unable to reach the lock at this time. The lock could be offline or out of battery.</h3>
            <h3 class="mt-4"><strong>Please contact your host for assistance.</strong></h3>
          </div>
          
            <a routerLink="/contact-host/{{reservationId}}" class="btn btn-primary mt-4">Contact Host</a>
        </div>
      </div>
      
    </ng-container>
    
  </div>       
    