import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckInModuleStageEnum, SpinnerService, UserService } from 'src/app/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() startingForm: FormGroup;
  @Input() verificationStageSkip: boolean = false;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  
  public addressInfoForm: FormGroup;
  submitted: boolean = false;
  countryCodes: any;
  selectedCountryCode: string ='';
  selectedCountryFlag: string ='';
  constructor(
    private fb:FormBuilder,
    private userService: UserService,
    private spinner: SpinnerService
  ) { 
    this.startingForm = this.addressInfoForm = this.fb.group({
      addressLine1: new FormControl('', [Validators.required]),
      addressLine2: new FormControl(''),
      zipCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      //stateAbbreviation: new FormControl('', [Validators.required]),
      country: new FormControl(''),
      countryName: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.getCountryCodes();
    this.subformInitialized.emit(this.addressInfoForm);
  }

  get f(){
    return this.addressInfoForm.controls;
  }

  getCountryCodes(){
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;
            this.selectedCountryCode = this.countryCodes[0].abbreviation;
            this.selectedCountryFlag = this.countryCodes[0].countryCodeImage;
            this.addressInfoForm.patchValue({
              countryName: this.countryCodes[0].countryName,
              countryCode: this.countryCodes[0].countryCode,
            });
          }
        },
        error:(e) =>{
          this.spinner.hide()
        },
        complete:()=>this.spinner.hide()
      }
    );
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.selectedCountryCode = selectedCountry.abbreviation;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;
        this.addressInfoForm.patchValue({
          countryName: selectedCountry.countryName,
          countryCode: selectedCountry.countryCode,
        });
      }
    }
  }
  doChangeStep() {
    this.submitted = true;
    if(this.addressInfoForm.invalid){
      return;
    }
    this.changeChildStep.emit({"currentStep":"addressInfoStep", "direction":"forward"});
  }
  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }

  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification});
  }
}
