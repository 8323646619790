import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckInModuleStageEnum, ReservationService } from 'src/app/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() verificationStageSkip: boolean = false;
  @Input() startingForm: FormGroup;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  title:string = "";
  body:string = "";
  termsAcceptedForm: FormGroup;
  constructor(
    private reservationService: ReservationService,
    private fb:FormBuilder,
    private router:Router
  ) { 
    this.startingForm = this.termsAcceptedForm = this.fb.group({
      termsAccepted: new FormControl(false)
    })
  }

  ngOnInit(): void {
    this.subformInitialized.emit(this.termsAcceptedForm);
    this.getVerificationTermsAndCondition();
  }

  getVerificationTermsAndCondition(){
    this.reservationService.getUserReservationTermsConditions(this.reservationId).subscribe({
      next: (response) => {
        if(response && response.code == HttpStatusCode.Ok && response.data){
          this.title = response.data.title;
          this.body = response.data.body;
        }
      }
    })
  }

  doChangeStep() {
    this.termsAcceptedForm.patchValue({
      termsAccepted:true
    });
    this.changeChildStep.emit({"currentStep":"termsConditionInfoStep", "direction":"forward"});
  }

  submit(){
    this.termsAcceptedForm.patchValue({
      termsAccepted:true
    });

    this.router.navigateByUrl('/selfie');
  }
  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }
  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification});
  }
}
