<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">      
        <a href="javascript:void(0)" class="help_icon" (click)="showCommonTermsCondition()"></a>
        <h2>Guest Verification</h2>
        <a class="skip" (click)="skipModule()" *ngIf="verificationStageSkip">SKIP</a>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">
        <form [formGroup]="personalDetailForm" class="form-flex"  autocomplete="off">
            
            <ng-container formArrayName="phoneList">
                <ng-container *ngFor="let adminForm of phoneList.controls; let index = index">
                    <div class="form-group d-block mt-4" [formGroupName]="index">
                        <label class="form-label">Phone</label>
                        <div class="with-country-code">
                            <span class="custom-select">                                
                                <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                                <input type="text" value="{{selectedCountryCode}}" class="selectform_control" />
                                <select class="form-control" formControlName="countryID" (change)="onCountryChange($event)">
                                    <option *ngFor="let country of countryCodes" [ngValue]="country.countryID" >{{country.countryName}}</option>
                                </select>
                            </span>
                            <input type="text" class="form-control" formControlName="phone"  [maxLength]="13" numbersOnly [ngClass]="{ 'is-invalid': submitted && phoneList.controls[index].get('phone')?.errors?.required }" autocomplete="off" />
                        </div>
                        
                        <div *ngIf="submitted && phoneList.controls[index].get('phone')?.errors?.required" class="invalid-feedback">
                            <div *ngIf="phoneList.controls[index].get('phone')?.errors?.required">Phone is required</div>
                        </div>                    
                    </div>
                </ng-container>
            </ng-container>
            <div class="form-group d-block mt-4">
                <label class="form-label">Title</label>
                <input type="text" class="form-control" formControlName="title"  autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"/>
                <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                    <div *ngIf="f.title.errors['required']">Title is required</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">First Name</label>
                <input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors['required']">First Name is required</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Email</label>
                <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors['required']">Email is required</div>
                    <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
                </div>                
            </div>          
            
            
            <div class="form-group mt-5">
                <button type="button" class="btn btn-primary w-100" (click)="doChangeStep()">Next</button>
            </div>
        </form>
          
      </div>
    
  </div>