<ng-container [ngSwitch]="currentStep">
  <app-damage-waiver-landing *ngSwitchCase="'damageWaiverLanding'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      [securityTransactionStageSkip]="securityTransactionStageSkip" 
      (changeChildStep)="changeStep($event)"
      (skipModuleStep)="skipModule($event)"
      (goBackToPreviousStep)="goBackToPreviousStep()"
  ></app-damage-waiver-landing>
  <app-damage-waiver-payment *ngSwitchCase="'damageWaiverPayment'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"
      [securityTransactionStageSkip]="securityTransactionStageSkip"       
      (changeChildStep)="changeStep($event)"
      (skipModuleStep)="skipModule($event)"
  ></app-damage-waiver-payment>
  <app-deposit-waiver-payment *ngSwitchCase="'depositWaiverPayment'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      [securityTransactionStageSkip]="securityTransactionStageSkip"     
      (changeChildStep)="changeStep($event)"
      (skipModuleStep)="skipModule($event)"
  ></app-deposit-waiver-payment>
</ng-container>