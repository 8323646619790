<ng-container *ngIf="propertyAmenities">
    <div class="title-bar">
      <div class="container">
        <div class="title-bar-box">
          <a routerLink="/home/{{reservationId}}" class="back-page"></a>
          <h2>Amenities</h2>
        </div>
      </div>
    </div>
    <div class="title-bar-space d-block">
      <div class="container amenities" 
        infinite-scroll
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        >
      
        <div class="linkBox" *ngFor="let amenity of propertyAmenities">
          
          <a routerLink="/property-amenity/{{reservationId}}/{{amenity.propertyAmenityID}}" class="anchor_cover"></a>
          <div class="linkBox_img"><img [src]="amenity.banner" alt=""/></div>
          <div class="linkBox_content">
            <strong>{{amenity.name}}</strong>
            
          </div>
        </div>
          
      </div>
    </div> 
  </ng-container> 
    <ng-container *ngIf="!isPropertyHaveAmenities">
      <div class="v_flexbxx notfound_container">
        <div class="container">
          <div class="notfound_box">
            <div class="notfound_icon text-center">
              <img src="../../assets/images/upsells-empty.png" alt=""/>
            </div>
      
            <h3 class="mb-3">No Amenity(s) Found</h3>
            <p class="mb-0">Your operator doesn't offer any amenity(s) at this time </p>
          </div>
        </div>
      </div>
    </ng-container>     
      