import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { SupportComponent } from './support.component';
import { SupportRoutingModule } from './support-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SupportRoutingModule
  ],
  declarations: [
    SupportComponent
  ],
  providers: [    
  ]
})
export class SupportModule {}
