import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyLockComponent } from './property-lock.component';
import { SharedModule } from '../shared';
import { PropertyLockRoutingModule } from './property-lock-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyLockRoutingModule
  ],
  declarations: [
    PropertyLockComponent
  ],
  providers: [    
  ]
})
export class PropertyLockModule {}
