import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SpinnerService, UpsellService } from '../core';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {

  purchases: any;
  isPurchaseRequestsAvailable: boolean = true;
  constructor(
    private upsellService: UpsellService,
    private spinner : SpinnerService
  ) { }

  ngOnInit(): void {
    this.getGuestRequestedUpsells();
  }

  getGuestRequestedUpsells(){
    this.spinner.show();
    this.upsellService.getGuestRequestedUpsells()
        .subscribe({
          next: (response) => {
            if(response.code == HttpStatusCode.Ok && response.data && response.data.length > 0){
              this.purchases = response.data;
              this.isPurchaseRequestsAvailable = true;
            }
            else{
              this.isPurchaseRequestsAvailable = false;
            }
          },
          error: (e) => {
            this.spinner.hide();
            this.isPurchaseRequestsAvailable = false;

          },
          complete: () => this.spinner.hide()
        });
  }

  getPurchasedStatus(status:string):any{
    if(status.toLowerCase()=="captured"){
      return "Approved";
    }
    else if (status.toLowerCase()=="onhold"){
      return "On Hold";
    }
    else if (status.toLowerCase()=="fullcapture"){
      return "Charged In Full";
    }
    else{
      return status;
    }
  }
}
