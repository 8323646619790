import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpStep1Component } from './sign-up-step1.component';
import { SignUpStep2Component } from './sign-up-step2.component';
import { SignUpStep3Component } from './sign-up-step3.component';
import { NoAuthGuard } from '../core/services/no-auth-guard.service';

const routes: Routes = [
  {
    path: 'sign-up',
    component: SignUpStep1Component,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'verify-phone',
    component: SignUpStep2Component,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'sign-up-detail',
    component: SignUpStep3Component,
    canActivate: [NoAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignUpRoutingModule {}
