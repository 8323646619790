<app-confirm-dialog></app-confirm-dialog>
<div class="page-box" *ngIf="propertyUnits && !showAccessPointImages">
  <div class="banner-with-header">
    <div class="banner-top">
      <a routerLink="/home/{{reservationId}}" class="backbtn_banner"></a>
      <img [src]="propertyUnits.unitImage" alt="header"/>
    </div>
    <h1 class="page-name">Access Details</h1>
  </div>
  <div class="container" *ngIf="accessPoints">
    <div class="tabs" id="tabs">
      <ul role="tablist" class="nav-tabs nav">
        <li class="nav-item" *ngFor="let accessPoit of accessPoints; let i = index">
          <a [className]="activeTab == i? 'nav-link active' : 'nav-link'" (click)="changeTab(i)" data-bs-toggle="tab" data-target="#{{i}}">{{accessPoit.name}}</a>
        </li>
        
      </ul>
    </div>
    <!-- Tab panes -->

    <div class="tab-content"  *ngIf="accessPoints">
      <div id="{{i}}" [className]="activeTab == i? 'tab-pane active' : 'tab-pane'" *ngFor="let accessPoint of accessPoints; let i = index">
        <div class="keycode" *ngIf="accessPoint.keyCode">
          <strong>Keycode</strong> 
          <div [ngClass]="accessPoint.keyCode && accessPoint.keyCode.toLowerCase()=='access revoked'? 'access-revoked':'access'">{{accessPoint.keyCode}}</div>
        </div>
        <p class="sub-title max-threeline" *ngIf="isReadMore && accessPoint.accessInstructions">{{accessPoint.accessInstructions.length > 155 ? accessPoint.accessInstructions.substr(0,155) + "..." : accessPoint.accessInstructions.substr(0,155)}} </p>
        <p class="sub-title" *ngIf="!isReadMore">{{accessPoint.accessInstructions}} </p>
        <div class="mb-4 read-more" *ngIf="accessPoint.accessInstructions && accessPoint.accessInstructions.length>155 && isReadMore">
          <a class="action-link" href="javascript:void(0)" (click)="showAccessInstruction()"><strong>Read More</strong></a>
        </div>
        <div>
          <ng-container *ngIf="propertyUnits.reservationStatus == reservationStatusEnum.UnitReady || (accessPoint.keyCode && accessPoint.keyCode.toLowerCase()=='access revoked')">
            <a class="action-link help-links" routerLink="/contact-host/{{reservationId}}"><i class="far fa-question-circle" style="color:#1e1e1e"></i> &nbsp;<strong>Need Help</strong></a>
          </ng-container>
          <ng-container *ngIf="propertyUnits.reservationStatus != reservationStatusEnum.UnitReady && (accessPoint.keyCode == null || (accessPoint.keyCode!=null && accessPoint.keyCode.toLowerCase()!='access revoked'))">
            <a class="action-link help-links" routerLink="/property-lock/{{reservationId}}/{{accessPoint.accessPointId}}"><i class="far fa-question-circle" style="color:#1e1e1e"></i> &nbsp;<strong>Need Help</strong></a>
          </ng-container>
          
        </div>
        <div *ngIf="accessPoint.images && accessPoint.images.length > 0">
          <a class="action-link help-links" href="javascript:void(0)" (click)="toggleAccessPointImages(true, accessPoint.accessPointId)"><span class="icon-linkss"><i class="fa-regular fa-eye"></i></span><strong>See Images</strong></a>
        </div>
        <div class="d-block" *ngIf="isShowSlideToUnlockButton(accessPoint)">
          <a href="javascript:void(0)" [ngClass]="!isTapToUnlockPress ? 'lock-unlock-btn' : (isTapToUnlockResult ? 'lock-unlock-btn animation-allow changedstatus' : 'lock-unlock-btn animation-allow')" (click)="lockUnlockProperty(accessPoint.accessPointId, accessPoint.keyCode)" ><span>{{tapToUnlockText}}</span></a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="show-images-box" *ngIf="showAccessPointImages">
  <a href="javascript:void(0)" class="close-images-showcase" (click)="toggleAccessPointImages(false, 0)">Close</a>
  <owl-carousel-o [options]="customOptions" class="sliderowl">   
    <ng-container *ngFor="let slide of accessPointImages">
      <ng-template carouselSlide>
        <img [src]="slide.name" >
      </ng-template>
    </ng-container>             
    </owl-carousel-o>
</div>



  