import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ReservationService {

    constructor(private apiService: ApiService){

    }

    checkReservationByPhone(phoneNumber: string, countryId: Number): Observable<any> {
        return this.apiService.get('/v1/reservation/CheckReservationByMobileNumber?MobileNumber='+ phoneNumber +'&CountryId='+ countryId)
          .pipe(map(
          reseponse => {
            if(reseponse && reseponse.data){
                return reseponse.data;
            }
            return reseponse;
          }
        ));
    }

    getUserReservations(reservationType: Number, pageNumber: Number): Observable<any> {
      return this.apiService.get('/v5/reservation/GetUserReservations?PageNumber='+ pageNumber + '&ReservationsType=' + reservationType)
        .pipe(map(
          reseponse => {          
          return reseponse;
        }
      ));
   }

   requestForSupport(formValue:any){
    return this.apiService.post('/v1/Support/Reservation', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
   }

   requestForSupportV5(formValue:any){
    return this.apiService.post('/v1/Support/General', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
   }


   getUserReservationTermsConditions(reservationId: Number): Observable<any> {
    return this.apiService.get('/v2/reservation/TermsAndConditions?ReservationId='+ reservationId)
      .pipe(map(
        reseponse => {          
        return reseponse;
      }
    ));
  }

  verifyReservationUserDetail(formValue:any){
    return this.apiService.post('/v2/Verification/VerificationSubmit', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
  }

  checkReservationByNumber(reservationNumber: string): Observable<any> {
    return this.apiService.get('/v1/reservation/ReservationLookup?ReservationNumber='+ reservationNumber)
      .pipe(map(
      reseponse => {        
        return reseponse;
      }
    ));
  }
  
  pairReservation(formValue:any){
    return this.apiService.put('/v1/Reservation/PairReservation', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
  }

  getGuestDetail(reservationId:number){
    return this.apiService.get('/v1/Reservation/GetAllAdditionalGuestInformation?reservationID='+ reservationId)
      .pipe(map(
        response => {          
          return response;
        }
    ));
  }

  addAdditionalGuest(formValue:any){
    return this.apiService.post('/v1/Reservation/AddAdditionalGuestToReservation', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
  }

  updateAdditionalGuest(formValue:any){
    return this.apiService.post('/v1/Reservation/EditAdditionalGuestOnReservation', formValue)
      .pipe(map(
        response => {          
          return response;
        }
    ));
  }

  removeAdditionalGuest(formValue:any){
    return this.apiService.post('/v1/reservation/deleteAdditionalGuestInformation', formValue)
        .pipe(map(
          response => {
            return response;
          }
        ))

  }

  getAmenities(reservationId:number, unitId:number, propertyId:number, pageNumber:number){
    return this.apiService.get(`/v2/property/amenities?PropertyID=${propertyId}&ReservationID=${reservationId}&UnitID=${unitId}&PageNumber=${pageNumber}`)
    .pipe(map(
      response => {
        return response;
      }
    ))
  }

  getAmenity(propertyAmenityID:number){
    return this.apiService.get(`/v2/property/amenityItem?PropertyAmenityId=${propertyAmenityID}`)
    .pipe(map(
      response => {
        return response;
      }
    ))
  }

  getReservationPassport(reservationId:number){
    return this.apiService.get(`/v1/reservation/passport?reservationId=${reservationId}`)
    .pipe(map(
      response => {
        return response;
      }
    ))
  }
}