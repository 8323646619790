import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckInModuleStageEnum } from 'src/app/core';

@Component({
  selector: 'app-verification-progress',
  templateUrl: './verification-progress.component.html',
  styleUrls: ['./verification-progress.component.scss']
})
export class VerificationProgressComponent implements OnInit {

  @Input() startingForm: FormGroup;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkVerificationStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  progressForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router:Router,
    private route: ActivatedRoute
  ) {
    var reservationId = Number(this.route.snapshot.params['id']);
    this.startingForm = this.progressForm = this.fb.group({
      id: new FormControl(reservationId,Validators.required)
    })
   }  

   ngOnInit(): void {
    if (this.startingForm) {
      this.progressForm = this.startingForm;
    } 
    this.subformInitialized.emit(this.progressForm);

    // setTimeout(()=>{
    //   this.submitForm.emit();
    // });
    setTimeout(()=>{
      this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification, "action":"complete"});
    },3000);
  }

  checkGuestVerificationStatus(){
    this.checkVerificationStatus.emit();
  }
}
