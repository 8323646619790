<div class="page-box" *ngIf="propertyAmenity">
    <div class="banner-with-header">
        <div class="banner-top">
            <a (click)="goToBack()" class="backbtn_banner"></a>
            <owl-carousel-o [options]="customOptions" class="sliderowl">   
              <ng-container *ngFor="let slide of propertyAmenity.propertyAmenityImages">
                  <ng-template carouselSlide>
                  <img [src]="slide.banner" >
                  </ng-template>
              </ng-container>             
            </owl-carousel-o>            
        </div>
        <h1 class="page-name">
          {{propertyAmenity.amenityName}} 
          <span>
            {{propertyAmenity.amenityLocatedOn}} 
            <span>
              
            </span>
          </span>
        </h1>
    </div>
    <div class="container">
        <div class="sub-title" [innerHTML]="propertyAmenity.description"></div>
    </div>

    <div class="listData" *ngIf="propertyAmenity.operationHours">
        <h4>Hours of Operation</h4>
        <ul>
            <li *ngFor="let item of propertyAmenity.operationHours">
                <strong>{{item.day}}</strong>
                <label>{{item.time}}</label>
            </li>
            
        </ul>
    </div>
    <div class="listData desc-min-height" *ngIf="propertyAmenity.yourNetwork">
        <h4>Your Network</h4>
        <ul>
            <li><strong>Wi-Fi Name</strong><label>{{propertyAmenity.yourNetwork.wifiName}}</label></li>
            <li><strong>Password</strong><label>{{propertyAmenity.yourNetwork.password}}</label></li>
        </ul>
    </div>
    <div class="container" *ngIf="propertyAmenity.yourNetwork">
        <a  (click)="copyCredential()" class="btn btn-primary mt-5 w-100">Copy Credentials</a>

    </div>

</div>