import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { PropertyEnhancementsComponent } from './property-enhancements.component';
import { PropertyEnhancementsRoutingModule } from './property-enhancements-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyEnhancementsRoutingModule
  ],
  declarations: [
    PropertyEnhancementsComponent
  ],
  providers: [    
  ]
})
export class PropertyEnhancementsModule {}
