import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReservationService, ReservationStatusType, SpinnerService, UserService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-switch-reservation',
  templateUrl: './switch-reservation.component.html',
  styleUrls: ['./switch-reservation.component.scss']
})
export class SwitchReservationComponent implements OnInit {

  userReservations: any = [];
  currentUser: any;

  constructor(
    private reservationService: ReservationService,
    private userService: UserService,
    private spinner: SpinnerService,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit(): void {
    this.getUserReservations();
    this.mixpanelService.sendScreenVisitEvent("ReservationList")
  }

  getUserReservations() {
    this.spinner.show();
    this.reservationService.getUserReservations(ReservationStatusType.Upcoming, 1).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data && response.data.length > 0) {
              this.userReservations = response.data;
            }
          }
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => { this.spinner.hide(); }
      }
    );
  }
}
