import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { SpinnerService } from 'src/app/core';

@Component({
  selector: 'app-result-reservation',
  templateUrl: './result-reservation.component.html',
  styleUrls: ['./result-reservation.component.scss']
})
export class ResultReservationComponent implements OnInit {

  @Input() reservationId: number = 0;
  
  constructor(
    private router: Router,
    private spinner:SpinnerService
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(()=>{
    this.spinner.hide();
      this.router.navigateByUrl('/home');
    },6000)
  }

}
