import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { PurchaseHistoryComponent } from './purchase-history.component';
import { PurchaseHistoryRoutingModule } from './purchase-history-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PurchaseHistoryRoutingModule
  ],
  declarations: [
    PurchaseHistoryComponent
  ],
  providers: [    
  ]
})
export class PurchaseHistoryModule {}
