import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogService, PropertyService, SpinnerService, UserService } from '../core';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
declare var $: any;

@Component({
  selector: 'app-property-direction',
  templateUrl: './property-direction.component.html',
  styleUrls: ['./property-direction.component.scss']
})
export class PropertyDirectionComponent implements OnInit {

  propertyUnit: any;
  unitId: number = 0;
  reservationId: number = 0;
  showMap:boolean = false;
  zoom = 15;
  center!: google.maps.LatLngLiteral;
  
  markers:any=[];
  isShowRidePanel:boolean = true;
  currentLatLng:any;
  //private agreedForShareLocation: boolean = false;
  private currentLocation: string = '';
  isShowLinkPopup: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private userService: UserService,
    private spinner: SpinnerService,
    private location:Location,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private toastr: ToastrService,
    private clipboardApi: ClipboardService
    ) 
    { 
      this.reservationId = this.route.snapshot.params['id'];
      this.unitId = this.route.snapshot.params['uid'];
    }

  ngOnInit(): void {
    $('head').append(`<script src="https://maps.googleapis.com/maps/api/js?key=`+ environment.googleMapKey +`"></script>`);
    
    this.getUserUnits();
    setTimeout(()=>{
      this.getLocation();
    },500)
    
  }

  
  getLocation():any {
    
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
          if (position) {
            let geocoder = new google.maps.Geocoder;  

            this.currentLatLng = {  
                lat: position.coords.latitude,  
                lng: position.coords.longitude  
            };  

            geocoder.geocode({  
                'location': this.currentLatLng  
            }, (results) => {  
                if (results) {  
                  this.currentLocation = results[0].formatted_address;     

                }
            });  
          }
        },
        (error: any) => {
          this.confirmDialogService.alertThis('DACK needs your location so you can accurately use this page. Please turn on permissions at your browser settings','Enable Location','Ok',()=>{});
        }
        );
      } else {
        this.confirmDialogService.alertThis('Geolocation is not supported by this browser','Location','Ok',()=>{});
      }
    return this.currentLocation;
  }

  getUserUnits(){
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
          next:(response)=>{
            if(response && response.code == HttpStatusCode.Ok && response.data){
                if(response.data.units && response.data.units.length > 0){

                  this.propertyUnit = response.data.units.find((x:any) => x.reservationID == this.reservationId && x.unitID == this.unitId);
                  
                  
                  setTimeout(() => {
                    this.setCenterOfGeoLocation();
                  }, 500);
                }            
            }
          },
          error:(err)=>{
            this.spinner.hide()
          },
          complete:()=>this.spinner.hide()
      }
    );
  }

  addMarker(){
    const image = {
      url: this.propertyUnit.operatorLogo+'#custom_marker',
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(65, 65),
      scaledSize: new google.maps.Size(65, 65),
      origin: new google.maps.Point(0, 0)
    };
    this.markers.push({
      position: {
        lat: this.center.lat ,
        lng: this.center.lng ,
      },
      // label: {
      //   color: 'black',
      //   text: this.propertyUnit.operatorName
      // },
      title: this.propertyUnit.operatorName,      
      options: { 
        animation: google.maps.Animation.DROP ,
        icon: image,        
      },
    });
  }

  setCenterOfGeoLocation(){
    this.showMap = true;
    this.center = {
      lat: this.propertyUnit.latitude,
      lng: this.propertyUnit.longitude,
    }
 
    setTimeout(()=>{
      this.addMarker()
    })
    
  }

  goToBack() {
    this.location.back();
  }

  hideRidePanel(){
    this.isShowRidePanel = false;
  }

  redirectToLyft(){
    var currentLocation = this.getLocation();
    if(currentLocation && currentLocation != ""){
      var redirectUrl = `https://ride.lyft.com/ridetype?destination=${this.center.lat},${this.center.lng}&origin=${this.currentLatLng.lat},${this.currentLatLng.lng}&offerProductId=standard`;
      window.open(redirectUrl, "_blank");
    }
    else{
      this.confirmDialogService.alertThis('Please turn on permissions at your browser settings','If you reject permission, you can not use this service','Ok',()=>{});
    }
  }

  redirectToUber(){
    var currentLocation = this.getLocation();
    if(currentLocation && currentLocation != ""){

      var redirectUrl = `https://m.uber.com/ul/?action=setPickup&pickup[latitude]=${this.currentLatLng.lat}&pickup[longitude]=${this.currentLatLng.lng}&pickup[nickname]=&pickup[formatted_address]=${currentLocation}&dropoff[latitude]=${this.center.lat}&dropoff[longitude]=${this.center.lng}&dropoff[nickname]=&dropoff[formatted_address]=${this.propertyUnit.propertyFullAddress}`;

      window.open(redirectUrl, "_blank");
    }
    else{
      this.confirmDialogService.alertThis('Please turn on permissions at your browser settings','If you reject permission, you can not use this service','Ok',()=>{});
    }
    
  }

  showLinkPopup(){
    this.isShowLinkPopup = true;
  }

  addressOnGoogleMap(){
    var currentLocation = this.getLocation();
    if(currentLocation && currentLocation != ""){
      var redirectUrl = `https://www.google.com/maps/dir/${currentLocation}/${this.propertyUnit.propertyFullAddress}`;
      window.open(redirectUrl, "_blank");
    }
    else{
      this.confirmDialogService.alertThis('Please turn on permissions at your browser settings','If you reject permission, you can not use this service','Ok',()=>{});
    }
  }

  copyAddressToClipboard(){
    if(this.propertyUnit.propertyFullAddress){
      this.clipboardApi.copyFromContent(this.propertyUnit.propertyFullAddress);
      this.toastr.info("Your address has been copied!");
    }
    
  }
}
