<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a (click)="goToBack()" class="back-page"></a>
        <h2>Privacy Policy</h2>
      </div>
    </div>
  </div>
  <div class="title-bar-space d-block footer_space" *ngIf="privacyPolicy">
    <div class="container">
      <p class="sub-title" [innerHTML]="privacyPolicy.pageContent"></p>
    </div>
    <div class="error-page" *ngIf="!privacyPolicy">
      <div class="container">
        <h1 class="heading-title">No record found.Try again! </h1>
      </div>
    </div>
    
  </div>       
    

