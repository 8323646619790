<div class="remove-footer-space">
<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/login" class="back-page"></a>
        <h2>Forgot Password</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">
          <h1 class="heading-title">Enter your email and we will send you a link to recover your account.</h1>
          <form class="mt-5 form-flex"  [formGroup]="forgotPasswordForm"  (ngSubmit)="submitForm()" autocomplete="off">
            <div class="form-group d-block mt-4">
              <label class="form-label">Email</label>
              <input type="text" placeholder="Email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
              </div>
            </div>
            <button class="btn btn-primary w-100 mt-5">Submit</button>
          </form>
      </div>
    
  </div>

</div>