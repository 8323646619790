import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { PhoneVerifyApiService } from './phoneVerifyApi.service';
import { JwtService } from './jwt.service';
import { User, CountryCode, UserProfileImageAndName} from '../models';
import { map ,  distinctUntilChanged } from 'rxjs/operators';


@Injectable()
export class UserService {
  
  public currentUserSubject = new BehaviorSubject<User>(localStorage.getItem('userDetail')? JSON.parse(localStorage.getItem('userDetail')!):{} as User)
  public currentUser  = this.currentUserSubject.asObservable().pipe();

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(localStorage.getItem('userDetail') ? true : false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable().pipe();

  constructor (
    private apiService: ApiService,
    private phoneVerifyApiService: PhoneVerifyApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) {
    let storedProp = localStorage.getItem('userDetail');
    if (storedProp)
      this.setUser(JSON.parse(storedProp), false);    
  }

  setUser(user: User, storeProp: boolean = false) {
    if (storeProp)
        localStorage.setItem('userDetail', JSON.stringify(user));
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
   // If JWT detected, attempt to get & store user's info
   let storedProp = localStorage.getItem('userDetail');
    if(storedProp){

    }
    else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    this.setUser(user, true);
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.token, true);
    
  }

  purgeAuth() {
    localStorage.removeItem('userDetail');    
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    
  }

  attemptAuth(credentials:any): Observable<User> {
    return this.apiService.post('/v1/user/login', credentials)
      .pipe(map(
      response => {
        if(response.code == HttpStatusCode.Ok){
          this.setAuth(response.data);        
        }
        return response;
      }
    ));
  }

  getCurrentUser():any{
    return this.currentUserSubject.asObservable();
    
  }

  // Update the user on the server (email, pass, etc)
  update(user:User): Observable<User> {
    return this.apiService
    .put('/user', { user })
    .pipe(map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }

  getCountryCode():Observable<CountryCode>{
    return this.apiService.get('/v1/User/GetCountryCode')
      .pipe(map(
      data => {
        if(data && data.data){
            return data.data;
        }
        return null;
      }
    ));
  }

  signUp(signUpFormValue:any): Observable<any> {
    return this.apiService.post('/v1/user/signup', signUpFormValue)
      .pipe(map(
        response => {
          if(response.code == HttpStatusCode.Ok){
            if(response.data){
              this.setAuth(response.data);        
            }
          }
          return response;
        }
    ));
  }

  getUserProfileImageAndName():Observable<any>{
    return this.apiService.get('/v5/User/GetProfileNameAndImage')
      .pipe(map(
      data => {        
        return data;
      }
    ));
  }

  getUserConcierge(reservationID: number):Observable<any>{
    return this.apiService.get('/v1/User/Concierge?ReservationID='+ reservationID)
      .pipe(map(
      data => {        
        return data;
      }
    ));
  }

  logOut():Observable<any>{
    return this.apiService.delete('/v5/User/logout')
      .pipe(map(
      data => {      
        if(data && data.code == HttpStatusCode.Ok)  {
          this.jwtService.destroyToken();
        }
        return data;
      }
    ));
  }

  changePassword(credentials:any): Observable<any> {
    return this.apiService.post('/v5/user/changepassword', credentials)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  getUserProfile():Observable<any>{
    return this.apiService.get('/v5/User/GetProfile')
      .pipe(map(
      data => {        
        return data;
      }
    ));
  }

  updateUserProfile(profile: any){
    return this.apiService.put('/v5/user/updateprofile', profile)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  verifyPhone(payload:any): Observable<any> {
    return this.phoneVerifyApiService.post('/v2/PhoneVerification/VerifyPhoneNumber', payload)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  verifyPhoneCode(payload:any): Observable<any> {
    return this.phoneVerifyApiService.post('/PhoneVerification/ConfirmVerificationCode', payload)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  forgotPassword(email:string):Observable<any>{
    return this.apiService.get('/v1/User/ForgotPassword?Email='+email)
      .pipe(map(
      data => {        
        return data;
      }
    ));
  }
}
