import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService, UpsellService } from '../core';
declare var $: any;
@Component({
  selector: 'app-property-enhancements',
  templateUrl: './property-enhancements.component.html',
  styleUrls: ['./property-enhancements.component.scss']
})
export class PropertyEnhancementsComponent implements OnInit {

  propertyEnhancements: any;
  apiPropertyEnhancements: any;
  reservationId: number = 0;
  unitId: number = 0;
  pageNumber: number = 1;
  throttle = 150; // call after scroll stop 150 mili seconds
  distance = 2; // 20% distance remain from footer
  isPropertyHaveUpsells: boolean = true;
  upsellCategories: any;
  activeTab: number = 0;
  constructor(
    private upsellService: UpsellService,
    private route: ActivatedRoute,
    private spinner: SpinnerService
  ) {
    this.unitId = route.snapshot.params['uid'];
    this.reservationId = route.snapshot.params['rid'];
   }

  ngOnInit(): void {
    this.getPropertyEnhancements(this.pageNumber);
  }

  getPropertyEnhancements(pageNumber: number){
    this.spinner.show();
    this.upsellService.getPropertyUpsells(this.reservationId, this.unitId, pageNumber).subscribe(
      {
        next: (response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            
            this.upsellCategories = response.data.upsellCategories;
            var allUpsellCatoryType = { upsellCategoryName: "All", upsellCategoryId: 0 };
            this.upsellCategories.splice(0, 0, allUpsellCatoryType);

            this.propertyEnhancements = this.apiPropertyEnhancements = response.data.upsellItems;
            // if(!this.propertyEnhancements || this.propertyEnhancements.length == 0){
            //   this.propertyEnhancements = response.data.upsellItems; 
            // }
            // else{
            //   this.propertyEnhancements.push(...response.data.upsellItems);
            // }
                       
          }

          this.propertyHaveUpsells();
          this.setCustomScrollBar()
        },
        error: (e) =>{
          this.propertyHaveUpsells();
          this.spinner.hide();
        },
        complete: () => this.spinner.hide() 
      }
      
    );
  }

  changeTab(tabIndex:number,upsellCategoryID: any){
    this.activeTab = tabIndex;
    if(upsellCategoryID == 0){
      this.propertyEnhancements = this.apiPropertyEnhancements;

    }else{
      this.propertyEnhancements = this.apiPropertyEnhancements.filter((x:any) => x.upsellCategoryId == upsellCategoryID);

    }
  }
  propertyHaveUpsells()
  {
    if(this.pageNumber == 1 && (!this.propertyEnhancements || this.propertyEnhancements.length == 0)){
      this.isPropertyHaveUpsells = false;
    }
  }

  onScroll(): void {
    this.getPropertyEnhancements(++this.pageNumber);    
  }

  setCustomScrollBar(){
    setTimeout(() => {
      $("#tabs").mCustomScrollbar({
        axis:"x",
        advanced:{
          autoExpandHorizontalScroll:true
        }
      });
    });
  }
}
