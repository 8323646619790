import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages, ReservationService, ReservationStatusType, SpinnerService } from '../core';
import { UserDeviceType } from '../core/enum/user-device-type';

@Component({
  selector: 'app-support-app',
  templateUrl: './support-app.component.html',
  styleUrls: ['./support-app.component.scss']
})
export class SupportAppComponent implements OnInit {

  reservationId: number = 0;
  submitted: boolean = false;
  supportForm= new FormGroup(
    {
      reservationId: new FormControl(0, [Validators.required]),
      notes: new FormControl('', [Validators.required]),
      issueType: new FormControl('from_profile', [Validators.required]),
      deviceType: new FormControl(UserDeviceType[UserDeviceType.Web], [Validators.required])
    }
  );
  constructor(
    private reservationService: ReservationService,
    private spinner: SpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) { 
  }

  ngOnInit(): void {
    this.getUserCurrentReservation();
  }

  getUserCurrentReservation(){
    this.spinner.show();
    this.reservationService.getUserReservations(ReservationStatusType.Upcoming, 1).subscribe(
      {
        next: (response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            if(response.data && response.data.length > 0){
              this.reservationId = response.data[0].reservationID;     
              this.supportForm.patchValue({
                reservationId: this.reservationId
              });  
            }
                     
          }
          
        },
        error: (e) => this.spinner.hide(),
        complete: () => this.spinner.hide()
      }
      
    );
  }

  get f(){
    return this.supportForm.controls;
  }

  submitForm() {

    this.submitted = true;   
    // stop here if form is invalid
    if (this.supportForm.invalid) {
      return;
    }

    this.spinner.show();
    this.reservationService
    .requestForSupportV5(this.supportForm.value)
    .subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok){
            this.toastr.success(response.message);
            setTimeout(()=>{
              this.router.navigateByUrl('/profile');
            },1500);
          }
          else{
            this.toastr.error(response.message);
          }
        },
        error:(e)=>{
          
          this.submitted = false;
          this.spinner.hide();
          if(e && e.data && e.data.length > 0){
            this.toastr.error(e.data[0]);
          }
          else{
            this.toastr.error(ErrorMessages.InternalServerError);
          }
          
        },
        complete:() => this.spinner.hide()
      }
      
    );
  }

}
