import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContactHostComponent } from './contact-host.component';
import { SharedModule } from '../shared';
import { ContactHostRoutingModule } from './contact-host-routing.module';

@NgModule({
  imports: [
    SharedModule,
    ContactHostRoutingModule
  ],
  declarations: [
    ContactHostComponent
  ],
  providers: [    
  ]
})
export class ContactHostModule {}
