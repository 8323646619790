import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService, SpinnerService } from '../../core';

type Step = 'damageWaiverLanding' | 'damageWaiverPayment' | 'depositWaiverPayment'

@Component({
  selector: 'app-damage-waiver',
  templateUrl: './damage-waiver.component.html',
  styleUrls: ['./damage-waiver.component.scss']
})
export class DamageWaiverComponent implements OnInit {

  @Input() securityTransactionStageSkip:boolean = false;
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() skipParentModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getPreviousCheckInSequence: EventEmitter<any> = new EventEmitter<any>();
  @Input() reservationId: number = 0;
  @Input() unitId: number = 0;
  public currentStep:string ='damageWaiverLanding';
  constructor(
    private spinner: SpinnerService
  ) {
    
   }

  ngOnInit(): void {
    
  }


  changeStep(request:any) {
    //currentStep: string, direction: 'forward' | 'back'
    this.currentStep = request.currentStep;
  }

  skipModule(request:any){
    this.skipParentModuleStep.emit(request);
  }
  goBackToPreviousStep(){
    this.getPreviousCheckInSequence.emit();
  }
}
