import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { isModuleNamespaceObject } from 'util/types';
import { CheckInModuleStageEnum, CheckInModuleStageEnumLabel, CheckInService, CmsPageType, CommonApiService, PropertyService, SpinnerService } from '../core';

type Step = 'Verification' | 'SecurityTransactions' | 'ArrivalTime' | 'AdditionalGuests' | 'ArrivalComplete';

@Component({
  selector: 'app-check-in-flow',
  templateUrl: './checkin-flow.component.html',
  styleUrls: ['./checkin-flow.component.scss']
})
export class CheckInFlowComponent implements OnInit {

  private moduleCurrentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('Verification');
  public moduleCurrentStep$: Observable<Step> = this.moduleCurrentStepBs.asObservable().pipe();

  public moduleCurrentStep:string ='';
  isShowVerificationTerms:boolean = false;
  termsConditions: string = "";
  public reservationId:number = 0;
  public unitId:number = 0;
  checkInModuleStage = CheckInModuleStageEnum;
  verificationStageSkip: boolean = false;
  securityTransactionStageSkip: boolean = false;
  arrivalTimeStageSkip: boolean = false;
  additionalGuestStageSkip: boolean = false;

  completedSteps:any = [];
  propertyUnit:any;
  checkInForm: FormGroup;

  checkInFlowSteps: any = [];
  initialModuleName:any;
  constructor(
    private spinner:SpinnerService,
    private commonApiService:CommonApiService,
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private router:Router,
    private checkInService: CheckInService,
    private toastr: ToastrService,
    private _fb: FormBuilder
  ) {
    this.reservationId = parseInt(this.route.snapshot.params['rid']);
    this.unitId = parseInt(this.route.snapshot.params['uid']);
    this.checkInForm = this._fb.group({
      arrivalInfo: null
    });
   }

  ngOnInit(): void {
    this.checkGuestVerificationStatus();
    this.moduleCurrentStep$.subscribe(
      
      (currentStep)=>{
        this.moduleCurrentStep = currentStep;
      }
    
  )
  }

  showHideSpinner(isShow: any){
    if(isShow){
      this.spinner.show();
    }
    else{
      this.spinner.hide();
    }
  }

  checkGuestVerificationStatus(){
    this.getUserUnits();
  }

  getUserUnits(){
    this.showHideSpinner(true);
    this.propertyService.getUserUnits().subscribe(
      {
        next:(response)=>{
          if(response && response.code == HttpStatusCode.Ok && response.data){            

              if(response.data.units && response.data.units.length > 0){
                this.propertyUnit = response.data.units.find((x:any) => x.reservationID == this.reservationId);
                if(this.propertyUnit){
                  
                  if(this.propertyUnit.checkInSequence == null){
                    this.router.navigateByUrl('/home/'+this.reservationId)
                  }
                  else {
                    this.getSkipableCheckinModuleStage(this.propertyUnit);
                    this.initialModuleName = this.getCheckinModuleStage(this.propertyUnit);

                  }
                }
                else{
                  this.router.navigateByUrl('/home');
                }
              }
              if(this.initialModuleName == ""){
                this.router.navigateByUrl('/home/'+this.reservationId)
              }
              else{
                this.moduleCurrentStepBs.next(this.initialModuleName);
                
              }
          }

        },

        error:(err) => {
          this.showHideSpinner(false);
        },
        complete:() => this.showHideSpinner(false)

      }


    );
  }

  subformInitialized(name:string, formGroup: any) {
    
    setTimeout(() => {
      this.checkInForm.setControl(name, formGroup);
    }, );    
    
  }

  getSkipableCheckinModuleStage(propertyUnit:any){
    
    var moduleName:any;
    for (let element of propertyUnit.checkInSequence.checkInModuleStatus) {
      moduleName = element.moduleName;
      var moduleNameEnumValue = parseInt(CheckInModuleStageEnum[moduleName]);
      var checkInSequenceDescription = CheckInModuleStageEnumLabel.get(moduleNameEnumValue);
      var moduleDetail = Object.entries(propertyUnit.checkInSequence).filter((key:any,value:any)=>{
        return key[0] == checkInSequenceDescription
      })
      var obj:any = Object.fromEntries(moduleDetail);
      var isModuleCanSkip = obj[checkInSequenceDescription!]?.allowSkip??false;
      switch (moduleNameEnumValue) {
        case CheckInModuleStageEnum.Verification:
          this.verificationStageSkip = isModuleCanSkip;
          break;
        case CheckInModuleStageEnum.SecurityTransactions:
          this.securityTransactionStageSkip = isModuleCanSkip;
          break;
        case CheckInModuleStageEnum.ArrivalTime:
          this.arrivalTimeStageSkip = isModuleCanSkip;
          break;
        case CheckInModuleStageEnum.AdditionalGuests:
          this.additionalGuestStageSkip = isModuleCanSkip;
          break;    
        default:
          break;
      }
    }
  }

  getNextCheckinSequenceStep(currentCheckInStep:any){
    if(this.propertyUnit.checkInSequence && this.propertyUnit.checkInSequence.checkInModuleStatus){     

      var totalSteps = this.propertyUnit.checkInSequence.checkInModuleStatus.length;
      var currentStep = this.propertyUnit.checkInSequence.sequenceOrder.indexOf(currentCheckInStep);
      var newStep = currentStep + 1;
      var newStepName = '';
      if(!this.checkInFlowSteps.includes(currentCheckInStep)){
        this.checkInFlowSteps.push(currentCheckInStep);
      }
      for(newStep; newStep < totalSteps; newStep++){
        if(this.propertyUnit.checkInSequence.checkInModuleStatus[newStep].moduleStatus == null){
          newStepName = this.propertyUnit.checkInSequence.checkInModuleStatus[newStep].moduleName;
          break;
        }
      }
      /// When current step is arrival time and no more step remaining then
      if(this.propertyUnit.checkInSequence.checkInModuleStatus[currentStep].moduleName == 'ArrivalTime' 
        && newStepName == ''){
          this.checkInService.getCheckInSummary(this.reservationId)
              .subscribe({
                next:(response) => {
                    if(response && response.code == HttpStatusCode.Ok && response.data){
                      this.moduleCurrentStepBs.next('ArrivalComplete' as Step);  
                    }  
                    else{
                      this.router.navigateByUrl(`/home/${this.reservationId}`);
                    }                  
                },
                error:(e) => {
                  this.router.navigateByUrl(`/home/${this.reservationId}`);
                }
              })
         
      }

      /// When current step is additional guest and arrival time exists but not payable
      else if(
        this.propertyUnit.checkInSequence.checkInModuleStatus.find((x:any) => x.moduleName == 'ArrivalTime')
        && this.propertyUnit.checkInSequence.checkInModuleStatus[currentStep].moduleName == 'AdditionalGuests'
      ){
        this.checkInService.getCheckInSummary(this.reservationId)
              .subscribe({
                next:(response) => {
                    if(response && response.code == HttpStatusCode.Ok && response.data){
                      this.moduleCurrentStepBs.next('ArrivalComplete' as Step);  
                    }     
                    else{
                      this.router.navigateByUrl(`/home/${this.reservationId}`);
                    }               
                },
                error:(e) => {
                  this.router.navigateByUrl(`/home/${this.reservationId}`);
                }
              })
      }
      else if(newStepName != ''){
        this.moduleCurrentStepBs.next(newStepName as Step);
      }
      else{
        this.router.navigateByUrl(`/home/${this.reservationId}`);
      }
    } 
  }

  getPreviousCheckInSequence(){
    
    if(this.checkInFlowSteps.length > 0){
      var lastCheckInFlowStep = this.checkInFlowSteps.pop();
      if(lastCheckInFlowStep == 'Verification'){
        this.router.navigateByUrl(`/home/${this.reservationId}`);

      }else{
        this.moduleCurrentStepBs.next(lastCheckInFlowStep as Step);
      }
    }
    else{
      this.router.navigateByUrl(`/home/${this.reservationId}`);
    }
  }

  getCheckinModuleStage(propertyUnit:any){
    //return 'ArrivalTime';
    //return 'ArrivalComplete';
    var moduleName:any;
    if(propertyUnit.checkInSequence && propertyUnit.checkInSequence.checkInModuleStatus){      

      for (let element of propertyUnit.checkInSequence.checkInModuleStatus) {
        moduleName = element.moduleName;
        var enumValue = parseInt(CheckInModuleStageEnum[moduleName]);
        var checkInSequenceDescription = CheckInModuleStageEnumLabel.get(enumValue);
        var moduleDetail = Object.entries(propertyUnit.checkInSequence).filter((key:any,value:any)=>{
          return key[0] == checkInSequenceDescription
        })
        var obj:any = Object.fromEntries(moduleDetail);
        //var isModuleCanSkip = obj[checkInSequenceDescription!]?.allowSkip??false;
        // if(moduleName == this.checkInModuleStage[CheckInModuleStageEnum.Verification]){
        //   this.guestVerificationStatus = obj[checkInSequenceDescription!]?.verificationStatus;
        // }

        if(element.moduleStatus == null){
          
          break;
        }
        else{
          moduleName = "";
        }

      }

    }
    if(moduleName != "" && !this.checkInFlowSteps.includes(moduleName)){
      this.checkInFlowSteps.push(moduleName);
    }
    return moduleName;
  }
  moduleChangeStep(request: any) {
    //moduleCurrentStep: string, direction: 'forward' | 'back'
    var moduleCurrentStep= request.moduleCurrentStep;
    var direction= request.direction;
      switch(moduleCurrentStep) {

        case 'Verification':
          if (direction === 'forward') {
            this.moduleCurrentStepBs.next('SecurityTransactions');
          }
          break;
        case 'SecurityTransactions':
          if (direction === 'forward') {
            this.moduleCurrentStepBs.next('ArrivalTime');
          }
          break;
        case 'ArrivalTime':
          if (direction === 'forward') {
            this.moduleCurrentStepBs.next('AdditionalGuests');
          }
          break;
        case 'AdditionalGuests':
          if (direction === 'forward') {
            this.moduleCurrentStepBs.next('ArrivalComplete');
          }
          break;

      }
    }

    showCommonTermsCondition(isShow: any){

      if(!this.isShowVerificationTerms && isShow){
        this.getTermsAndConditions();
      }
      this.isShowVerificationTerms = isShow;
    }

    getTermsAndConditions(){
      this.spinner.show();
      this.commonApiService.getCmsPage(CmsPageType.GuestVerificationTerms).subscribe(
         {
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.termsConditions = response.data.pageContent;
            }
          },
          error:(err) => {
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        }
      );
    }

    skipModule(request:any){
      if(request.action && request.action == "complete"){
        //this.getUserUnits()
        //this.moduleChangeStep({"moduleCurrentStep":request.step, "direction":"forward"})
        this.getNextCheckinSequenceStep(request.step);
      }
      else{
        this.postSkipModule(request);      
      }
    }

    

    postSkipModule(request:any){
      
      this.checkInService.skipModule({"module":request.step, "reservationId":this.reservationId})
          .subscribe({
            next: (response) => {
              if(response && response.code == HttpStatusCode.Ok){
                this.getNextCheckinSequenceStep(request.step)
              }
            },
            error:(e) => {
              this.toastr.error(e.message);
            }
          })
    }
}
