<div class="page-box" *ngIf="amenityItem">
  <div class="banner-with-header">
    <div class="banner-top" *ngIf="amenityItem.propertyAmenityImages">
      <a routerLink="/home/{{reservationId}}" class="backbtn_banner"></a>
      <img [src]="amenityItem.propertyAmenityImages[0].banner" alt="">
    </div>
    <h1 class="page-name">{{amenityItem.amenityName}}</h1>
  </div>

  <div class="container">
    <div *ngIf="amenityItem.yourNetwork">
      <div class="contact-info-box" >
        <strong>Name:</strong>
        <label>{{amenityItem.yourNetwork.wifiName}}</label>
      </div>
      <div class="contact-info-box mt-4">
        <strong>Password:</strong>
        <label>{{amenityItem.yourNetwork.password}}</label>
      </div>
    </div>
    
    <div class="desc-min-height">
        <p class="sub-title mt-5" [innerHTML]="amenityItem.description"></p>
    </div>
    <a *ngIf="amenityItem.yourNetwork" (click)="copyCredential()" class="btn btn-primary mt-5 w-100">Copy Credentials</a>
  </div>

</div>
<div class="error-page" *ngIf="!amenityItem">
  <!-- <div class="container">
    <h1 class="heading-title">No record found.Try again! </h1>
  </div> -->
</div>

