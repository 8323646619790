import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core';

@Component({
  selector: 'app-match-reservation',
  templateUrl: './match-reservation.component.html',
  styleUrls: ['./match-reservation.component.scss']
})
export class MatchReservationComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() maskedMobileNumber: string = "";

  @Input() startingForm: FormGroup;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitFormStep: EventEmitter<any> = new EventEmitter<any>();

  matchReservationInfoForm: FormGroup;
  countryCodes:any;
  selectedCountryFlag: string = "";
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) { 
    this.startingForm = this.matchReservationInfoForm = this.fb.group({
      countryId: new FormControl(0),
      countryCode: new FormControl(''),
      mobileNumber: new FormControl('',Validators.required),
      lastName: new FormControl('',Validators.required),
    });
  }

  ngOnInit(): void {
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;
            let defaultCountry = this.countryCodes.find((x:any) => x.countryCode == '+1')
            if(defaultCountry){
              this.matchReservationInfoForm.patchValue({
                countryId:defaultCountry.countryID,
                countryCode: defaultCountry.countryCode

              });
              this.selectedCountryFlag = defaultCountry.countryCodeImage;
            }
            else{
              this.matchReservationInfoForm.patchValue({
                countryId: this.countryCodes[0].countryID,
                countryCode: this.countryCodes[0].countryCode

              });
              this.selectedCountryFlag = this.countryCodes[0].countryCodeImage;

            }
          }
        },
        error:(e) =>{
          
        }
      }
    );
    this.subformInitialized.emit(this.matchReservationInfoForm);
  }

  get f(){
    return this.matchReservationInfoForm.controls;
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.matchReservationInfoForm.patchValue({
          countryCode: selectedCountry.countryCode

        });
        this.selectedCountryFlag = selectedCountry.countryCodeImage;
      }
    }
  }

  submitForm(){
    this.submitted = true;
    if(this.matchReservationInfoForm.invalid){
      return false;
    }

    this.submitFormStep.emit();
    return true;
  }

  doChangeChildStep(){
    this.changeChildStep.emit({"currentStep":"matchReservationStep", "direction":"back"});
  }
}
