import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckInModuleStageEnum } from 'src/app/core';

@Component({
  selector: 'app-collect-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss']
})
export class DobComponent implements OnInit {

  @Input() startingForm: FormGroup;
  @Input() verificationStageSkip: boolean = false;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum;
  public dobInfoForm: FormGroup;
  submitted: boolean = false;
  currentDate = new Date();
  constructor(
    private fb:FormBuilder
  ) {
    this.currentDate = new Date();
    this.currentDate.setFullYear(this.currentDate.getFullYear() - 18);

    this.startingForm = this.dobInfoForm = this.fb.group({
      date: new FormControl(this.currentDate.getDate()),
      month: new FormControl((this.currentDate.getMonth()+1), [Validators.required]),
      year: new FormControl(this.currentDate.getFullYear(), [Validators.required])
    });

    
   }

  ngOnInit(): void {
      this.subformInitialized.emit(this.dobInfoForm);
  }

  get f(){
    return this.dobInfoForm.controls;
  }
  doChangeStep() {
    this.submitted = true;
    if(this.dobInfoForm.invalid){
      return;
    }

    this.dobInfoForm.patchValue({
      date: this.currentDate.getDate(),
      month: this.currentDate.getMonth()+1,
      year: this.currentDate.getFullYear()
    });
    this.changeChildStep.emit({"currentStep":"dobInfoStep", "direction":"forward"});
  }

  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }

  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification});
  }
}
