<app-confirm-dialog></app-confirm-dialog>
<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/home/{{reservationId}}" class="back-page"></a>
        <h2>Registered Guests</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
    <div class="container" *ngIf="additionalGuests">
        <nav class="nav-links mt-3">
          <ul>
            <li *ngFor="let guest of additionalGuests">
                <a (click)="goToGuestDetail(guest.reservationAdditionalGuestID)">{{ guest.firstName}} {{guest.lastName}}
                  <span class="r-number">{{guest.email}}</span>
                </a>
              </li> 
                    
          </ul>
        </nav>
        <a (click)="addGuest()"><div class="w-100 text-end mt-3" style="color: #809DA7"><strong>Add A Guest</strong></div></a>
        <div class="mt-4">
            <p>
                <strong>Note: </strong> Adding guests will provide them a mobile key and access.
            </p>
        </div>
      </div>
  </div>
