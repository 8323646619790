import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogService, SpinnerService, User, UserService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private spinner: SpinnerService,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService
  ) {
    this.currentUser = {} as User;
  }

  currentUser: User;

  ngOnInit(): void {
    this.spinner.show();
    this.userService
      .getUserProfileImageAndName()
      .subscribe({
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.currentUser = response.data;
          }
        },
        error: (e) => { this.spinner.hide(); },
        complete: () => this.spinner.hide()
      });

    this.mixpanelService.sendScreenVisitEvent("Profile")
    this.renderer.addClass(document.body, 'translator');
  }

  logout() {
    this.confirmDialogService.confirmThis('Are you sure you want to log out?', 'Log out', 'Yes', 'No', () => {
      this.userService
        .logOut()
        .subscribe(
          (response) => {
            if (response && response.code == HttpStatusCode.Ok) {
              this.userService.purgeAuth();
              this.router.navigateByUrl('/');
            }
          }
        );
    }, () => {
    });

  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'translator');
  }
}
