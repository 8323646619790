import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { PassportComponent } from './passport.component';
import { PassportRoutingModule } from './passport-routing.module';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  imports: [
    SharedModule,
    PassportRoutingModule,
    QRCodeModule
  ],
  declarations: [
    PassportComponent
  ],
  providers: [    
  ]
})
export class PassportModule {}
