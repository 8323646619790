import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages, SpinnerService } from '../core';
import { SupportService } from '../core/services/support.service';

@Component({
  selector: 'app-sign-in-support',
  templateUrl: './sign-in-support.component.html',
  styleUrls: ['./sign-in-support.component.scss']
})
export class SignInSupportComponent implements OnInit {

  submitted: boolean = false;
  supportForm= new FormGroup(
    {
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),
      notes: new FormControl('', [Validators.required]),
      issueType: new FormControl('login',[Validators.required]),
      deviceType:new FormControl('web' ,[Validators.required])
    }
  );
  constructor(
    private supportService: SupportService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private router: Router
  ) { }
  

  ngOnInit(): void {
  }

  get f(){
    return this.supportForm.controls;
  }

  signInSupportRequest(){
    
    this.submitted = true;
    if(this.supportForm.invalid){
      return;
    }

    this.spinner.show();
    this.supportService.supportForCreateAccount(this.supportForm.value)
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.toastr.info(response.message);
              setTimeout(() => {
                this.router.navigateByUrl('/login');
              }, 2000);
            }
          },
          error: (e) => {
            this.toastr.error(ErrorMessages.InternalServerError);
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        });
    
    
  }
}
