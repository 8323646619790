import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { JwtService } from '../services';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators'
import { ErrorMessages } from '../enum';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const token = this.jwtService.getToken();    
    
    headersConfig['Authorization'] = `bearer ${token}`;
    
    const request = req.clone({ setHeaders: headersConfig });
    
    return next.handle(request).pipe(tap(
      {
        next:() =>{},
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 0) {
              throw throwError(()=> new Error(ErrorMessages.UnableToConnectServer));
            }
            if(err.status == HttpStatusCode.InternalServerError){
              throw throwError(()=> new Error(ErrorMessages.InternalServerError));
            }
            else if(err.status === HttpStatusCode.Forbidden || err.status === HttpStatusCode.Unauthorized){
              this.jwtService.destroyToken();
              this.router.navigateByUrl('/login');
              location.reload();
            }           
            return;
          }
        }
      }));
  }
}
