<div class="error_flex">
    
      <form class="mt-5"  [formGroup]="reservationNotFoundForm"  (ngSubmit)="submitForm()">
        
        <div class="container">
          <a routerLink="/sign-up" class="back-page back-page-black"></a>
          <h1 class="heading-title">
            No reservation found
          </h1>
          <p class="sub-title mt-4">We’re sorry but your host requires verification to view property details. Your reservation has not synced yet. This may take up to 24 hours. You can try this link again, submit a ticket or contact your host below. </p>
          <div class="form-min-height">
            <div class="form-group d-block mt-4">
              <label class="form-label">Reservation Number</label>
              <input type="text" class="form-control" formControlName="reservationNumber" [ngClass]="{ 'is-invalid': submitted && f.reservationNumber.errors }" />
              <div *ngIf="submitted && f.reservationNumber.errors" class="invalid-feedback">
                <div *ngIf="f.reservationNumber.errors['required']">Reservation Number is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Hotel Name</label>
              <input type="text" class="form-control" formControlName="hotelName" [ngClass]="{ 'is-invalid': submitted && f.hotelName.errors }" />
              <div *ngIf="submitted && f.hotelName.errors" class="invalid-feedback">
                <div *ngIf="f.hotelName.errors['required']">Hotel Name is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Last Name</label>
              <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors['required']">Email is required</div>
                <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
              </div>
            </div>    
            <div class="form-group d-block mt-4">
              <label class="form-label">Expected Reservation Date</label>
              <span #dp="bsDatepicker" bsDatepicker [(bsValue)]="currentDate" class="date-piker" [bsConfig]="{containerClass:'theme-default'}"></span>
              
              <input type="text" formControlName="expectedReservationDate" class="form-control relative" [value]="currentDate | date: 'dd-MM-yyyy'"/>
            </div>        
          </div>         
            
        </div>        
        <div class="container">
          <button class="btn btn-outline w-100">Submit Ticket</button>
        </div>
      </form>
    
  </div>
  