import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PropertyService, SpinnerService } from '../core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.scss']
})
export class PropertyDetailComponent implements OnInit {

  stayId: number = 0;

  propertyDetail: any;

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private location:Location,
    private spinner: SpinnerService) { 
    if(this.route.snapshot.params['id']){
      this.stayId = Number(this.route.snapshot.params['id']);
    }
    
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,    
    items:1,
    autoplay:true
  }

  ngOnInit(): void {
    this.fillPropertyDetail();
  }

  fillPropertyDetail(){
    this.spinner.show();
    this.propertyService.getPropertyDetail(this.stayId).subscribe(
      {
        next:(response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            this.propertyDetail = response.data;
          }
        },
        error:(err) => {
          this.spinner.hide();
        },
        complete:() => this.spinner.hide()
      }
      
    );
  }

  goToBack() {
    this.location.back();
  }

}
