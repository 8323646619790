import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages, SpinnerService, UserService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]]
    });
  }

  ngOnInit(): void {
    this.mixpanelService.sendScreenVisitEvent("ForgotPassword")
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  submitForm() {
    this.submitted = true;

    //stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.spinner.show();
    let email = this.forgotPasswordForm.get('email')?.value;
    this.userService
      .forgotPassword(email)
      .subscribe(
        {
          next: (response) => {
            if (response.code == HttpStatusCode.Ok) {
              this.toastr.success(response.message);
              setTimeout(() => {
                this.router.navigateByUrl('/login');
              }, 2000);
            }
            else {
              this.toastr.error(response.message);
            }
          },
          error: (e) => {

            this.submitted = false;
            this.spinner.hide();
            this.toastr.error(e.message);

          },
          complete: () => this.spinner.hide()
        }

      );
  }
}
