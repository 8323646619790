import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

import { SpinnerService, User, UserService } from '../../core';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  isShow= false;
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '100%',
    margin: '0 auto',
    position: 'fixed',
    zIndex: '1002',
    background: 'rgba(30,30,30,0.75)',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  constructor(
    private userService: UserService,
    private spinnerService: SpinnerService
  ) {}

  currentUser: User | undefined;

  ngOnInit() {
    //this.currentUser =  this.userService.getCurrentUser();
    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );
    
    this.spinnerService.showOrHideLoadingAnimation.subscribe((isShow)=>{
      this.isShow = isShow;
    });
  }
}
