import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { PropertyDirectionComponent } from './property-direction.component';
import { PropertyDirectionRoutingModule } from './property-direction-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';


@NgModule({
  imports: [
    SharedModule,
    PropertyDirectionRoutingModule,
    GoogleMapsModule,
  ],
  declarations: [
    PropertyDirectionComponent
  ],
  providers: [    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PropertyDirectionModule {}
