<app-confirm-dialog></app-confirm-dialog>

<div class="vertical-flex-box">
    <div class="container">
      <div class="logo-circle mt-3"><img src={{currentUser.profilePicture}} alt="" onError="this.src='/assets/images/profile.png'"></div>
      <h3 class="section-title mt-4 text-center">{{currentUser.firstName}} {{currentUser.lastName}}</h3>
      <nav class="nav-links mt-4">
        <ul>
          <li><a routerLink="/purchases">Purchases</a></li>
          <li><a routerLink="/support">Support</a></li>
          <li><a routerLink="/personal-detail">Personal Information</a></li>
          <li><a routerLink="/change-password">Password & Security</a></li>
          <li><a routerLink="/terms">Terms of Service</a></li>
          <li><a routerLink="/reservations">Switch Reservation</a></li>
          <li><a (click)="logout()" style="cursor:pointer">Logout</a></li>
        </ul>
      </nav>
    </div>
    <div class="app-version"><div class="container">DACK Web Version 2.1</div></div>

  </div>