import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PrivacyPolicyComponent } from './privacy-policy.component';
import { SharedModule } from '../shared';
import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PrivacyPolicyRoutingModule
  ],
  declarations: [
    PrivacyPolicyComponent
  ],
  providers: [    
  ]
})
export class PrivacyPolicyModule {}
