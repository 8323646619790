<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/" class="back-page"></a>
        <h2>Create Account</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block remove-footer-space">
  
      <div class="container">
          <h1 class="heading-title">Please enter your mobile phone number</h1>
          <form class="mt-5 form-flex" [formGroup]="signUpPhoneForm" (ngSubmit)="CheckReservationAndSendCode()">            
            <div class="country-input">
              <span class="custom-select">
                <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                <input type="text" value="{{selectedCountryCode}}" class="line-input selectform_control" />
                <select class="line-input" formControlName="countryId" (change)="onCountryChange($event)">
                  <option *ngFor="let country of countryCodes" [value]="country.countryID">{{country.countryName}}</option>
                </select>
              </span>
              <input type="text" class="line-input" formControlName="phoneNumber" [maxLength]="13" numbersOnly />
            </div>
            <div >
              <div class="terms-condition">
                <input type="checkbox" formControlName="acceptTermsAndConditions" (click)="changeAcceptanceOfTermsConditions()" /> I have read and accepted the <a routerLink="/terms">Terms and Conditions</a> and <a  routerLink="/privacy-policy">Privacy Policy</a>

              </div>
              <button class="btn btn-primary w-100 mt-5">Get Started</button>
            </div>
            
          </form>
      </div>
    
  </div>