<ng-container *ngIf="recommendation">
    <div class="banner-with-header">
        <div class="banner-top">
            <a (click)="goToBack()" class="backbtn_banner"></a>
            <owl-carousel-o [options]="customOptions" class="sliderowl">   
            <ng-container *ngFor="let slide of recommendation.images">
                <ng-template carouselSlide>
                <img [src]="slide" >
                </ng-template>
            </ng-container>             
            </owl-carousel-o>
        </div>
        <h1 class="page-name">{{recommendation.name}}<span class="localfav">{{recommendation.source == "Host" ? "Host Recommended" : "Local Spot"}}</span></h1>
      </div>

      <div class="container">
        <div class="localrating">
          <i class="fa-solid fa-star"></i> <strong> {{recommendation.rating}}</strong> <span> Host Rating</span>  
        </div>

        <div class="contact-info-box  mt-4" *ngIf="recommendation.phoneNumber">
          <label><a href="tel:+1{{recommendation.phoneNumber}}">+1{{recommendation.phoneNumber}}</a></label>
        </div>
        <div class="contact-info-box mt-4" *ngIf="recommendation.address" (click)="showLinkPopup()">
            <label style="cursor:pointer">{{recommendation.address}}</label>
        </div>
        <div class="contact-info-box mt-4" *ngIf="recommendation.destinationURL">
          <a href="{{recommendation.destinationURL}}" target="_blank">
            <label style="cursor: pointer;" *ngIf="recommendation.linkText">{{recommendation.linkText}}</label>
            <label style="cursor: pointer;" *ngIf="!recommendation.linkText">Visit Site</label>
          </a>
        </div>

        <div class="sub-title mt-3" [innerHTML] = "recommendation.descriptionHTML"></div>

      </div>

</ng-container>

<div id="linkPopup" *ngIf="isShowLinkPopup" class="modal" tabindex="-1" role="dialog" style="display:block!important">  
  <div class="modal-dialog custom-alert modal-dialog-centered" role="document">  
      <div class="modal-content">
        <div class="modal-body">  
          <div class="row">  
              <div class="col-md-12">  
                  <ul>
                    <li>Dack</li>
                    <li (click)="addressOnGoogleMap()">Open in Google Map</li>
                    <li (click)="copyAddressToClipboard()">Copy to Clipboard</li>
                    <li (click)="isShowLinkPopup=false">Cancel</li>
                  </ul>
              </div>  
          </div>  
          
        </div>  
      </div>
  </div>
</div>