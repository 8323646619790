<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/profile" class="back-page"></a>
        <h2>Switch Reservation</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
    <div class="container">
        <nav class="nav-links mt-3" *ngIf="userReservations.length > 0">
          <ul>
            <li *ngFor="let reservation of userReservations">
              <a routerLink="/home/{{reservation.reservationID}}">{{ reservation.unitName}}
                <span class="r-number">{{reservation.reservationNumber}}</span>
              </a>
            </li>          
          </ul>
        </nav>
        <h1 class="heading-title" *ngIf="userReservations.length == 0">No other reservations available</h1>

      </div>
  </div>
