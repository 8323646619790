import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReservationNotFoundComponent } from './reservation-not-found.component';
import { SharedModule } from '../shared';
import { ReservationNotFoundRoutingModule } from './reservation-not-found-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    SharedModule,
    BsDatepickerModule.forRoot(),
    ReservationNotFoundRoutingModule
  ],
  declarations: [
    ReservationNotFoundComponent
  ],
  providers: [    
  ]
})
export class ReservationNotFoundModule {}
