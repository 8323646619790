import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConfirmDialogService, RecommendationService, SpinnerService } from '../core';
import { environment } from '../../environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from '../core/services/mixpanel.service';

declare var $: any;

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent implements OnInit {

  unitId: number = 0;
  propertyId: number = 0;
  recommendationId: number = 0;
  recommendation: any;
  isShowLinkPopup: boolean = false;
  currentLatLng: any;
  private currentLocation: string = '';
  private isMapView: string = '';
  private recommendationTypeId: number = 0;
  private reservationId: number = 0;
  constructor(
    private recommendationService: RecommendationService,
    private route: ActivatedRoute,
    private location: Location,
    private spinner: SpinnerService,
    private confirmDialogService: ConfirmDialogService,
    private clipboardApi: ClipboardService,
    private toastr: ToastrService,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {
    this.recommendationId = this.route.snapshot.params['id'];
    this.reservationId = parseInt(this.route.snapshot.params['rid']);
    this.unitId = parseInt(this.route.snapshot.params['uid']);
    this.propertyId = parseInt(this.route.snapshot.params['pid']);
    this.isMapView = this.route.snapshot.params['map-view'];
    this.recommendationTypeId = this.route.snapshot.params['type'];
  }

  ngOnInit(): void {
    $('head').append(`<script src="https://maps.googleapis.com/maps/api/js?key=` + environment.googleMapKey + `"></script>`);

    this.getRecommendationDetail();
    setTimeout(() => {
      this.getLocation();
    }, 500)

    this.mixpanelService.sendScreenVisitEvent("UpsellDetail")
  }

  getLocation(): any {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            let geocoder = new google.maps.Geocoder;

            this.currentLatLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            geocoder.geocode({
              'location': this.currentLatLng
            }, (results) => {
              if (results) {
                this.currentLocation = results[0].formatted_address;

              }
            });
          }
        },
        (error: any) => {
          this.confirmDialogService.alertThis('DACK needs your location so you can accurately use this page. Please turn on permissions at your browser settings', 'Enable Location', 'Ok', () => { });
        }
      );
    } else {
      this.confirmDialogService.alertThis('Geolocation is not supported by this browser', 'Location', 'Ok', () => { });
    }
    return this.currentLocation;
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    items: 1,
    autoplay: true
  }

  getRecommendationDetail() {
    this.spinner.show();
    this.recommendationService.getRecommendationDetail(this.recommendationId)
      .subscribe({
        next: (response) => {
          if (response.code == HttpStatusCode.Ok && response.data) {
            this.recommendation = response.data;
          }
        },
        error: (e) => this.spinner.hide(),
        complete: () => this.spinner.hide(),
      })
  }

  goToBack() {
    if (this.isMapView) {
      this.router.navigateByUrl(`/recommendations/${this.unitId}/${this.propertyId}/${this.reservationId}/${this.isMapView}/${this.recommendationTypeId}`);
    } else {
      this.location.back();
    }
  }

  showLinkPopup() {
    this.isShowLinkPopup = true;
  }

  addressOnGoogleMap() {
    var currentLocation = this.getLocation();
    if (currentLocation && currentLocation != "") {
      var redirectUrl = `https://www.google.com/maps/dir/${currentLocation}/${this.recommendation.address}`;
      window.open(redirectUrl, "_blank");
    }
    else {
      this.confirmDialogService.alertThis('Please turn on permissions at your browser settings', 'If you reject permission, you can not use this service', 'Ok', () => { });
    }
  }

  copyAddressToClipboard() {
    if (this.recommendation.address) {
      this.clipboardApi.copyFromContent(this.recommendation.address);
      this.toastr.info("Your address has been copied!");
    }

  }
}
