import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyDetailComponent } from './property-detail.component';
import { SharedModule } from '../shared';
import { PropertyDetailRoutingModule } from './property-detail-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyDetailRoutingModule
  ],
  declarations: [
    PropertyDetailComponent
  ],
  providers: [    
  ]
})
export class PropertyDetailModule {}
