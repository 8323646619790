import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { SignInSupportComponent } from './sign-in-support.component';
import { SignInSupportRoutingModule } from './sign-in-support-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SignInSupportRoutingModule
  ],
  declarations: [
    SignInSupportComponent
  ],
  providers: [    
  ]
})
export class SignInSupportModule {}
