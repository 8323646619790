<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        
        <a (click)="goToBack()" class="back-page"></a>
        <h2>Add Additional Guests</h2>
        <a class="skip" *ngIf="additionalGuestStageSkip" (click)="skipModule()">SKIP</a>
        
      </div>
    </div>
  </div>
  
  <div class="title-bar-space d-block">
    <div class="container vflexcheck">
      <ng-container *ngIf="isAddGuest; then addGuestScreen; else listScreen"></ng-container>
      <ng-template #listScreen>
        <div>
          <div class="userimagelf mb-5">
            <span><img src="../../../assets/images/userimmg.svg" alt="icon"/></span>
          </div>
          <div class="font-14 mb-4"><strong style="font-weight: 700;">Add Friends or Family staying with you. </strong></div>
          <div class="seprationPart" *ngFor="let guest of additionalGuests;let i = index;">                
            <div class="font-14 friendsmanage">
              <strong>{{guest.firstName}} {{guest.lastName}}</strong>
              <span class="minusethis" (click)="removeAdditionalGuest(guest.reservationAdditionalGuestID)">
                <img src="../../../assets/images/remove-item.svg" alt="icon"/>
              </span>
            </div>
          </div>
          
          <div class="seprationPart">                
            <div class="addguest-link friendsmanage font-14" (click)="isAddGuest=true"><strong>Add Guest</strong><span class="plusthiss"><img src="../../../assets/images/add-item.svg" alt="icon"/></span></div>
          </div> 
        </div>


        <button class="btn btn-primary w-100 mt-5" (click)="completeAdditionalGuestStep()">Continue</button>
      </ng-template>
      <ng-template #addGuestScreen>
        <div class="userimagelf mb-3">
          <span><img src="../../../assets/images/userimmg.svg" alt="icon"/></span>
        </div>
        <div class="font-14 mb-3"><strong style="font-weight: 700;">Add Guest Details Below. </strong></div>
        <form class=""  [formGroup]="guestForm"  (ngSubmit)="submitForm()" autocomplete="off">          
          <div class="form-group d-block mt-4">
              <label class="form-label">First Name</label>
              <input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors['required']">First Name is required</div>
              </div>                
          </div>
          <div class="form-group d-block mt-4">
              <label class="form-label">Last Name</label>
              <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
              </div>                
          </div>
          <div class="form-group d-block mt-4">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors['required']">Email is required</div>
                  <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
              </div>                
          </div>
          <div class="form-group d-block mt-4">
              <label class="form-label">Phone</label>
              <div class="with-country-code">
                  <span class="custom-select">                                
                      <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                      <input type="text" value="{{selectedCountryCode}}" class="selectform_control" />
                      <select class="form-control" formControlName="countryId" (change)="onCountryChange($event)">
                          <option *ngFor="let country of countryCodes" [ngValue]="country.countryID" >{{country.countryName}}</option>
                      </select>
                  </span>
                  <input type="text" class="form-control" formControlName="phoneNumber"  [maxLength]="13" numbersOnly [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" autocomplete="off" />
              </div>
              
              <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phoneNumber.errors['required']">Phone is required</div>
                  <div *ngIf="f.phoneNumber.errors['minlength']">Invalid phone number</div>
              </div>                    
          </div>
          
          
          
        <div class="form-group mt-4" >
          <button class="btn btn-primary w-100">Invite Guest</button>
          <!-- <ng-container *ngIf="!primaryGuest && guest">
              <button class="btn btn-primary w-100" *ngIf="reservationAdditionalGuestId!=0">Update Guest</button>
              <button class="btn btn-secondary w-100 mt-3 red-text" type="button" *ngIf="reservationAdditionalGuestId!=0" (click)="removeAdditionalGuest()">Delete Guest</button>
              
          </ng-container> -->
        </div>
      </form>
        
      </ng-template>
    </div>
  </div>