import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { CheckInFlowComponent } from './checkin-flow.component';
import { SharedModule } from '../shared';
import { CheckInFlowRoutingModule } from './checkin-flow-routing.module';
import { ConfirmDetailComponent } from './verification/confirm-detail/confirm-detail.component';
import { TermsComponent } from './verification/terms/terms.component';
import { AddressComponent } from './verification//address/address.component';
import { DobComponent } from './verification//collect-dob/dob.component';
import { IdProofComponent } from './verification//id-proof/id-proof.component';
import { SelfieComponent } from './verification//selfie/selfie.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { WebcamModule } from 'ngx-webcam';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VerificationProgressComponent } from './verification/verification-progress/verification-progress.component';
import { LandingComponent } from './verification//landing/landing.component';
import { VerificationComponent } from './verification/verification.component';
import { DamageWaiverComponent } from './damage-waiver/damage-waiver.component';
import { DamageWaiverPaymentComponent } from './damage-waiver/damage-waiver-payment/damage-waiver-payment.component';
import { DepositWaiverPaymentComponent } from './damage-waiver/deposit-waiver-payment/deposit-waiver-payment.component';
import { DamageWaiverLandingComponent } from './damage-waiver/damage-waiver-landing/damage-waiver-landing.component';
import { AdditionalGuestsComponent } from './additional-guests/additional-guests.component';
import { ArrivalTimeComponent } from './arrival-time/arrival-time.component';
import { ArrivalCompleteComponent } from './arrival-complete/arrival-complete.component';
@NgModule({
  imports: [
    SharedModule,
    CheckInFlowRoutingModule,
    BsDatepickerModule.forRoot(),
    WebcamModule,
    ImageCropperModule
  ],
  declarations: [
    CheckInFlowComponent,
    ConfirmDetailComponent,
    VerificationComponent,
    LandingComponent,
    AddressComponent,
    DobComponent,
    IdProofComponent,
    SelfieComponent,
    TermsComponent,
    VerificationProgressComponent,
    DamageWaiverComponent,
    DamageWaiverLandingComponent,
    DepositWaiverPaymentComponent,
    DamageWaiverPaymentComponent,
    AdditionalGuestsComponent,
    ArrivalTimeComponent,
    ArrivalCompleteComponent
  ],
  providers: [
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CheckInFlowModule { }
