<ng-container *ngIf="recommendationTypes">

  <div style="z-index: 1000;position: sticky !important;    top: 0;    background: #EAEAEA;" [className]="isMapView ?'mapbgremove':''">
    <div class="title-bar">
      <div class="container">
        <div class="title-bar-box">
          <a routerLink="/home/{{reservationId}}" class="back-page"></a>
          <h2>Local Recommendations</h2>
          <a class="map-view" (click)="viewRecommendationInMap(true)" *ngIf="!isMapView"></a>
          <a class="list-view" (click)="viewRecommendationInMap(false)" *ngIf="isMapView"></a>
        </div>
      </div>
    </div>
    <div class="container pb-0 pt-3">
      <div [class]="isMapView ? 'tabs tabs-map-view':'tabs mb-2'" id="tabs">
        <ul role="tablist" class="nav-tabs nav">
          <!-- <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#all">All</a></li> -->

          <li class="nav-item" *ngFor="let category of recommendationTypes;let i = index;">                   
            <a [className]="activeTab == i? 'nav-link active' : 'nav-link'" (click)="changeTab(i, category.recommendationTypeID)" data-bs-toggle="tab" data-target="#{{i}}">{{category.recommendationTypeFriendlyName}}</a>
          </li>                  
        </ul>
      </div>
      </div>
  
  </div>
   
      <div class="title-bar-space d-block">
      
        <div class="container pt-0">
              

              <!-- Tab panes -->
              <ng-container *ngIf="!isMapView">
                <div class="tab-content">
                  <div id="all" class="tab-pane active">

                  
                    <div class="linkBox locallists" *ngFor="let recommendation of recommendations">
                      <ng-container *ngIf="recommendation.source == 'Yelp' && recommendation.destinationURL; then yelpLink else dackLink "></ng-container>
                      <ng-template #yelpLink>
                        <a target="_blank" [href]="recommendation.destinationURL" class="anchor_cover"></a>
                      </ng-template>
                      <ng-template #dackLink>
                        <a routerLink="/recommendation/{{recommendation.recommendationID}}/{{this.unitId}}/{{this.propertyId}}/{{this.reservationId}}/{{isMapView?1:0}}/{{selectedRecommendationTypeId}}" class="anchor_cover"></a>

                      </ng-template>
                      <div class="linkBox_img"><img [src]="recommendation.image" alt="" onerror="this.onerror=null;this.src='../../assets/images/default_black_img.svg'"/></div>
                      <div class="linkBox_content">
                        <strong>{{recommendation.name}}</strong>
                        <label>{{recommendation.source == "Yelp"? "Local" : "Host"}} Favorite</label>
                        <div class="startratings" [innerHTML]="GetRating(recommendation.rating)">
                          
                        </div>
                      </div>
                    </div>  
                 

                  </div>                
                </div>
              </ng-container>
              <ng-container *ngIf="isMapView" style="padding:0px !important">
                <div class="tab-content">
                  <div id="all" class="linkboxCalls">
                    <google-map
                        height="100vh"
                        width="100%"
                        [center]="center"
                        [zoom]="zoom"
                        >
                          <map-marker *ngFor="let marker of markers"
                            [position]="marker.position"
                            [label]="marker.label"
                            [title]="marker.title"
                            [options]="marker.options"
                            setClickable="true"
                            (mapClick)="markerClick(marker)"                            
                          >
                          </map-marker>
                    </google-map>
                    <div class="linkbox_outer" id="recommendations-map-view">
                      <div class="linkBox locallists" *ngFor="let recommendation of recommendations;let i=index">
                        <ng-container *ngIf="recommendation.source == 'Yelp' && recommendation.destinationURL; then yelpLink else dackLink "></ng-container>
                      <ng-template #yelpLink>
                        <a target="_blank" [href]="recommendation.destinationURL" class="anchor_cover" id="{{recommendation.location?recommendation.location.latitude+'-'+recommendation.location.longitude:i}}"></a>
                      </ng-template>
                      <ng-template #dackLink>
                        <a routerLink="/recommendation/{{recommendation.recommendationID}}/{{this.unitId}}/{{this.propertyId}}/{{this.reservationId}}/{{isMapView?1:0}}/{{selectedRecommendationTypeId}}" class="anchor_cover" id="{{recommendation.location?recommendation.location.latitude+'-'+recommendation.location.longitude:i}}"></a>
                      </ng-template>
                        
                        <div class="linkBox_img"><img [src]="recommendation.image" alt="" onerror="this.onerror=null;this.src='../../assets/images/default_black_img.svg'"/></div>
                        <div class="linkBox_content">
                          <strong>{{recommendation.name}}</strong>
                          <label>{{recommendation.source == "Yelp"? "Local" : "Host"}} Favorite</label>
                          <div class="startratings" [innerHTML]="GetRating(recommendation.rating)">
                            
                          </div>
                        </div>
                      </div>  
                      <div class="linkBox locallists" style="    box-shadow: none !important;
                      width: 25px;
                      min-width: 25px;
                      margin-right: 0;">&nbsp;</div>
                    </div>
                  </div>
                </div>
                
              </ng-container>
              
          </div>
        
      </div>

</ng-container>
