import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TermsComponent } from './terms.component';
import { SharedModule } from '../shared';
import { TermsRoutingModule } from './terms-routing.module';

@NgModule({
  imports: [
    SharedModule,
    TermsRoutingModule
  ],
  declarations: [
    TermsComponent
  ],
  providers: [    
  ]
})
export class TermsModule {}
