import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService, ReservationService, SpinnerService } from '../core';

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.scss']
})
export class GuestsComponent implements OnInit {

  reservationId:number = 0;
  additionalGuests:any;
  maxGuestsAllowed:number = 0;
  
  constructor(
    private reservationService: ReservationService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private router:Router
    
    
  ) { 
    this.reservationId = parseInt(this.route.snapshot.params['rid']);

  }

  ngOnInit(): void {
    this.getGuestDetail();
  }

  getGuestDetail(){
    this.reservationService.getGuestDetail(this.reservationId)
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              if(response && response.code == HttpStatusCode.Ok && response.data && response.data.additionalGuestInformation){

                this.additionalGuests = response.data.additionalGuestInformation;
                this.maxGuestsAllowed = response.data.maxGuestsAllowed;
              }
            }
          },
          error:(e) => { 
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  goToGuestDetail(additionalGuestId: number){
    this.router.navigateByUrl(`/guest-detail/${this.reservationId}/${additionalGuestId}`)
  }

  addGuest(){
    if(this.maxGuestsAllowed <= this.additionalGuests.length){
      this.confirmDialogService.alertThis("We're sorry, you have reached the maximum number of guests allowed.", 'Maximum Guests Reached', 'Ok', () =>  {
        
      });          
    }
    else{
      this.router.navigateByUrl(`/guest-detail/${this.reservationId}/0`)
    }
  }
}
