import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyWifiComponent } from './property-wifi.component';
import { SharedModule } from '../shared';
import { PropertyWifiRoutingModule } from './property-wifi-routing.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    SharedModule,
    PropertyWifiRoutingModule,
    ClipboardModule
  ],
  declarations: [
    PropertyWifiComponent
  ],
  providers: [    
  ]
})
export class PropertyWifiModule {}
