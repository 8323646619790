import { ViewportScroller, DOCUMENT } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService, RecommendationService, SpinnerService } from '../core';
import { environment } from '../../environments/environment';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MixpanelService } from '../core/services/mixpanel.service';

declare var $: any;

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {

  recommendationTypes: any;
  recommendations: any;
  propertyId: number = 0;
  unitId: number = 0;
  activeTab: number = 0;
  reservationId: number = 0;
  center!: google.maps.LatLngLiteral;
  isMapView: boolean = false;
  markers: any = [];
  zoom = 15;
  private jsScript: any;
  currentActiveMarker: any;
  propertyUnit: any;
  selectedRecommendationTypeId: number = 0;
  constructor(
    private recommendationService: RecommendationService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private propertyService: PropertyService,
    private router: Router,
    private scroller: ViewportScroller,
    private _renderer2: Renderer2,
    private mixpanelService: MixpanelService,
    @Inject(DOCUMENT) private _document: any
  ) {
    this.unitId = this.route.snapshot.params['uid'];
    this.propertyId = this.route.snapshot.params['pid'];
    this.reservationId = parseInt(this.route.snapshot.params['rid']);

    var mapView = this.route.snapshot.params['map-view'];
    var recommendatioType = this.route.snapshot.params['type'];
    if (recommendatioType) {
      this.selectedRecommendationTypeId = parseInt(this.route.snapshot.params['type']);
    }
    if (mapView == '1') {
      this.isMapView = true;
    } else {
      this.isMapView = false;
    }

  }

  ngOnInit(): void {
    if (this.markers.length == 0) {
      this.jsScript = this._renderer2.createElement('script');
      this.jsScript.type = `text/javascript`;
      this.jsScript.src = `https://maps.googleapis.com/maps/api/js?key=` + environment.googleMapKey;

      this._renderer2.appendChild(this._document.body, this.jsScript);
    }
    this.getUserUnits(this.unitId, this.reservationId);
    this.mixpanelService.sendScreenVisitEvent("UpsellList")
  }

  ngOnDestroy() {
    // this removes the script so it won't be added again when the component gets initialized again.
    this._renderer2.removeChild(this._document.body, this.jsScript)
  }

  getPropertyRecommendationTypes() {
    this.recommendationService.getOperatorRecommendationTypes(this.propertyId).subscribe(
      {
        next: (response) => {
          if (response.code == HttpStatusCode.Ok && response.data) {
            this.recommendationTypes = response.data;
            var allRecommendationType = { image: '', recommendationTypeFriendlyName: "All", recommendationTypeID: null };
            this.recommendationTypes.splice(0, 0, allRecommendationType);
            this.setCustomScrollBar();
            if (this.selectedRecommendationTypeId > 0) {
              var selectedRecommendationTypeIdIndex = this.recommendationTypes.findIndex((x: any) => x.recommendationTypeID == this.selectedRecommendationTypeId);
              //this.getPropertyRecommendations(this.selectedRecommendationTypeId);
              this.changeTab(selectedRecommendationTypeIdIndex, this.selectedRecommendationTypeId);

            } else {
              this.getPropertyRecommendations(this.recommendationTypes[0].recommendationTypeID);

            }
          }
        }
      }
    )
  }

  getUserUnits(unitId: number, reservationId: number) {
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data.units && response.data.units.length > 0) {
              this.propertyUnit = response.data.units.find((x: any) => x.reservationID == reservationId && x.unitID == unitId);
              if (this.propertyUnit) {
                this.getPropertyRecommendationTypes();
              }
              else {
                this.router.navigateByUrl('/home')
              }
            }
          }
        },
        error: (e) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        }
      }


    );
  }


  getPropertyRecommendations(recommendationTypeId: number) {
    this.selectedRecommendationTypeId = recommendationTypeId ? recommendationTypeId : -1;
    this.spinner.show();
    this.recommendationService.getPropertyRecommendations(this.propertyId, this.unitId, this.selectedRecommendationTypeId).subscribe(
      {
        next: (response) => {
          if (response.code == HttpStatusCode.Ok && response.data) {
            this.recommendations = response.data;
            if (this.activeTab > 0) {
              this.markers = [];

              setTimeout(() => {
                this.setCenterOfGeoLocation();
              }, 500)
            }
          }
          else {
            this.recommendations = [];
          }
        },
        error: (e) => {
          this.recommendations = [];
          this.spinner.hide();
        },
        complete: () => this.spinner.hide()
      }
    )
  }

  viewRecommendationInMap(isMapView: boolean) {
    this.isMapView = isMapView;
    if (isMapView) {
      this.setRecommendationCustomScrollBar();
    }

    this.markers = [];

    setTimeout(() => {
      this.setCenterOfGeoLocation();
    }, 500)
  }

  setCenterOfGeoLocation() {

    this.center = {
      lat: this.propertyUnit.latitude,
      lng: this.propertyUnit.longitude,
    }
    this.addMarker(this.propertyUnit.operatorLogo, this.propertyUnit.latitude, this.propertyUnit.longitude, true);

    this.recommendations.forEach((element: any) => {
      if (element.location) {
        this.addMarker(environment.webUrl + '/assets/images/pin-white.svg', element.location.latitude, element.location.longitude, false);
      }
    });

  }

  addMarker(markerURL: string, latitude: number, longitude: number, isUnit: boolean) {
    const image = {
      url: markerURL + '#custom_marker',
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(40, 40),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0)
    };

    var marker = {

      position: {
        lat: latitude,
        lng: longitude,
      },
      options: {
        animation: google.maps.Animation.DROP,
        icon: image
      },
    }

    this.markers.push(marker);
  }
  makeActiveMarker(latitude: number, longitude: number) {
    const image = {
      url: environment.webUrl + 'assets/images/pin-grey.svg#custom_marker',
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(40, 40),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0)
    };

    var marker = {

      position: {
        lat: latitude,
        lng: longitude,
      },
      options: {
        animation: null,
        icon: image
      },
    }

    this.markers.push(marker);
  }

  makeInActiveMarker(latitude: number, longitude: number) {
    const image = {
      url: environment.webUrl + 'assets/images/pin-white.svg#custom_marker',
      // This marker is 20 pixels wide by 32 pixels high.
      size: new google.maps.Size(40, 40),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0)
    };

    var marker = {

      position: {
        lat: latitude,
        lng: longitude,
      },
      options: {
        animation: null,
        icon: image
      },
    }

    this.markers.push(marker);
  }


  markerClick(marker: any) {
    if (marker && marker.position) {

      if (this.currentActiveMarker) {
        var inActiveMarkerIndex = this.markers.findIndex((x: any) => x.position !== null && x.position.lat == this.currentActiveMarker.position.lat && x.position.lng == this.currentActiveMarker.position.lng)
        if (inActiveMarkerIndex != -1) {
          this.markers.splice(inActiveMarkerIndex, 1);
          this.makeInActiveMarker(this.currentActiveMarker.position.lat, this.currentActiveMarker.position.lng);
        }
      }

      var i = this.markers.findIndex((x: any) => x.position !== null && x.position.lat == marker.position.lat && x.position.lng == marker.position.lng)
      if (i != -1) {

        this.currentActiveMarker = marker;
        this.markers.splice(i, 1);
      }
      this.makeActiveMarker(marker.position.lat, marker.position.lng);
      this.center = {
        lat: marker.position.lat,
        lng: marker.position.lng,
      }

      setTimeout(() => {
        this.scroller.scrollToAnchor(marker.position.lat + "-" + marker.position.lng);
      })
    }
  }
  changeTab(tabIndex: number, recommendationTypeID: number) {
    this.activeTab = tabIndex;
    this.getPropertyRecommendations(recommendationTypeID);
  }

  GetRating(rating: number) {
    var ratingHtml = "";
    var isRatingWithWholeNumber = this.IsRatingWithWholeNumber(rating);

    for (let i = 0; i < Math.floor(rating); i++) {
      ratingHtml += '<i class="fa-solid fa-star"></i>';
    }

    if (!isRatingWithWholeNumber) {
      ratingHtml += '<i class="fa-solid fa-star-half-stroke"></i>';
    }
    return ratingHtml;
  }

  IsRatingWithWholeNumber(value: number) {
    return (value - Math.floor(value)) === 0;
  }

  setCustomScrollBar() {
    setTimeout(() => {
      $("#tabs").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    });
  }

  setRecommendationCustomScrollBar() {
    setTimeout(() => {
      $("#recommendations-map-view").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    });
  }


}
