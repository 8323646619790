import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SurveyComponent } from './survey.component';
import { SharedModule } from '../shared';
import { SurveyRoutingModule } from './survey-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SurveyRoutingModule
  ],
  declarations: [
    SurveyComponent
  ],
  providers: [    
  ]
})
export class SurveyModule {}
