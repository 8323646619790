import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyAccessComponent } from './property-access.component';
import { SharedModule } from '../shared';
import { PropertyAccessRoutingModule } from './property-access-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyAccessRoutingModule
  ],
  declarations: [
    PropertyAccessComponent
  ],
  providers: [    
  ]
})
export class PropertyAccessModule {}
