
<div class="login-page-box">
    <div class="container">
      <!-- <div class="logo-circle"><img src={{currentOperator.profilePic}} alt="logo" /></div> -->
      <h1 class="mb-5 mt-5 page-heading">Login to view stay details</h1>
      <form [formGroup]="authForm" (ngSubmit)="submitForm()">

        <div class="login-group">
          <div class="form-group d-block mt-0">
            <label class="form-label">Email</label>
            <input type="text" formControlName="email" placeholder="Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors['required']">Email is required</div>
              <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-group d-block mt-4">
            <label class="form-label">Password</label>
            <div class="eye-password">
              <input [type]="showPassword ? 'text' : 'password'" maxlength="20" class="form-control" formControlName="password" placeholder="Password"  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off"/>
              <span>
                <i [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" (click)="toggleShowPassword()"></i>
              </span>
            </div>
            
            
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors['required']">Password is required</div>
            </div>
            <div class="d-block text-end mt-3">
              <a routerLink="/forgot-password">Forgot Password?</a>
            </div>
          </div>
        </div>
        <div class="form-group">
          <a routerLink="/sign-in-support"><strong style="color:#809DA7">Need Help?</strong></a>
        </div>
        <div class="form-group mt-5">
          <button class="btn btn-primary w-100">Sign in</button>
        </div>
        <div class="text-center mt-5">New guest? <a routerLink="/" class="n-heading">Get Started</a></div>

      </form>
    </div>
  </div>

