export interface User {
  userID:number;
  firstName:string;
  lastName:string;
  email: string;
  token: string;
  emailVerified:boolean;
  isComfortProfile:boolean;
  profilePicture: string;
  phoneList:any;
}

export interface UserProfileImageAndName{
  userID: number,
  firstName: string,
  lastName: string,
  profilePicture: string
}