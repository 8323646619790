<div class="innerpage footer_space" *ngIf="propertyDetail">
    <div class="banner-with-header">
      <div class="banner-top">
        <a (click)="goToBack()" class="backbtn_banner"></a>
        <owl-carousel-o [options]="customOptions" class="sliderowl">   
          <ng-container *ngFor="let slide of propertyDetail.stayDetailItemImages">
            <ng-template carouselSlide>
              <img [src]="slide.banner" >
            </ng-template>
          </ng-container>             
          </owl-carousel-o>
      </div>
      <h1 class="page-name">{{propertyDetail.unitStayDetailName}}</h1>
    </div>
    
    <div class="container">
      <div class="videoLink" *ngIf="propertyDetail.unitStayDetailVideoUrl">
        <a [href]="propertyDetail.unitStayDetailVideoUrl" target="_blank" >Watch Video</a>  
      </div>
      <div class="sub-title" [innerHTML]="propertyDetail.unitStayDetailDescription">
       
      </div>
    </div>

    
  </div>
  <div class="error-page" *ngIf="!propertyDetail">
    <!-- <div class="container">
      <h1 class="heading-title">No record found.Try again! </h1>
    </div> -->
  </div>