import { Observable ,  BehaviorSubject } from 'rxjs';

export class SpinnerService{
    constructor(){}
    public showOrHideLoadingAnimation = new BehaviorSubject<boolean>(false);

    show(){
        this.showOrHideLoadingAnimation.next(true);
    }

    hide(){
        this.showOrHideLoadingAnimation.next(false);
    }
}