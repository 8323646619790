import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService, ConfirmDialogService, ErrorMessages, ReservationService, ReservationStatusType, SpinnerService, UserService } from '../core';
import { CustomValidators } from '../core/vailidaters/custom-validators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpStatusCode } from '@angular/common/http';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up-step3.component.html',
  styleUrls: ['./sign-up-step3.component.css']
})
export class SignUpStep3Component implements OnInit {

  submitted = false;
  phoneNumber = '';
  countryId = 0;

  signUpForm = new FormGroup(
    {
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#>\$%\^&\*])(?=.{8,})/
        )
      ]),
      deviceID: new FormControl('a', []),
      deviceType: new FormControl('web', []),
      confirmPassword: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl(''),
      countryID: new FormControl(0)
    },
    { validators: this.v.passwordMatch('password', 'confirmPassword') }
  );

  constructor(
    private v: CustomValidators,
    private commonService: CommonService,
    private spinner: SpinnerService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private reservationService: ReservationService,
    private mixpanelService: MixpanelService
  ) {

  }

  ngOnInit(): void {
    this.commonService.newGuestPhoneNumber.subscribe(
      (phone) => {
        if (phone && phone != "") {
          this.phoneNumber = phone;
        }
        else {
          this.router.navigateByUrl('/sign-up');
        }
      }
    );
    this.commonService.newGuestCountryId.subscribe(
      (countryId) => {
        this.countryId = Number(countryId);
      }
    );
    this.signUpForm.patchValue({
      phoneNumber: this.phoneNumber,
      countryID: this.countryId
    });
    this.mixpanelService.sendScreenVisitEvent("SignupAddAccountDetails")
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.signUpForm.controls;
  }

  submitForm() {

    this.submitted = true;

    //stop here if form is invalid
    if (this.signUpForm.invalid) {
      return;
    }

    this.spinner.show();
    this.userService
      .signUp(this.signUpForm.value)
      .subscribe(
        {
          next: (response) => {
            if (response.code == HttpStatusCode.Ok) {
              this.confirmReservationPopup();
            }
            else {
              this.toastr.error(ErrorMessages.InternalServerError);
            }
          },
          error: (e) => {
            this.submitted = false;
            this.spinner.hide();
            if (e.code == HttpStatusCode.Conflict) {
              this.userAlreadyExists(e.message);
            }
            else {
              this.toastr.error(ErrorMessages.InternalServerError);
            }

          },
          complete: () => this.spinner.hide()
        }

      );
  }

  userAlreadyExists(message: string) {
    this.confirmDialogService.confirmThis(message, 'Sign Up Error', 'Ok', 'Cancel', () => {
      this.router.navigateByUrl('/login');
    },
      () => {

      }
    );
  }

  confirmReservationPopup() {
    this.confirmDialogService.confirmThis('Do you have a reservation?', '', 'Yes', 'No', () => {
      this.getUserCurrentReservation();
    },
      () => {
        this.router.navigateByUrl('/profile');
      }
    );
  }

  getUserCurrentReservation() {
    this.reservationService.getUserReservations(ReservationStatusType.Upcoming, 1).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data && response.data.length > 0) {
              this.router.navigateByUrl('/home');
            }
            else {
              this.router.navigateByUrl('/reservation-not-found');
            }
          }
        },
        error: (e) => {
          this.router.navigateByUrl('/reservation-not-found');
        }
      }
    );
  }
}
