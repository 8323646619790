import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService, ReservationService, SpinnerService, UserDeviceType } from '../core';

@Component({
  selector: 'app-reservation-not-found',
  templateUrl: './reservation-not-found.component.html',
  styleUrls: ['./reservation-not-found.component.scss']
})
export class ReservationNotFoundComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private reservationService: ReservationService,
    private router: Router
    ) { 
      var shortDate = this.currentDate.getDate()+'-'+(this.currentDate.getMonth()+1)+'-'+this.currentDate.getFullYear();
      this.reservationNotFoundForm.patchValue({
        expectedReservationDate: shortDate
      })
    }

  submitted = false;
  phoneNumber = '';
  countryId = 0;
  currentDate = new Date();
  reservationNotFoundForm= new FormGroup(
    {
      reservationNumber: new FormControl('', [Validators.required]),
      hotelName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),      
      phoneNumber: new FormControl(''),
      countryId: new FormControl(0),
      expectedReservationDate: new FormControl(),
      deviceType: new FormControl(UserDeviceType[UserDeviceType.Web])

    }
  );


  ngOnInit(): void {
    this.commonService.newGuestPhoneNumber.subscribe(
      (phone)=>{
        if(phone && phone != ""){
          this.phoneNumber = phone;
        }
        else{
          this.router.navigateByUrl('/sign-up');          
        }        
      }
    );
    this.commonService.newGuestCountryId.subscribe(
      (countryId)=>{
        this.countryId = countryId;        
      }
    );
    this.reservationNotFoundForm.patchValue({
      phoneNumber: this.phoneNumber,
      countryId: Number(this.countryId)
    });
  }  

  get f() {
    return this.reservationNotFoundForm.controls;
  }

  submitForm() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.reservationNotFoundForm.invalid) {
      return;
    }

    this.reservationNotFoundForm.patchValue({
      expectedReservationDate:this.currentDate
    });
    this.spinner.show();
    this.reservationService
    .requestForSupport(this.reservationNotFoundForm.value)
    .subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok){
            this.toastr.success(response.message);
            setTimeout(() => {
              this.router.navigateByUrl('/profile');
            }, 1000);
          }
          else{
            this.toastr.error(response.message);
          }
        },
        error:(e)=>{
          this.submitted = false;
          this.spinner.hide()
          this.toastr.error(e.message);

        },
        complete:()=>this.spinner.hide()
      }
      
    );
  }
}
