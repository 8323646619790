import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GetStartedComponent } from './get-started.component';
import { SharedModule } from '../shared';
import { GetStartedRoutingModule } from './get-started-routing.module';

@NgModule({
  imports: [
    SharedModule,
    GetStartedRoutingModule
  ],
  declarations: [
    GetStartedComponent
  ],
  providers: [
  ]
})
export class GetStartedModule {}
