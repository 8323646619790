import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { PropertyEnhancementComponent } from './property-enhancement.component';
import { PropertyEnhancementRoutingModule } from './property-enhancement-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyEnhancementRoutingModule
  ],
  declarations: [
    PropertyEnhancementComponent
  ],
  providers: [    
  ]
})
export class PropertyEnhancementModule {}
