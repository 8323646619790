import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationService, ReservationStatusType, SpinnerService, User, UserService } from '../core';

@Component({
  selector: 'app-contact-host',
  templateUrl: './contact-host.component.html',
  styleUrls: ['./contact-host.component.scss']
})
export class ContactHostComponent implements OnInit {

  reservationId: number = 0;
  conciergeData: any;
  currentUser: User;
  currentReservation: any;
  conciergeDataAvailable: boolean = true;

  constructor(
    private userService: UserService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private location: Location,
    private reservationService: ReservationService
  ) { 
    if(route.snapshot.params != null && route.snapshot.params['id']){
      this.reservationId = parseInt(this.route.snapshot.params['id']);
    }    
    this.currentUser = {} as User;
  }

  

  ngOnInit(): void {
    if(this.reservationId > 0){
      this.getUserConcierge(this.reservationId);
    }
    else{
      this.getUserCurrentReservation();
    }
  }


  getUserCurrentReservation(){
    this.reservationService.getUserReservations(ReservationStatusType.Upcoming, 1).subscribe(
      {
        next: (response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            if(response.data && response.data.length > 0){
              this.getUserConcierge(response.data[0].reservationID);                      
            }  
            else{
              this.conciergeDataAvailable = false;
            }          
          }
        },
        error:(e)=>{
          this.conciergeDataAvailable = false;
        }
      }
      
    );
  }


  getUserConcierge(reservationId: number){
    this.spinner.show();
    this.userService.getUserConcierge(reservationId).subscribe(
      {
        next: (response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            this.conciergeData = response.data;                        
          }
          else{
            this.conciergeDataAvailable = false;
          }
        },
        error: (e) => {
          this.conciergeDataAvailable = false;
          this.spinner.hide()
        },
        complete: () => this.spinner.hide() 
      }
      
    );
  }

  goToBack() {
    this.location.back();
  }
}
