import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Errors, Operator, UserService, OperatorService, SpinnerService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  errors: Errors = { errors: {} };
  submitted = false;
  showPassword = false;
  authForm = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),
      password: new FormControl('', [
        Validators.required
      ]),
      'DeviceID': new FormControl('a', []),
      'DeviceType': new FormControl('web', [])
    }
  );

  constructor(
    private router: Router,
    private userService: UserService,
    private spinner: SpinnerService,
    private operatorService: OperatorService,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService
  ) {
    this.currentOperator = {} as Operator;
  }

  currentOperator: Operator;

  ngOnInit() {
    this.getToken();
    this.mixpanelService.sendScreenVisitEvent("Login")
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.authForm.controls;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;

  }

  submitForm() {
    this.submitted = true;

    if (this.authForm.invalid) {
      return;
    }

    const credentials = this.authForm.value;
    this.spinner.show();
    this.userService
      .attemptAuth(credentials)
      .subscribe(
        {
          next: (data) => {
            this.router.navigateByUrl('/home');
          },
          error: (e) => {
            this.submitted = false;
            this.spinner.hide();
            this.toastr.error(e.message);
          },
          complete: () => this.spinner.hide()
        }

      );
  }

  getToken() {
    this.operatorService.getJwtToken().subscribe({
      next: (response) => {
        if (response != null) {
          this.operatorService.currentOperator.subscribe(
            (data) => {
              this.currentOperator = data;
            },
          );
        }
      },
      error: (e) => { }
    });
  }
}
