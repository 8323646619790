<app-confirm-dialog></app-confirm-dialog>
<div class="remove-footer-space">
  <div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/sign-up" class="back-page"></a>
        <h2>Create Account</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">

      <div class="container">
          <h1 class="heading-title">To access your stay info please add a few details</h1>
          <form class="mt-5"  [formGroup]="signUpForm"  (ngSubmit)="submitForm()" autocomplete="off">
            <div class="form-group d-block mt-4">
              <label class="form-label">First Name</label>
              <input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors['required']">First Name is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Last Name</label>
              <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors['required']">Email is required</div>
                <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Password</label>
              <input type="password" class="form-control" maxlength="20" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors['required']">Password is required</div>
                <div *ngIf="f.password.errors['minlength']">Password must be at least 8 characters</div>
              </div>
              <div class="password-info">
                <p [ngClass]="{ 'text-danger': submitted && f.password.errors && f.password.errors?.pattern}">It should contain(s) a number, special character (#,!,>) and a capital letter (L)</p>              
              </div>
              
            </div>
            <div class="form-group d-block mt-4">
              <label class="form-label">Confirm Password</label>
              <input type="password" class="form-control" maxlength="20" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"  autocomplete="off"/>
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors['required']">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors['passwordMismatch']">Password and confirm not match</div>
              </div>
            </div>
            <button class="btn btn-primary w-100 mt-5">Next</button>
          </form>
      </div>
    
  </div>
</div>