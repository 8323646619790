import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class SurveyService{

    constructor(
        private apiService : ApiService
    ) {

    }

    getSurveyDetailById(reservationId:number, surveyId:number){
        return this.apiService.get(`/v5/survey/${surveyId}?reservationId=${reservationId}`)
            .pipe(map(
              response => {          
                return response;
              }
          ));
      }

    submitSurvey(formValue:any){
        return this.apiService.post('/v5/Survey/Submit', formValue)
            .pipe(map(
            response => {          
                return response;
            }
        ));
    }
}