import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckInModuleStageEnum, CommonService, CustomValidators, SpinnerService, UserService } from 'src/app/core';

@Component({
  selector: 'app-confirm-detail',
  templateUrl: './confirm-detail.component.html',
  styleUrls: ['./confirm-detail.component.scss']
})
export class ConfirmDetailComponent implements OnInit {

  @Input() startingForm: any;
  @Input() verificationStageSkip: boolean = false;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showHideSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() skipModuleStep: EventEmitter<any> = new EventEmitter<any>();
  userId: number = 0;
  submitted: boolean = false;
  countryCodes: any;
  selectedCountryCode: string ='';
  selectedCountryFlag: string ='';
  
  public personalDetailForm: FormGroup;
  checkInModuleStageEnum = CheckInModuleStageEnum;

  constructor(
    private v: CustomValidators,
    private commonService: CommonService,
    private spinner: SpinnerService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { 
     this.personalDetailForm = this.fb.group({
      title: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),  
      emailVerified: new FormControl(false),
      address: new FormControl(''),
      isComfortProfile: new FormControl(false), 
      userID:new FormControl(0,[Validators.required]),
      phoneList: this.fb.array([])
  })
  }

  get f() {
    return this.personalDetailForm.controls;
  }
  

  ngOnInit(): void {
    if (this.startingForm) {
      this.getCountryCodes(false, this.startingForm);
    } 
    else{
      this.getCountryCodes(true, null);
    }
    
    this.subformInitialized.emit(this.personalDetailForm);
    
  }

  doChangeStep() {
    this.submitted = true;
    if(this.personalDetailForm.invalid){
      return;
    }
    this.changeChildStep.emit({"currentStep":"personalInfoStep", "direction":"forward"});
  }

  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }

  getCountryCodes(callProfile:boolean, startingForm: any){
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;
            if(callProfile){
              this.getUserProfile();
            }
            else if(startingForm){
              this.personalDetailForm.patchValue({
                title: startingForm.title,
                firstName: startingForm.firstName,
                lastName: startingForm.lastName,
                email: startingForm.email,
                emailVerified: startingForm.emailVerified,
                address: startingForm.address,
                isComfortProfile: startingForm.isComfortProfile,
                userID: startingForm.userID,
                phoneList: this.addNewPhoneForm(
                  startingForm.phoneList[0].userPhoneID,
                  startingForm.phoneList[0].phone,
                  startingForm.phoneList[0].countryID,
                  startingForm.phoneList[0].countryCode,
                  startingForm.phoneList[0].phoneType,
                  startingForm.phoneList[0].phoneVerified
        
                  ),
        
              });
              this.selectedCountryCode = startingForm.phoneList[0].countryCode;
              var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == startingForm.phoneList[0].countryID);
              if(selectedCountry){
                this.selectedCountryFlag = selectedCountry.countryCodeImage;
              }
            }

          }
        },
        error:(e) =>{
          this.spinner.hide()
        },
        complete:()=>this.spinner.hide()
      }
    );
  }


  get phoneList() {
    return this.personalDetailForm.controls["phoneList"] as FormArray;
  }

  addNewPhoneForm(userPhoneId:number, phoneNumber:string, countryId:number,countryCode:string, phoneType: number, phoneVerified: boolean):any{
    
    let phoneForm = this.fb.group({
        userPhoneID: new FormControl(userPhoneId, [Validators.required]),
        phone: new FormControl(phoneNumber, [Validators.required]),
        countryID: new FormControl<number>(countryId),
        countryCode: new FormControl(countryCode),
        phoneType: new FormControl(phoneType),
        phoneVerified: new FormControl(phoneVerified),

      });
    return this.phoneList.push(phoneForm);
  }

  getUserProfile(){
    
    this.showHideSpinner.emit(true);
    this.userService.getUserProfile().subscribe({
      next:(response)=>{
        if(response.code == HttpStatusCode.Ok){
          this.personalDetailForm.patchValue({
            userID: this.userId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            address: response.data.address,
            emailVerified: response.data.emailVerified,
            isComfortProfile: response.data.isComfortProfile,
            phoneList: this.addNewPhoneForm(
                response.data.phoneList[0].userPhoneID,
                response.data.phoneList[0].phone,
                response.data.phoneList[0].countryID,
                response.data.phoneList[0].countryCode,
                response.data.phoneList[0].phoneType,
                response.data.phoneList[0].phoneVerified

                )
            
          });
          this.selectedCountryCode = response.data.phoneList[0].countryCode;

          var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == response.data.phoneList[0].countryID);
          if(selectedCountry){
            this.selectedCountryFlag = selectedCountry.countryCodeImage;
          }
        }
      },
      error: (err) => {
        this.showHideSpinner.emit(false);
        this.toastr.error(err.message);
      },
      complete:()=>{this.showHideSpinner.emit(false)}
    });
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.selectedCountryCode = selectedCountry.countryCode;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;
      }
    }
  }

  skipModule(){
    this.skipModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.Verification],"stepValue":CheckInModuleStageEnum.Verification});
  }
  
}
