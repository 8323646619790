import { Component, Inject, OnInit, inject } from '@angular/core';
import { CheckInModuleStageEnum, CheckInModuleStageEnumLabel, CommonService, ConfirmDialogService, PropertyService, RecommendationService, ReservationService, ReservationStatusEnum, ReservationStatusType, SpinnerService, SurveyType, UpsellService, User, UserService } from '../core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { MixpanelService } from '../core/services/mixpanel.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private propertyService: PropertyService,
    private reservationService: ReservationService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private upsellService: UpsellService,
    private confirmDialogService: ConfirmDialogService,
    private recommendationService: RecommendationService,
    private mixpanelService: MixpanelService
  ) {
    this.currentUser = {} as User;
    if (route.snapshot.params != null && route.snapshot.params['id']) {
      this.currentReservationId = parseInt(route.snapshot.params['id']);
    }
  }

  currentUser: User;
  propertyUnit: any;
  guestHaveReservation: boolean = true;
  currentReservation: any;
  accessPoints: any;
  currentReservationId: number = 0;
  propertyEnhancements: any;
  propertyAmenities: any;

  reservationStatusEnum = ReservationStatusEnum;
  additionalGuests: any;
  maxGuestsAllowed: number = 0;
  checkInModuleStage = CheckInModuleStageEnum;
  contentBlockingStage: string = "";
  guestVerificationStatus: string = "";
  surveryType = SurveyType;
  recommendations: any;

  ngOnInit(): void {
    this.getUserCurrentReservation();
    this.mixpanelService.sendScreenVisitEvent("Stay")
  }

  getUserCurrentReservation() {
    this.reservationService.getUserReservations(ReservationStatusType.Upcoming, 1).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data && response.data.length > 0) {
              if (!this.currentReservationId || this.currentReservationId == 0) {
                this.currentReservation = response.data[0];
              }
              else {
                this.currentReservation = response.data.find((x: any) => x.reservationID == this.currentReservationId);
              }
              if (this.currentReservation) {
                this.getUserUnits(this.currentReservation.unitID, this.currentReservation.reservationID);
                this.getPropertyEnhancements(this.currentReservation.reservationID, this.currentReservation.unitID);
                this.getGuestDetail(this.currentReservation.reservationID);
              }
              else {
                this.guestHaveReservation = false;
              }
            }
            else {
              this.guestHaveReservation = false;
            }
          }
          else {
            this.guestHaveReservation = false;
          }
        },
        error: (e) => {
          this.guestHaveReservation = false;
        }
      }

    );
  }



  getUserUnits(unitId: number, reservationId: number) {
    this.spinner.show();
    this.propertyService.getUserUnits().subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            if (response.data.units && response.data.units.length > 0) {
              var propertyUnits = response.data.units.find((x: any) => x.reservationID == reservationId && x.unitID == unitId);
              if (propertyUnits) {
                this.propertyUnit = propertyUnits;
                this.getContentBlockingStage();
                this.getPropertyAccessPoint(reservationId);
                this.getPropertyAmenities(this.currentReservation.reservationID, this.currentReservation.unitID, this.propertyUnit.propertyID);
                this.getPropertyRecommendations(this.currentReservation.unitID, this.propertyUnit.propertyID);
                this.setCustomScrollBar();
              }
            }
          }
        },
        error: (e) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        }
      }


    );
  }

  get isContentBlocked() {
    if (this.propertyUnit) {
      if (this.propertyUnit.checkInSequence && this.propertyUnit.checkInSequence.isBlocking && (this.contentBlockingStage != "" || (this.guestVerificationStatus == "submitted"))) {

        return true;
      }
    }
    return false;
  }

  getContentBlockingStage() {
    if (this.propertyUnit) {
      if (this.propertyUnit.checkInSequence && this.propertyUnit.checkInSequence.checkInModuleStatus) {
        var moduleName;
        for (let element of this.propertyUnit.checkInSequence.checkInModuleStatus) {
          moduleName = element.moduleName;
          var enumValue = parseInt(CheckInModuleStageEnum[moduleName]);
          var checkInSequenceDescription = CheckInModuleStageEnumLabel.get(enumValue);
          var moduleDetail = Object.entries(this.propertyUnit.checkInSequence).filter((key: any, value: any) => {
            return key[0] == checkInSequenceDescription
          })
          var obj: any = Object.fromEntries(moduleDetail);

          if (moduleName == this.checkInModuleStage[CheckInModuleStageEnum.Verification]) {
            this.guestVerificationStatus = obj[checkInSequenceDescription!]?.verificationStatus;
          }
          if (element.moduleStatus == null) {
            break;
          }
          else {
            moduleName = "";
          }

        }

      }

    }
    this.contentBlockingStage = moduleName;

  }

  get isShowDamageWaiverBanner() {
    if (this.propertyUnit) {
      if (this.propertyUnit.checkInSequence && this.propertyUnit.checkInSequence.verification == null) {
        if (this.propertyUnit.checkInSequence.securityTransactions && this.propertyUnit.checkInSequence.securityTransactions.status.toLowerCase() == 'incomplete') {
          return true;
        }
        else {
          return false;
        }
      }
      else if (
        this.propertyUnit.checkInSequence
        && this.propertyUnit.checkInSequence.verification.verificationStatus
        && (this.propertyUnit.checkInSequence.verification.verificationStatus.toLowerCase() == 'approved'
          || this.propertyUnit.checkInSequence.verification.verificationStatus.toLowerCase() == 'submitted')
        && this.propertyUnit.checkInSequence.securityTransactions && this.propertyUnit.checkInSequence.securityTransactions.status.toLowerCase() == 'incomplete'
      ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }

  getPropertyAccessPoint(reservationId: number) {
    //this.spinner.show();
    this.propertyService.getPropertyAccessPoint(reservationId).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.accessPoints = response.data.accessPoints;
          }
        },
        error: (e) => {
          //this.spinner.hide()
        },
        complete: () => {
          //this.spinner.hide() 
        }
      }

    );
  }

  getPropertyEnhancements(reservationId: number, unitId: number) {
    this.upsellService.getPropertyUpsells(reservationId, unitId, 1).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.propertyEnhancements = response.data.upsellItems.length > 8 ? response.data.upsellItems.slice(0, 8) : response.data.upsellItems;
            this.setCustomScrollBarEnhancement();
          }
        }
      }

    );
  }

  getPropertyAmenities(reservationId: number, unitId: number, propertyId: number) {
    this.reservationService.getAmenities(reservationId, unitId, propertyId, 1).subscribe(
      {
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data) {
            this.propertyAmenities = response.data.length > 3 ? response.data.filter((x: any) => x.disabledAmenityUnitLevelId != 1).slice(0, 4) : response.datafilter((x: any) => x.disabledAmenityUnitLevelId != 1);
            this.setCustomScrollBarAmenity();
          }
        }
      }

    );
  }

  getGuestDetail(reservationId: number) {
    this.reservationService.getGuestDetail(reservationId)
      .subscribe({
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok && response.data && response.data.additionalGuestInformation) {

            this.additionalGuests = response.data.additionalGuestInformation;
            this.maxGuestsAllowed = response.data.maxGuestsAllowed;
          }
        },
        error: (e) => {
          // this.spinner.hide();
        },
        complete: () => {
          // this.spinner.hide()
        }
      })
  }

  goToGuestDetail(additionalGuestId: number) {
    this.router.navigateByUrl(`/guest-detail/${this.currentReservation.reservationID}/${additionalGuestId}`)
  }

  addGuest() {
    if (this.maxGuestsAllowed <= this.additionalGuests.length) {
      this.confirmDialogService.alertThis("We're sorry, you have reached the maximum number of guests allowed.", 'Maximum Guests Reached', 'Ok', () => {

      });
    }
    else {
      this.router.navigateByUrl(`/guest-detail/${this.currentReservation.reservationID}/0`)
    }
  }

  getDamageDepositText() {
    if (this.propertyUnit.checkInSequence && this.propertyUnit.checkInSequence.securityTransactions) {
      if (this.propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled && this.propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled) {
        return "Deposit / Damage Waiver Needed";
      }
      else if (this.propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled) {
        return "Deposit Needed";
      }
      else if (this.propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled) {
        return "Damage Waiver Needed";
      }
    }
    return "";
  }

  setCustomScrollBar() {
    setTimeout(() => {
      $("#options-box").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });

    });
  }

  setCustomScrollBarAmenity() {
    setTimeout(() => {

      $("#horizontal-scroll-box-amenity").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    });
  }
  setCustomScrollBarEnhancement() {
    setTimeout(() => {

      $("#horizontal-scroll-box-enhancement").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    }, 2000);
  }
  setCustomScrollBarRecommendation() {
    setTimeout(() => {
      $("#horizontal-scroll-box-recommendation").mCustomScrollbar({
        axis: "x",
        advanced: {
          autoExpandHorizontalScroll: true
        }
      });
    });
  }

  getPropertyRecommendations(unitID: number, propertyID: number) {
    this.recommendationService.getPropertyRecommendations(propertyID, unitID, -1).subscribe(
      {
        next: (response) => {
          if (response.code == HttpStatusCode.Ok && response.data) {
            this.recommendations = response.data.slice(0, 8);;
            this.setCustomScrollBarRecommendation();
          }
          else {
            this.recommendations = [];
          }
        },
        error: (e) => {
          this.recommendations = [];
        }
      }
    )
  }

  GetRating(rating: number) {
    var ratingHtml = "";
    var isRatingWithWholeNumber = this.IsRatingWithWholeNumber(rating);

    for (let i = 0; i < Math.floor(rating); i++) {
      ratingHtml += '<i class="fa-solid fa-star"></i>';
    }

    if (!isRatingWithWholeNumber) {
      ratingHtml += '<i class="fa-solid fa-star-half-stroke"></i>';
    }
    return ratingHtml;
  }

  IsRatingWithWholeNumber(value: number) {
    return (value - Math.floor(value)) === 0;
  }
}
