import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService, CustomValidators, SpinnerService, UserService } from '../core';

@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.component.html',
  styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailComponent implements OnInit {

  userId: number = 0;
  submitted: boolean = false;
  countryCodes: any;
  selectedCountryCode: string ='';
  selectedCountryFlag: string ='';

  personalDetailForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),  
      emailVerified: new FormControl(false),
      address: new FormControl(''),
      isComfortProfile: new FormControl(false), 
      userID:new FormControl(0,[Validators.required]),
      phoneList: this.fb.array([])
  })


  constructor(
    private v: CustomValidators,
    private commonService: CommonService,
    private spinner: SpinnerService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }

  get f() {
    return this.personalDetailForm.controls;
  }

  ngOnInit(): void {
    this.getCountryCodes();
  }

  getCountryCodes(){
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;
            this.getUserProfile();

          }
        },
        error:(e) =>{
          this.spinner.hide()
        },
        complete:()=>this.spinner.hide()
      }
    );
  }


  get phoneList() {
    return this.personalDetailForm.controls["phoneList"] as FormArray;
  }

  addNewPhoneForm(userPhoneId:number, phoneNumber:string, countryId:number, phoneType: number, phoneVerified: boolean):any{
    
    let phoneForm = this.fb.group({
        userPhoneID: new FormControl(userPhoneId, [Validators.required]),
        phone: new FormControl(phoneNumber, [Validators.required]),
        countryID: new FormControl<number>(countryId),
        phoneType: new FormControl(phoneType),
        phoneVerified: new FormControl(phoneVerified),

      });
    return this.phoneList.push(phoneForm);
  }

  getUserProfile(){
    this.spinner.show();
    this.userService.getUserProfile().subscribe({
      next:(response)=>{
        if(response.code == HttpStatusCode.Ok){
          this.personalDetailForm.patchValue({
            userID: this.userId,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            address: response.data.address,
            emailVerified: response.data.emailVerified,
            isComfortProfile: response.data.isComfortProfile,
            phoneList: this.addNewPhoneForm(
                response.data.phoneList[0].userPhoneID,
                response.data.phoneList[0].phone,
                response.data.phoneList[0].countryID,
                response.data.phoneList[0].phoneType,
                response.data.phoneList[0].phoneVerified

                )
            
          });
          this.selectedCountryCode = response.data.phoneList[0].countryCode;

          var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == response.data.phoneList[0].countryID);
          if(selectedCountry){
            this.selectedCountryFlag = selectedCountry.countryCodeImage;
          }
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      },
      complete:()=>{this.spinner.hide()}
    });
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.selectedCountryCode = selectedCountry.countryCode;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;
      }
    }
  }

  submitForm() {

    this.submitted = true;
    
    // stop here if form is invalid
    if (this.personalDetailForm.invalid) {
      return;
    }
    this.spinner.show();
    this.userService
    .updateUserProfile(this.personalDetailForm.value)
    .subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok){
            this.toastr.success("Record updated successfully.");
            setTimeout(()=>{
              this.router.navigateByUrl('/profile');            
            },1000);
          }
          else{
            this.toastr.error(response.message);

          }
        },
        error:(e)=>{
          this.submitted = false;
          this.spinner.hide();
          this.toastr.error(e.message);

        },
        complete:()=>this.spinner.hide()
      }
      
    );
  }
}
