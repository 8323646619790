import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { RecommendationsComponent } from './recommendations.component';
import { RecommendationsRoutingModule } from './recommendations-routing.module';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [
    SharedModule,
    RecommendationsRoutingModule,
    GoogleMapsModule
  ],
  declarations: [
    RecommendationsComponent
  ],
  providers: [    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class RecommendationsModule {}
