<div class="welcome-section footer_space">
    <div class="container" style="    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;">
      <div class="welcome-box">
        <!-- <div class="logo-circle mb-4"><img src={{currentOperator.profilePic}} alt="" /></div> -->
        <h1 class="heading-title mb-4 color-white text-center">To access your stay info please add a few details</h1>
        <a routerLink="/sign-up" class="btn btn-white w-100">Get Started</a>
      </div>
      <div class="text-center mt-5">Already registered? <a routerLink="/login" class="n-heading">Login</a></div>
    </div>
    <div class="footer">
      <div class="download-app">
        <h3>For the best experience download our mobile app</h3>
        <a href="https://dackinc.app.link/download" target="_blank" class="btn-download">Download</a>
      </div>
    </div>
    
  </div>