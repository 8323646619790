import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password.component';
import { SharedModule } from '../shared';
import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';

@NgModule({
  imports: [
    SharedModule,
    ForgotPasswordRoutingModule
  ],
  declarations: [
    ForgotPasswordComponent
  ],
  providers: [
  ]
})
export class ForgotPasswordModule {}
