<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        
        <a href="javascript:void(0)" class="help_icon" (click)="showCommonTermsCondition()"></a>
        <h2>Guest Verification</h2>
        <a class="skip" (click)="skipModule()" *ngIf="verificationStageSkip">SKIP</a>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
  
      <div class="container">

          <h4 class="heading-title">Please add your mailing address</h4>

          <form class="form-flex" [formGroup]="addressInfoForm" autocomplete="off">
            <div class="form-group d-block mt-4">
              <input type="text" class="form-control" placeholder="Address Line 1" formControlName="addressLine1" [ngClass]="{ 'is-invalid': submitted && f.addressLine1.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.addressLine1.errors" class="invalid-feedback">
                    <div *ngIf="f.addressLine1.errors['required']">Address Line1 is required</div>
                </div> 
            </div>
            <div class="form-group d-block mt-4">
              <input type="text" class="form-control" placeholder="Address Line 2" formControlName="addressLine2" [ngClass]="{ 'is-invalid': submitted && f.addressLine2.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.addressLine2.errors" class="invalid-feedback">
                  <div *ngIf="f.addressLine2.errors['required']">Address Line2 is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <input type="text" class="form-control" placeholder="Zip Code" formControlName="zipCode" [ngClass]="{ 'is-invalid': submitted && f.zipCode.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.zipCode.errors" class="invalid-feedback">
                  <div *ngIf="f.zipCode.errors['required']">Zip Code is required</div>
              </div>
            </div>
            <div class="form-group d-block mt-4">
              <input type="text" class="form-control" placeholder="City"  formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                  <div *ngIf="f.city.errors['required']">City is required</div>
              </div>
            </div>
            <!-- <div class="form-group d-block mt-4">
              <input type="text" class="form-control" placeholder="State Abbreviation" formControlName="stateAbbreviation" [ngClass]="{ 'is-invalid': submitted && f.stateAbbreviation.errors }" autocomplete="off" />
              <div *ngIf="submitted && f.stateAbbreviation.errors" class="invalid-feedback">
                  <div *ngIf="f.stateAbbreviation.errors['required']">State Abbreviationis required</div>
              </div>
            </div> -->
            <div class="form-group d-block mt-4">
              <div class="with-country-code">
                <span class="custom-select">                                
                    <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                    <input type="text" value="{{selectedCountryCode}}" class="selectform_control" />
                    <select class="form-control" formControlName="country" (change)="onCountryChange($event)">
                        <option *ngFor="let country of countryCodes" [ngValue]="country.countryID" >{{country.countryName}}</option>
                    </select>
                </span>
              </div>             
            </div>
            <div class="form-group mt-5">
              <button type="button" class="btn btn-primary w-100" (click)="doChangeStep()">Next</button>
            </div>
          </form>
      </div>
    
  </div>
