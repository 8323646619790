<ng-container *ngIf="propertyEnhancements">
  <div style="z-index: 1000;position: sticky !important;    top: 0;    background: #EAEAEA;">
    <div class="title-bar">

      <div class="container">
        <div class="title-bar-box">
          <a routerLink="/home/{{reservationId}}" class="back-page"></a>
          <h2>Enhance Your Stay</h2>
        </div>
      </div>
    </div>
    <div class="container pb-0 pt-3">
      <div class="tabs mb-2" id="tabs">
        <ul role="tablist" class="nav-tabs nav">
          <!-- <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#all">All</a></li> -->
  
          <li class="nav-item" *ngFor="let category of upsellCategories;let i = index;">                   
            <a [className]="activeTab == i? 'nav-link active' : 'nav-link'" (click)="changeTab(i, category.upsellCategoryId)" data-bs-toggle="tab" data-target="#{{i}}">{{category.upsellCategoryName}}</a>
          </li>                  
        </ul>
      </div>
    </div>
  </div>
  
  <div class="title-bar-space d-block">
    <!-- <div class="container enhancements" 
      infinite-scroll
      [infiniteScrollDistance]="distance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      > -->
    <div class="container pt-0 enhancements" style="height:auto; overflow: visible;">
      
      <div class="linkBox" *ngFor="let upsell of propertyEnhancements">
        
        <a routerLink="/property-enhancement/{{reservationId}}/{{unitId}}/{{upsell.itemID}}" class="anchor_cover" *ngIf="upsell.itemID != null && upsell.externalLink == null"></a>
        <a href="{{upsell.externalLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID != null && upsell.externalLink != null"></a>
        <a href="{{upsell.externalLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID == null && upsell.externalLink != null"></a>
        <a href="{{upsell.androidLink}}" target="_blank" class="anchor_cover" *ngIf="upsell.itemID == null && upsell.externalLink == null && upsell.androidLink"></a>
        <div class="linkBox_img"><img [src]="upsell.image" alt=""/></div>
        <div class="linkBox_content">
          <strong>{{upsell.itemName}}</strong>
          <label *ngIf="upsell.itemID != null && upsell.price">{{upsell.price.displayPrice}}</label>
        </div>
      </div>
        
    </div>
  </div> 
</ng-container> 
  <ng-container *ngIf="!isPropertyHaveUpsells">
    <div class="v_flexbxx notfound_container">
      <div class="container">
        <div class="notfound_box">
          <div class="notfound_icon text-center">
            <img src="../../assets/images/upsells-empty.png" alt=""/>
          </div>
    
          <h3 class="mb-3">No Enhancements Found</h3>
          <p class="mb-0">Your operator doesn't offer any stay enhancements at this time </p>
        </div>
      </div>
    </div>
  </ng-container>     
    