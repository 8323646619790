import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PropertyAmenityComponent } from './property-amenity.component';
import { SharedModule } from '../shared';
import { PropertyAmenityRoutingModule } from './property-amenity-routing.module';

@NgModule({
  imports: [
    SharedModule,
    PropertyAmenityRoutingModule
  ],
  declarations: [
    PropertyAmenityComponent
  ],
  providers: [
  ]
})
export class PropertyAmenityModule {}
