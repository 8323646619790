export enum CheckInModuleStageEnum{
    Verification,
    SecurityTransactions,
    ArrivalTime,
    AdditionalGuests
}

export const CheckInModuleStageEnumLabel = new Map<number, string>([
    [CheckInModuleStageEnum.Verification, 'verification'],
    [CheckInModuleStageEnum.SecurityTransactions, 'securityTransactions'],
    [CheckInModuleStageEnum.ArrivalTime, 'arrivalTime'],
    [CheckInModuleStageEnum.AdditionalGuests, 'additionalGuests']
  ]);
  