<div class="title-bar">
    <div class="container">
      <div class="title-bar-box textlinks">
        <a (click)="updatePaymentMethodToForm()">Cancel</a>
        <a href="javascript:void(0)">Edit</a>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block">
    
    <div class="paymenttitletop">Payment Method</div>
  
      <ng-container *ngIf="isShowAddNewCard">
        <div class="container">
          <form  [formGroup]="cardForm" (ngSubmit)="addNewCard()" autocomplete="off">
            <!-- <div class="form-group d-block mt-5">
                <label class="form-label">Email</label>
                <input type="text" class="form-control"/>
            </div> -->
            <div class="form-group d-block mt-4">
              <label class="form-label">Card information</label>
              <div class="row">
                <div class="col-md-12">
                  <input type="text" class="form-control" formControlName="cardNumber" numbersOnly [maxlength]="16" placeholder="Card Number" [ngClass]="{ 'is-invalid': cardSubmit && f.cardNumber.errors }" autocomplete="off"/>
                  <div *ngIf="cardSubmit && f.cardNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.cardNumber.errors['required']">Card Number is required</div>
                  </div>
                </div>
                <div class="col mt-2">
                  <input type="text" class="form-control" formControlName="expMonth" numbersOnly [maxlength]="2" placeholder="MM" [ngClass]="{ 'is-invalid': cardSubmit && f.expMonth.errors }" autocomplete="off"/>
                  <div *ngIf="cardSubmit && f.expMonth.errors" class="invalid-feedback">
                      <div *ngIf="f.expMonth.errors['required']">Expiry Month is required</div>
                  </div>
                </div>
                <div class="col mt-2">
                  <input type="text" class="form-control" formControlName="expYear" numbersOnly [maxLength]="4" placeholder="YYYY" [ngClass]="{ 'is-invalid': cardSubmit && f.expYear.errors }" autocomplete="off"/>
                  <div *ngIf="cardSubmit && f.expYear.errors" class="invalid-feedback">
                      <div *ngIf="f.expYear.errors['required']">Expiry Year is required</div>
                  </div>
                </div>
                <div class="col mt-2">
                  <input type="text" class="form-control" formControlName="cvc" numbersOnly maxlength="4" placeholder="CVV" [ngClass]="{ 'is-invalid': cardSubmit && f.cvc.errors }" autocomplete="off"/>
                  <div *ngIf="cardSubmit && f.cvc.errors" class="invalid-feedback">
                      <div *ngIf="f.cvc.errors['required']">Cvc is required</div>
                  </div>
                </div>
              </div>
              
            </div>
            <!-- <div class="form-group d-block mt-4">
              <label class="form-label">Name on card</label>
              <input type="text" class="form-control"/>
            </div> -->
            <button class="btn btn-primary w-100 mt-4">Add Card</button>
          </form>
        </div>
        
      </ng-container>
      <ng-container *ngIf="!isShowAddNewCard">
        <div class="d-block text-center mt-5 mb-5">
            <img src="assets/images/card_icon.svg" alt="icon"/>
          </div>
   
          <div class="paymentoptionss">
            <!-- <div class="poBox">
             <input type="radio" name="card" id="applepay"/>
              <label for="applepay">
               <img src="assets/images/gpay.svg" alt="icon"/>
                <span>Apple Pay</span>
              </label>
            </div> -->
   
   
            <div class="poBox" *ngFor="let methodItem of paymentMethods">
             <!-- <input type="radio" name="card" id="visa" checked="" /> -->
              <label for="visa" (click)="selectStripePaymentMethod(methodItem.paymentMethodKey, methodItem.lastDigits, methodItem.paymentMethodBrand)">
               <img src="../../assets/images/visa.svg" alt="icon"/>
                <strong>{{methodItem.paymentMethodBrand}}</strong> <span> &nbsp;ending in</span> <strong> &nbsp;{{methodItem.lastDigits}}</strong>
              </label>
            </div>
   
            <div class="poBox mt-4 addnewcardtext">             
              <label (click)="showAddNewCard(true)">
               <img src="../../assets/images/plusicon.svg" alt="icon"/>
                <span>Add New Card...</span>
              </label>
            </div>
          </div>
               
      </ng-container>
       
  </div>

