import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class CheckInService{

    constructor(
        private apiService : ApiService
    ) {

    }

    skipModule(formValue:any){
        return this.apiService.post('/v1/checkin/skipmodule', formValue)
          .pipe(map(
            response => {          
              return response;
            }
        ));
    }

    getArrivalTimeInfo(reservationId:number){
      return this.apiService.get('/v1/checkin/ArrivalTimeInfo?reservationId='+ reservationId)
          .pipe(map(
            response => {          
              return response;
            }
        ));
    }

    selectArrivalTime(formRequest:any){
      return this.apiService.post('/v1/checkin/SelectArrivalTime', formRequest)
          .pipe(map(
            response => {
              return response;
            }
          ))
    }

    abandonCart(formRequest:any){
      return this.apiService.post('/v1/checkin/AbandonCart', formRequest)
          .pipe(map(
            response => {
              return response;
            }
          ))
    }

    
    getCheckInSummary(reservationId:number){
      return this.apiService.get('/v1/checkin/CheckInSummary?reservationId='+ reservationId)
          .pipe(map(
            response => {          
              return response;
            }
        ));
    }

    earlyCheckInRequest(request:any){
      return this.apiService.post("/v1/checkin/completeCheckIn", request)
      .pipe(map(
          response => {
              return response;
          }
      ))
    }

    completeAdditionalGuest(request:any){
      return this.apiService.post("/v1/checkin/CompleteAdditionalGuest", request)
      .pipe(map(
          response => {
              return response;
          }
      ))
    }
}