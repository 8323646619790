<app-confirm-dialog></app-confirm-dialog>
<ng-container [ngSwitch]="moduleCurrentStep" *ngIf="initialModuleName">
    <app-verification *ngSwitchCase="'Verification'"      
      [reservationId]="reservationId"      
      [unitId]="unitId" 
      [verificationStageSkip]="verificationStageSkip"    
      (skipParentModuleStep)="skipModule($event)" 
    ></app-verification>

    <app-damage-waiver *ngSwitchCase="'SecurityTransactions'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      [securityTransactionStageSkip]="securityTransactionStageSkip" 
      (skipParentModuleStep)="skipModule($event)"  
      (getPreviousCheckInSequence)="getPreviousCheckInSequence()"  
    ></app-damage-waiver>

    <app-arrival-time *ngSwitchCase="'ArrivalTime'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      [arrivalTimeStageSkip]="arrivalTimeStageSkip"  
      [checkInForm]="checkInForm"  
      (skipParentModuleStep)="skipModule($event)" 
      (subformInitialized)="subformInitialized('arrivalInfo', $event)"
      (getPreviousCheckInSequence)="getPreviousCheckInSequence()"  
    ></app-arrival-time>

    <app-additional-guests *ngSwitchCase="'AdditionalGuests'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      [additionalGuestStageSkip]="additionalGuestStageSkip"    
      (skipParentModuleStep)="skipModule($event)" 
      (getPreviousCheckInSequence)="getPreviousCheckInSequence()"  
    ></app-additional-guests>
    <app-arrival-complete *ngSwitchCase="'ArrivalComplete'"      
      [reservationId]="reservationId"      
      [unitId]="unitId"  
      (getPreviousCheckInSequence)="getPreviousCheckInSequence()"  
    ></app-arrival-complete>

</ng-container>