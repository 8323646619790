import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages, UpsellService } from '../core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() selectedPaymentMethod:any = { paymentMethodKey:'', lastDigits:'', brand:''};

  @Output() getPaymentMethod: EventEmitter<any> = new EventEmitter<any>()
  cardSubmit:boolean = false;
  isShowAddNewCard:boolean = false;
  paymentMethods:any;
  cardForm= new FormGroup({
    cardNumber: new FormControl('',Validators.required),
    expMonth: new FormControl('',Validators.required),
    expYear: new FormControl('',Validators.required),
    cvc: new FormControl('',Validators.required)
  })
  constructor(
    private upsellService: UpsellService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getStripePaymentMethods();
  }

  get f(){
    return this.cardForm.controls;
  }
  getStripePaymentMethods(){
    this.upsellService.getStripePaymentMethods().subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok && response.data){
            this.paymentMethods = response.data;
          }
        }
      }
    )
  }

  updatePaymentMethodToForm(){
    this.getPaymentMethod.emit(this.selectedPaymentMethod);
  }
  selectStripePaymentMethod(paymentMethodKey: string, cardLastDigits: string, paymentMethodBrand: string){
    this.selectedPaymentMethod = {
      paymentMethodKey: paymentMethodKey,
      lastDigits: cardLastDigits,
      brand: paymentMethodBrand
    };

    this.updatePaymentMethodToForm();
    // this.isShowPaymentDetail = false;
    // this.isShowAddNewCard = false;
    // this.isShowPaymentMethods = false;
    // this.isPaymentMethodSelected = true;
    // this.purchaseForm.patchValue({
    //   paymentMethodKey : paymentMethodKey
    // });
    // this.selectedCardLastDigits = cardLastDigits;
    // this.selectedPaymentMethodBrand = paymentMethodBrand;
  }

  addNewCard(){

    this.cardSubmit = true;
    if(this.cardForm.invalid){
      return;
    }

    this.upsellService.addPaymentMethod(this.cardForm.value).subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok && response.data){
            this.getStripePaymentMethods();
            this.isShowAddNewCard = false;
            // this.purchaseForm.patchValue({
            //   paymentMethodKey : response.data
            // });
            // this.isShowAddNewCard = false;
            // this.isShowPaymentMethods = false;
            // this.isPaymentMethodSelected = true;
            // this.showPaymentDetail(false);
    
          }
          else{
            this.toastr.warning("Card details are not valid.");
          }
        },
        error:(e)=>{
          this.toastr.error(ErrorMessages.InternalServerError);
        }
      }
    )
  }

  showAddNewCard(isShow:boolean){
    this.isShowAddNewCard = isShow;
  }
}
