import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService, ErrorMessages, ReservationService, SpinnerService, UserService } from '../core';

@Component({
  selector: 'app-guest-detail',
  templateUrl: './guest-detail.component.html',
  styleUrls: ['./guest-detail.component.scss']
})
export class GuestDetailComponent implements OnInit {

  reservationId:number = 0;
  reservationAdditionalGuestId:number = 0;
  submitted: boolean = false;
  countryCodes: any;
  selectedCountryCode: string ='';
  selectedCountryFlag: string ='';
  primaryGuest: boolean = false;
  guests:any;
  guest:any;


  guestForm = this.fb.group({
    reservationId: new FormControl(0, [Validators.required]),
    reservationAdditionalGuestID: new FormControl(0, [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/)]),  
    phoneNumber: new FormControl('', [Validators.required, Validators.minLength(5)]),
    countryId: new FormControl<number>(0),
    countryCode: new FormControl('')
  })

  constructor(
    private reservationService: ReservationService,
    private userService: UserService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private router: Router
  ) { 
    this.reservationId = parseInt(this.route.snapshot.params['rid']);
    this.reservationAdditionalGuestId = parseInt(this.route.snapshot.params['aid']);
  }

  get f() {
    return this.guestForm.controls;
  }

  ngOnInit(): void {
    this.getCountryCodes();
    this.getGuestDetail();
  }

  getCountryCodes(){
    this.userService.getCountryCode().subscribe(
      {
        next:(data)=>{
          if(data){
            this.countryCodes = data;

            if(this.countryCodes){
              var selectedCountry = this.countryCodes[0];
              if(selectedCountry){
                this.selectedCountryFlag = selectedCountry.countryCodeImage;
                this.selectedCountryCode = selectedCountry.countryCode;
                if(this.reservationAdditionalGuestId == 0){
                  this.guestForm.patchValue({
                    countryId: selectedCountry.countryID,
                    countryCode: selectedCountry.countryCode,
                  });
                }
              }
            }
            
          }
        },
        error:(e) =>{
          this.spinner.hide()
        },
        complete:()=>this.spinner.hide()
      }
    );
  }

  getGuestDetail(){
    this.spinner.show();

    this.reservationService.getGuestDetail(this.reservationId)
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok && response.data){
              this.guestForm.patchValue({
                reservationId: this.reservationId,
                reservationAdditionalGuestID: this.reservationAdditionalGuestId
              });
              this.guests = response.data;
              if(this.reservationAdditionalGuestId > 0){
                
                this.guest = this.guests.additionalGuestInformation.find((x:any) => x.reservationAdditionalGuestID == this.reservationAdditionalGuestId);
                if(this.guest){

                  this.primaryGuest = this.guest.primaryGuest;

                  this.guestForm.patchValue({
                    firstName: this.guest.firstName,
                    lastName: this.guest.lastName,
                    email: this.guest.email,
                    countryId: this.guest.countryId,
                    phoneNumber: this.guest.phoneNumber,
                    countryCode: this.guest.countryCode,
                  });

                  this.selectedCountryCode = this.guest.countryCode;

                  var selectedCountry = this.countryCodes.find((x:any) =>x.countryCode == this.guest.countryCode);
                  if(selectedCountry){
                    this.selectedCountryFlag = selectedCountry.countryCodeImage;
                  }
                }
                
              }
            }
          },
          error:(e) => { 
            this.spinner.hide();
          },
          complete:() => this.spinner.hide()
        })
  }

  onCountryChange(event:any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[1]
    if(this.countryCodes && this.countryCodes.length > 0){
      var selectedCountry = this.countryCodes.find((x:any) =>x.countryID == changedValue);
      if(selectedCountry){
        this.selectedCountryCode = selectedCountry.countryCode;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;

        this.guestForm.patchValue({
          countryCode: this.selectedCountryCode
        });
      }
    }
  }

  submitForm(){

    this.submitted = true;
   
    if(this.guestForm.invalid){
      if(this.guestForm.controls.phoneNumber?.errors?.['minlength']){
        this.confirmDialogService.alertThis('Please enter valid mobile number', '', 'Ok', () => { });
      }
      return;
    }

    if(this.reservationAdditionalGuestId == 0 && (this.guests.maxGuestsAllowed <= this.guests.additionalGuestInformation.length)){
      this.confirmDialogService.alertThis("We're sorry, you have reached the maximum number of guests allowed.", 'Maximum Guests Reached', 'Ok', () =>  {
        
      });
      return;
    }

    this.spinner.show();
    if(this.reservationAdditionalGuestId == 0){
      this.addGuest();
    }
    else{
      this.updateGuest();
    }
    
  }

  addGuest(){
    this.reservationService.addAdditionalGuest(this.guestForm.value)
    .subscribe({
      next:(response) => {
        if(response && response.code == HttpStatusCode.Ok && response.data){
          this.toastr.info("Guest added successfully.");
          setTimeout(()=>{
            this.router.navigateByUrl(`/home/${this.reservationId}`)
          },2000);
        }
        else{
          this.toastr.error(ErrorMessages.InternalServerError);
        }
      },
      error:(e) => {
        this.spinner.hide();
        this.toastr.error(e.message);
      },
      complete:() => this.spinner.hide()
    })
  }

  updateGuest(){
    this.reservationService.updateAdditionalGuest(this.guestForm.value)
    .subscribe({
      next:(response) => {
        if(response && response.code == HttpStatusCode.Ok){
          this.toastr.info("Guest updated successfully.");
          setTimeout(()=>{
            this.router.navigateByUrl(`/home/${this.reservationId}`)
          },2000);
        }
        else{
          this.toastr.error(ErrorMessages.InternalServerError);
        }
      },
      error:(e) => {
        this.spinner.hide();
        this.toastr.error(e.message);
      },
      complete:() => this.spinner.hide()
    })
  }

  removeAdditionalGuest(){
    this.spinner.show();

    this.reservationService.removeAdditionalGuest({ reservationAdditionalGuestID: this.reservationAdditionalGuestId, reservationID: this.reservationId })
        .subscribe({
          next: (response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.router.navigateByUrl(`/home/${this.reservationId}`);
            }
          },
          error: (e) => {
            this.toastr.error(e.message);
            this.spinner.hide();
          },
          complete: () => this.spinner.hide()
        })
  }
}
