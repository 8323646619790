<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a (click)="goToBack()" class="back-page"></a>
        <h2>Arrival Time</h2>
        <a class="skip" (click)="skipModule()" *ngIf="arrivalTimeStageSkip">SKIP</a>
      </div>
    </div>
  </div>
  <div class="title-bar-space d-block">
    <div class="container">
        <h2 class="mt-2">When do you think you'll arrive at the property? </h2>
        <div class="mt-3 sub-title">
            Your host may decline early check-in requests. 
        </div>
        <div class="mt-4"> 
          <div class="options-box" id="hoursTab">
            <div [className]="(activeTab == i && tabSelected) || (selectedArrivalTimeId == hoursAvailable.arrivalTimeId)? 'ob-box time-scheduled active' : 'ob-box time-scheduled'" *ngFor="let hoursAvailable of arrivalTimeInfo;let i = index">
              <a href="javascript:void(0)" (click)="changeTab(i, hoursAvailable.arrivalTimeId, hoursAvailable.subLabel)">
                <p><b>{{hoursAvailable.label}} </b>{{hoursAvailable.subLabel}}</p>
              </a>
            </div>                
          </div>
        </div>
    </div>
    <div class="container">
      <button type="button" class="btn btn-primary w-100" (click)="selectArrivalTime()">Select</button>
  
    </div>
  </div>
  