import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonApiService, ConfirmDialogService, ErrorMessages, PropertyService, ReservationService, SpinnerService, UserService } from '../core';

type Step = 'findReservation' | 'matchReservation' | 'resultReservation';

@Component({
  selector: 'app-pair-reservation',
  templateUrl: './pair-reservation.component.html',
  styleUrls: ['./pair-reservation.component.scss']
})

export class PairReservationComponent implements OnInit {

  reservationId: number = 0;
  private currentStepBs: BehaviorSubject<Step> = new BehaviorSubject<Step>('findReservation');
  public currentStep$: Observable<Step> = this.currentStepBs.asObservable().pipe();

  public currentStep:string ='';
  public userForm: FormGroup;
  private userDetail: any;
  public maskedMobileNumber:string ="";
  constructor(
    private _fb: FormBuilder,
    private route:ActivatedRoute,
    private spinner: SpinnerService,
    private commonApiService: CommonApiService,
    private reservationService: ReservationService,
    private router: Router,
    private propertyService: PropertyService,
    private confirmDialogService: ConfirmDialogService,
    private toastr: ToastrService,
    private userService: UserService
  ) { 
    this.userForm = this._fb.group({
      findReservation: null,
      matchReservation: null
      
    });
  }

  ngOnInit(): void {
    this.currentStep$.subscribe(
      
      (currentStep)=>{
        this.currentStep = currentStep;
      }
    
    )
  }

  changeStep(request: any) {
    //currentStep: string, direction: 'forward' | 'back'
    var currentStep= request.currentStep;
    var direction= request.direction;
  
      switch(currentStep) {
        case 'findReservationStep':
          if (direction === 'forward') {
            this.maskedMobileNumber = request.maskedMobileNumber
            this.currentStepBs.next('matchReservation');
          }
          break;
        case 'matchReservationStep':
          if (direction === 'back') {
            this.currentStepBs.next('findReservation');
          }
          else if (direction === 'forward'){
            this.currentStepBs.next('resultReservation');
          }
        
      }
    }


    subformInitialized(name:string, formGroup: FormGroup) {
    
      setTimeout(() => {
        this.userForm.setControl(name, formGroup);
      }, );    
      
    }

    submitForm(){
      var userFormValue = this.userForm.value;
      this.userService.getCurrentUser().subscribe({
        next:(response:any)=>{
          this.userDetail = response
        }
      })

      var modelRequest = {
        LastName: userFormValue.matchReservation.lastName,
        MobileNumber: userFormValue.matchReservation.mobileNumber,
        CountryID: userFormValue.matchReservation.countryId,
        ReservationNumber: userFormValue.findReservation.reservationNumber
      }
      this.spinner.show();
      this.reservationService.pairReservation(modelRequest).subscribe({
        next: (response) => {
          if(response && response.code == HttpStatusCode.Ok && response.data){
            this.changeStep({"currentStep":"matchReservationStep", "direction":"forward"});
            
          }
          else{
            this.confirmDialogService.alertThis(response.message, '', 'Ok', () =>  {
          
            });
          }
        },
        error: (e) => {
          if(e.error){
            if(e.error.code == HttpStatusCode.InternalServerError || e.error.code == 'UnsupportedApiVersion'){
              this.toastr.error(ErrorMessages.InternalServerError)
            }
            else{
              this.toastr.error(e.error.message);
            }
          }
          else{
            if(e.code == HttpStatusCode.InternalServerError){
              this.toastr.error(ErrorMessages.InternalServerError)
            }
            else{
              this.confirmDialogService.alertThis(e.message, '', 'Ok', () =>  {
            
              });
            }
          }
          this.spinner.hide();
        },
        complete:()=>{
          this.spinner.hide();
        }
      })

      return true;
    }
}
