<app-confirm-dialog></app-confirm-dialog>
<div class="page-box" *ngIf="propertyUnit" style="padding-bottom:24px">
  <div class="banner-with-header" >
    <div class="banner-top">
      <a routerLink="/home/{{reservationId}}" class="backbtn_banner"></a>
      <img [src]="propertyUnit.unitImage" alt="">
    </div>
    <h1 class="page-name">Parking & Directions</h1>
  </div>

  <div class="container">
    <strong class="n-heading mt-2 d-block">You're Staying At: </strong>
    <div class="location-box mt-3" (click)="showLinkPopup()">
      <span style="cursor:pointer">{{propertyUnit.propertyFullAddress}}</span>
    </div>
  </div>
  <div *ngIf="showMap">
    <google-map
      height="400px"
      width="100%"
      [center]="center"
      [zoom]="zoom"
      >
        <map-marker *ngFor="let marker of markers"
          [position]="marker.position"
          [label]="marker.label"
          [title]="marker.title"
          [options]="marker.options"
        >
        </map-marker>
  </google-map>
  </div>
  <div class="getRide" *ngIf="isShowRidePanel">
    
    <h4>Get a Ride</h4>
    <div class="RideOptions">
      <a (click)="redirectToLyft()" target="_blank" class="btn btn-secondary">Lyft <i class="fa-solid fa-chevron-right"></i></a>
      <a (click)="redirectToUber()" class="btn btn-secondary">Uber <i class="fa-solid fa-chevron-right"></i></a>
    </div>
  </div>
</div>
<div class="error-page" *ngIf="!propertyUnit">
  <!-- <div class="container">
    <h1 class="heading-title">No record found.Try again! </h1>
  </div> -->
</div>
<div id="linkPopup" *ngIf="isShowLinkPopup" class="modal" tabindex="-1" role="dialog" style="display:block!important">  
  <div class="modal-dialog custom-alert modal-dialog-centered" role="document">  
      <div class="modal-content">
        <div class="modal-body">  
          <div class="row">  
              <div class="col-md-12">  
                  <ul>
                    <li>Dack</li>
                    <li (click)="addressOnGoogleMap()">Open in Google Map</li>
                    <li (click)="copyAddressToClipboard()">Copy to Clipboard</li>
                    <li (click)="isShowLinkPopup=false">Cancel</li>
                  </ul>
              </div>  
          </div>  
          
        </div>  
      </div>
  </div>
</div>