import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';
import { SupportAppComponent } from './support-app.component';
import { SupportAppRoutingModule } from './support-app-routing.module';

@NgModule({
  imports: [
    SharedModule,
    SupportAppRoutingModule
  ],
  declarations: [
    SupportAppComponent
  ],
  providers: [    
  ]
})
export class SupportAppModule {}
