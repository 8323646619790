import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationService, SpinnerService } from '../core';

@Component({
  selector: 'app-property-amenities',
  templateUrl: './property-amenities.component.html',
  styleUrls: ['./property-amenities.component.scss']
})
export class PropertyAmenitiesComponent implements OnInit {

  reservationId:number = 0;
  unitId:number = 0;
  propertyId:number = 0;
  pageNumber:number = 1;
  propertyAmenities:any;
  isPropertyHaveAmenities:boolean = true;
  throttle = 150; // call after scroll stop 150 mili seconds
  distance = 2; // 20% distance remain from footer
  constructor(
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private spinner: SpinnerService
  ) { 
    this.reservationId = route.snapshot.params['rid'];
    this.unitId = route.snapshot.params['uid'];
    this.propertyId = route.snapshot.params['pid'];
  }

  ngOnInit(): void {
    this.getAmenities(this.pageNumber);
  }

  getAmenities(pageNumber: number){
    this.spinner.show();
    
    this.reservationService.getAmenities(this.reservationId, this.unitId, this.propertyId, pageNumber)
        .subscribe({
          next: (response) =>{
            if(response && response.code == HttpStatusCode.Ok && response.data){
              var amenities = response.data.filter((x:any) => x.disabledAmenityUnitLevelId != 1);
              if(!this.propertyAmenities || this.propertyAmenities.length == 0){
                this.propertyAmenities = amenities; 
              }
              else{
                this.propertyAmenities.push(...amenities);
              }
                         
            }
  
            this.propertyHaveAmenities();
          },
          error: (e) => {
            this.propertyHaveAmenities();
            this.spinner.hide();
          },
          complete: () => this.spinner.hide()
      })
  }

  propertyHaveAmenities()
  {
    if(this.pageNumber == 1 && (!this.propertyAmenities || this.propertyAmenities.length == 0)){
      this.isPropertyHaveAmenities = false;
    }
  }

  onScroll(): void {
    this.getAmenities(++this.pageNumber);    
  }
}
