import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators, SpinnerService, UserService } from '../core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userId: number = 0;
  submitted: boolean = false;

  changePasswordForm= new FormGroup(
    {
      password: new FormControl('',[Validators.required]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#>\$%\^&\*])(?=.{8,})/
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
      userID: new FormControl(0)
    },
    {validators: this.v.passwordMatch('newPassword', 'confirmPassword')}
  );

  constructor(
    private userService: UserService,
    private v: CustomValidators,
    private spinner: SpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.userService.currentUser.subscribe(
      (userData) => {
        this.userId = userData.userID;
      }
    );
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  changePassword(){
    
  }

  submitForm() {

    this.submitted = true;
    this.changePasswordForm.patchValue({
      userID: this.userId
    });


    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.spinner.show();
    this.userService
    .changePassword(this.changePasswordForm.value)
    .subscribe(
      {
        next:(response)=>{
          if(response.code == HttpStatusCode.Ok){
            this.router.navigateByUrl('/profile');            
          }
          else{
            this.toastr.error(response.message);
          }
        },
        error:(e)=>{
          this.submitted = false;
          this.spinner.hide();
          this.toastr.error(e.message);
        },
        complete:()=>this.spinner.hide()
      }
      
    );
  }

}
