import { Component, OnInit, ViewChildren, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService, ErrorMessages, SpinnerService, UserService } from '../core';
import { HttpStatusCode } from '@angular/common/http';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up-step2.component.html',
  styleUrls: ['./sign-up-step2.component.css']
})
export class SignUpStep2Component implements OnInit {

  phoneNumber: string = "";
  phoneNumberWithoutPrefix: string = "";
  phoneNumberCode: string = "";
  form: FormGroup;
  formInput = ['input1', 'input2', 'input3', 'input4'];
  @ViewChildren('formRow') rows: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService,
    private userService: UserService,
    private spinner: SpinnerService
    ) { 

    this.form = this.toFormGroup(this.formInput);
  }

  ngOnInit(): void {
    this.commonService.newGuestPhoneNumberWithCode.subscribe(
      (phone)=>{
        if(phone && phone != ""){
          this.phoneNumber = phone;
        }
        else{
          this.router.navigateByUrl('/sign-up');          
        }   
      }
    );
    this.commonService.newGuestPhoneNumber.subscribe(
      (phone)=>{
        if(phone && phone != ""){
          this.phoneNumberWithoutPrefix = phone;
        }
        else{
          this.router.navigateByUrl('/sign-up');          
        }   
      }
    );
    this.commonService.newGuestPhoneNumberCode.subscribe(
      (phoneCode)=>{
        if(phoneCode && phoneCode != ""){
          this.phoneNumberCode = phoneCode;
        }
        else{
          this.router.navigateByUrl('/sign-up');          
        }   
      }
    );
    
  }


  toFormGroup(elements: any) {
    const group: any = {};

    elements.forEach((key:any) => {
      group[key] = new FormControl('', Validators.required);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event: any, index: number) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      pos = index + 1 ;
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }

  }
  
  VerifyPhoneCode(){

    var code = "";
    Object.keys(this.form.value).forEach(e => 
      code += this.form.value[e]
    );

    if(this.form.invalid || code.length != 4){
      this.toastr.info('Please fill all fields.')
      return;
    }

    this.spinner.show();
    
    this.userService
          .verifyPhoneCode({phoneNumber:this.phoneNumber, code:code})
          .subscribe({
              next:(response) => {      
                if(response && response.code == HttpStatusCode.Ok && response.data){
                  if(response.data.isVerificationCodeConfirmed){
                    this.router.navigateByUrl('/sign-up-detail');
                  }
                  else{
                    this.toastr.warning(response.data.userMessageOnError);
                  }
                }
                else{
                  this.toastr.warning(ErrorMessages.InternalServerError);
                }                
              },
              error:(e) => {
                this.spinner.hide();
                this.toastr.error('Invalid code');
              },
              complete:() => this.spinner.hide()
            });
    
  }

  resendPhoneVerificationCode(){
    this.userService
        .verifyPhone({phoneNumber:this.phoneNumberWithoutPrefix,countryPhonePrefix:this.phoneNumberCode})
        .subscribe({
            next:(response)=>{    
              if(response && response.code == HttpStatusCode.Ok && response.data){
                if(response.data.isVerificationSent){
                  this.toastr.info("A OTP is send to your phone number.");
                  
                }
                else{
                  this.toastr.warning(response.data.userMessageOnError, ErrorMessages.InternalServerError);
                }                        
              }
              else{
                this.toastr.warning(ErrorMessages.InternalServerError);
              }
            }
          });
  }
}
