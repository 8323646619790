<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/profile" class="back-page"></a>
        <h2>Update Password</h2>
      </div>
    </div>
  </div>
  
  <div class="title-bar-space d-block">
  
      <div class="container">
          <form class="mt-5"  [formGroup]="changePasswordForm"  (ngSubmit)="submitForm()" autocomplete="off">

            <div class="form-group d-block mt-4">
                <label class="form-label">Old Password</label>
                <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off">
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Old Password is required</div>
                </div>
                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">New Password</label>
                <input type="password" class="form-control" maxlength="20" formControlName="newPassword" [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" autocomplete="off">
                <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.newPassword.errors.required">New Password is required</div>
                    <div *ngIf="f.newPassword.errors.minlength">New Password must be at least 8 characters</div>
                </div>
                <div class="password-info">
                    <p [ngClass]="{ 'text-danger': submitted && f.newPassword.errors && f.newPassword.errors?.pattern}">It should contain(s) a number, special character (#,!,>) and a capital letter (L)</p>              
                </div>
                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Confirm New Password</label>
                <input type="password" class="form-control" maxlength="20" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" autocomplete="off">
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="f.confirmPassword.errors.passwordMismatch">New Password and confirm not match</div>
                </div>
              </div>
            <button class="btn btn-primary w-100 mt-5">Update</button>
          </form>
      </div>
  
</div>