import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsPageType, CommonApiService, SpinnerService } from 'src/app/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-guest-verification',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  reservationId:number = 0;
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private commonApiService: CommonApiService,
    private spinner: SpinnerService,
    private route: ActivatedRoute,
    private fb: FormBuilder
    ) { 
      this.reservationId = this.route.snapshot.params['id'];
      
    }


  // isShowCamera: boolean = false;
  // imageCaptured: boolean = false;
  // adjustImage: boolean = false;
  // private triggerCamera: Subject<any> = new Subject();
  // public webcamImage!: WebcamImage;
  // private nextWebcam: Subject<any> = new Subject();
  // sysImage = '';
  // imageChangedEvent: any = '';
  // croppedImage: any = '';

  // isCameraAvaialable: boolean = true;
  // cameraErrorText: string = "";
  // width: number = 640;
  // height: number = 480;

  ngOnInit(): void {
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event?: Event) {
  //   const win = !!event ? (event.target as Window) : window;
  //   this.width = win.innerWidth-50;
  //   this.height = win.innerHeight;
  // }

  showCommonTermsCondition(){
    this.showCommonTermsConditions.emit(true);
  }
 
  doChangeStep() {
    
    this.changeChildStep.emit({"currentStep":"landingInfoStep", "direction":"forward"});
  }
 

  // useCamera(){
  //   this.isShowCamera = true;
  // }
  // public getSnapshot(): void {
  //   this.triggerCamera.next(void 0);
  // }
  // public captureImg(webcamImage: WebcamImage): void {
  //   this.webcamImage = webcamImage;
  //   this.sysImage = webcamImage!.imageAsDataUrl;
  //   var base64Image = this.sysImage.split('base64,');
  //   if(base64Image.length>1){
  //     console.log(base64Image[1])

  //   }
  //   this.imageCaptured = true;
  //   this.isShowCamera = false;
  // }

  // public retryImage(){
  //   this.imageCaptured = false;
  //   this.isShowCamera = false;

  // }

  // public adjustingImage(){
  //   this.imageCaptured = false;
  //   this.adjustImage = true;
  // }


  // public get invokeObservable(): Observable<any> {
  //   return this.triggerCamera.asObservable();
  // }
  

  // public handleInitError(error: WebcamInitError): void {

  //   this.isCameraAvaialable = false;

  //   if(error.mediaStreamError && error.mediaStreamError.name === "NotFoundError"){
  //     this.isCameraAvaialable = false;
  //     this.cameraErrorText = "We could not connect to your selected Camera Device!";
  //   }
  //   if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
  //     this.cameraErrorText = "Please provide access of your Camera Device!";      

  //   }
  // }
  

  // imageCropped(event: ImageCroppedEvent) {    
  //   this.croppedImage = event.base64;
  // }
  // imageLoaded(image: LoadedImage) {
  //     // show cropper
  // }
  // cropperReady() {
  //     // cropper ready
  // }
  // loadImageFailed() {
  //     // show message
  // }

  
}
