<div class="title-bar">
  <div class="container">
    <div class="title-bar-box">
      <a routerLink="/home/{{reservationId}}" class="back-page"></a>
      <h2>Property Guide</h2>
    </div>
  </div>
</div>
<div class="title-bar-space d-block">
  <div class="container" *ngIf="propertyGuides">
    <nav class="nav-links mt-3">
      <ul>
        <li *ngFor="let property of propertyGuides"><a routerLink="/property-detail/{{property.unitStayDetailItemID}}">{{ property.unitStayDetailName}}</a></li>          
      </ul>
    </nav>
  </div>
  <div class="error-page" *ngIf="!propertyGuides">
    <!-- <div class="container">
      <h1 class="heading-title">No record found.Try again! </h1>
    </div> -->
  </div>
  
</div>       
  