import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';

import {
  ApiService,
  AuthGuard,
  JwtService,
  UserService,
  OperatorService,
  ReservationService,
  SpinnerService,
  UpsellService,
  RecommendationService,
  CommonApiService,
  TransactionService,
  PhoneVerifyApiService,
  SupportService,
  CheckInService,
  SurveyService
} from './services';

import { MixpanelService } from './services/mixpanel.service';
import { CustomValidators } from './vailidaters/custom-validators';
import { PropertyService } from './services/property.service';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    PhoneVerifyApiService,
    AuthGuard,
    JwtService,
    UserService,
    OperatorService,
    ReservationService,
    CustomValidators,
    PropertyService,
    SpinnerService,
    UpsellService,
    RecommendationService,
    CommonApiService,
    TransactionService,
    SupportService,
    CheckInService,
    SurveyService,
    MixpanelService
  ],
  declarations: [
  ]
})
export class CoreModule { }
