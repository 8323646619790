import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class TransactionService {

    constructor(private apiService: ApiService){

    }

    purchaseDamageWaiver(formValue:any){
        return this.apiService.post('/v1/transactions/PurchaseDamageWaiver', formValue)
          .pipe(map(
            response => {          
              return response;
            }
        ));
      }

    purchaseSecurityDeposit(formValue:any){
      return this.apiService.post('/v1/transactions/PurchaseSecurityDeposit', formValue)
        .pipe(map(
          response => {          
            return response;
          }
      ));
    }

    purchaseUpsellItemDetail(id:number){
      return this.apiService.get('/v5/transactions/UpsellItem?UpsellRequestDetailId='+ id)
      .pipe(map(
        response => {          
          return response;
        }
      ));
    }

    purchaseSecurityItemDetail(id:number){
      return this.apiService.get('/v1/transactions/SecurityDepositItem?SecurityDepositId='+ id)
      .pipe(map(
        response => {          
          return response;
        }
      ));
    }

    purchaseDamageWaiverItemDetail(id:number){
      return this.apiService.get('/v1/transactions/DamageWaiverItem?DamageWaiverId='+ id)
      .pipe(map(
        response => {          
          return response;
        }
      ));
    }
}