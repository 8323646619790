import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { OnlyNumberDirective } from '../core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PaymentComponent } from '../payment/payment.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ConfirmDialogModule,
    CarouselModule,
    InfiniteScrollModule
  ],
  declarations: [
    ListErrorsComponent,
    ShowAuthedDirective,
    OnlyNumberDirective,
    PaymentComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ListErrorsComponent,
    RouterModule,
    ShowAuthedDirective,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ConfirmDialogModule,
    OnlyNumberDirective,
    CarouselModule,
    InfiniteScrollModule,
    PaymentComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
