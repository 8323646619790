import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogService, ErrorMessages, ReservationService, SpinnerService, UserService } from 'src/app/core';

@Component({
  selector: 'app-find-reservation',
  templateUrl: './find-reservation.component.html',
  styleUrls: ['./find-reservation.component.scss']
})
export class FindReservationComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() startingForm: FormGroup;
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  findReservationInfoForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private reservationService: ReservationService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private spinner: SpinnerService
  ) { 
    this.startingForm = this.findReservationInfoForm = this.fb.group({
      reservationNumber: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.subformInitialized.emit(this.findReservationInfoForm);
  }

  get f(){
    return this.findReservationInfoForm.controls;
  }

  doChangeStep() {
    this.submitted = true;
    if(this.findReservationInfoForm.invalid){
      return;
    }

    var reservationFormValue = this.findReservationInfoForm.value;
    this.spinner.show();
    this.reservationService.checkReservationByNumber(reservationFormValue.reservationNumber).subscribe({
      next:(response)=>{
        if(response && response.data && response.code == HttpStatusCode.Ok){
         
          this.changeChildStep.emit({"currentStep":"findReservationStep", "direction":"forward", "maskedMobileNumber":response.data.maskedMobileNumber});
        }
      },
      error:(e)=>{
        if(e.code == HttpStatusCode.Conflict){
          this.confirmDialogService.confirmThis('This reservation is already synced with an existing DACK user. The primary guest can add you to his party so you can access the unit\'s features. ', 'Primary Guest Exists', 'Log out','Ok', 
          () =>  {
            this.userService
                .logOut()
                .subscribe(
                  (response)=>{
                    if(response && response.code == HttpStatusCode.Ok){
                      this.userService.purgeAuth();
                      this.router.navigateByUrl('/');        
                    }
                  }
                );
          },
          () => {

          });
          
        }
        else{
          this.confirmDialogService.confirmThis('Try a different reservation number or request support', 'No Reservation Found', 'Support','Try Again', 
          () =>  {
            this.router.navigateByUrl('/support-app');
          },
          () => {

          });
        }
        this.spinner.hide();
      },
      complete:()=>{
        this.spinner.hide();
      }
    })
  }
}
