import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ReservationService, SpinnerService } from '../core';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-property-amenity',
  templateUrl: './property-amenity.component.html',
  styleUrls: ['./property-amenity.component.scss']
})
export class PropertyAmenityComponent implements OnInit {

  propertyAmenity:any;
  propertyAmenityId:number = 0;
  reservationId:number = 0;
  returnPage:string = "";
  wifiName:string = "";
  wifiPassword:string = "";
  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private reservationService: ReservationService,
    private location: Location,
    private router: Router,
    private clipboardApi: ClipboardService,
    private toastr: ToastrService
  ) { 
    this.propertyAmenityId = this.route.snapshot.params['pid'];
    this.reservationId = this.route.snapshot.params['rid'];
    this.returnPage = this.route.snapshot.params['rpage'];
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,    
    items:1,
    autoplay:true
  };

  ngOnInit(): void {    
    this.getPropertyAmenity();
  }

  getPropertyAmenity(){
    this.reservationService.getAmenity(this.propertyAmenityId)
    .subscribe({
      next: (response) =>{
        if(response && response.code == HttpStatusCode.Ok && response.data){
          this.propertyAmenity = response.data; 
                     
        }

      },
      error: (e) => {
        this.spinner.hide();
      },
      complete: () => this.spinner.hide()
  })
  }
  
  goToBack() {    
    if(this.returnPage && this.returnPage.indexOf('default')!=-1){
      this.router.navigateByUrl(`/home/${this.reservationId}`)
    }else{
      this.location.back();    
    }
  }

  copyCredential(){
    
    if(this.propertyAmenity.yourNetwork && this.propertyAmenity.yourNetwork.wifiName && this.propertyAmenity.yourNetwork.password){
      var credential = "Wi-Fi Name:"+ this.propertyAmenity.yourNetwork.wifiName + "\n Password:"+ this.propertyAmenity.yourNetwork.password
      this.clipboardApi.copyFromContent(credential);
      this.toastr.info("Your wifi details has been copied!");
    }
    
  }
}
