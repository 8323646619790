import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryCode, UserService, ReservationService, CommonService, SpinnerService, ErrorMessages } from '../core';
import { HttpStatusCode } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up-step1.component.html',
  styleUrls: ['./sign-up-step1.component.css']
})
export class SignUpStep1Component implements OnInit {

  signUpPhoneForm: FormGroup;
  countryCodes: any;
  selectedCountryCode: string = '';
  selectedCountryFlag: string = '';
  acceptTermsAndConditions: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private spinner: SpinnerService,
    private reservationService: ReservationService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService
  ) {

    this.signUpPhoneForm = this.fb.group({
      phoneNumber: ['', Validators.required],
      countryId: [0],
      acceptTermsAndConditions: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userService.getCountryCode().subscribe(
      {
        next: (data) => {
          if (data) {
            this.countryCodes = data;
            let defaultCountry = this.countryCodes.find((x: any) => x.countryCode == '+1')
            if (defaultCountry) {
              this.signUpPhoneForm.patchValue({
                countryId: defaultCountry.countryID
              });
              this.selectedCountryCode = defaultCountry.countryCode;
              this.selectedCountryFlag = defaultCountry.countryCodeImage;
            }
            else {
              this.signUpPhoneForm.patchValue({
                countryId: this.countryCodes[0].countryID
              });
              this.selectedCountryCode = this.countryCodes[0].countryCode;
              this.selectedCountryFlag = this.countryCodes[0].countryCodeImage;

            }
          }
        },
        error: (e) => {
          this.spinner.hide()
        },
        complete: () => this.spinner.hide()
      }
    );
    this.mixpanelService.sendScreenVisitEvent("SignupEnterPhoneNumber")
  }

  changeAcceptanceOfTermsConditions() {
    this.acceptTermsAndConditions = !this.acceptTermsAndConditions;

    this.signUpPhoneForm.patchValue({
      acceptTermsAndConditions: this.acceptTermsAndConditions
    })
  }
  CheckReservationAndSendCode() {

    let phoneNumber = this.signUpPhoneForm.get('phoneNumber')?.value;
    let selectedCountryId = this.signUpPhoneForm.get('countryId')?.value;
    let countryInfo = this.countryCodes.find((x: any) => x.countryID == selectedCountryId)

    if (phoneNumber && selectedCountryId) {
      if (this.acceptTermsAndConditions) {
        this.reservationService.checkReservationByPhone(phoneNumber, selectedCountryId).subscribe(
          (data) => {
            if (data && data.isUserExist) {
              this.toastr.info("Guest with this mobile number already registered with us.");
              setTimeout(() => {
                this.router.navigateByUrl('/login');
              }, 2000);
            }
            else {
              this.spinner.show();
              this.userService
                .verifyPhone({ phoneNumber: phoneNumber, countryPhonePrefix: countryInfo.countryCode })
                .subscribe({
                  next: (response) => {
                    if (response && response.code == HttpStatusCode.Ok && response.data) {
                      if (response.data.isVerificationSent) {
                        this.commonService.setPhoneNumberWithCode(countryInfo.countryCode + phoneNumber)
                        this.commonService.setPhoneNumber(phoneNumber);
                        this.commonService.setPhoneNumberCode(countryInfo.countryCode);
                        this.commonService.setCountryID(selectedCountryId);
                        this.router.navigateByUrl('/verify-phone');
                      }
                      else {
                        this.toastr.warning(response.data.userMessageOnError, ErrorMessages.InternalServerError);
                      }
                    }
                    else {
                      this.toastr.warning(ErrorMessages.InternalServerError);
                    }
                  },
                  error: (e) => {
                    this.toastr.warning(ErrorMessages.InternalServerError);
                    this.spinner.hide();
                  },
                  complete: () => this.spinner.hide()
                });

            }
          }
        )
      }
      else {
        this.toastr.info("Please accept Terms and Conditions and Privacy Policy.")
      }

    }
    else {
      this.toastr.info("Please enter mobile number.")
    }

  }

  onCountryChange(event: any) {
    var changedValueArr = event.target.value;
    var changedValue = changedValueArr.split(':')[0]
    if (this.countryCodes && this.countryCodes.length > 0) {
      var selectedCountry = this.countryCodes.find((x: any) => x.countryID == changedValue)
      if (selectedCountry) {
        this.selectedCountryCode = selectedCountry.countryCode;
        this.selectedCountryFlag = selectedCountry.countryCodeImage;
      }
    }
  }


}
