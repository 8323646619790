import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable() export class ConfirmDialogService {
    private subject = new Subject<any>();

    confirmThis(message: string,title: string, confirmText: string, cancelText: string, yesFn: () => void, noFn: () => void): any {
        this.setConfirmation(message, title, confirmText, cancelText, yesFn, noFn);
    }

    alertThis(message: string,title: string, confirmText: string, yesFn: () => void): any {
        this.setAlert(message, title, confirmText, yesFn);
    }

    setConfirmation(message: string, title: string, confirmText: string, cancelText: string, yesFn: () => void, noFn: () => void): any {
        const that = this;
        this.subject.next({
            type: 'confirm',
            text: message,
            title: title,
            confirmBtnText: confirmText,
            cancelBtnText: cancelText,
            yesFn(): any {
                    that.subject.next(null); // This will close the modal
                    yesFn();
                },
            noFn(): any {
                that.subject.next(null);
                noFn();
            }
        });

    }

    setAlert(message: string, title: string, confirmText: string, yesFn: () => void): any {
        const that = this;
        this.subject.next({
            type: 'alert',
            text: message,
            title: title,
            confirmBtnText: confirmText,
            yesFn(): any {
                    that.subject.next(null); // This will close the modal
                    yesFn();
                }
           
        });

    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
}