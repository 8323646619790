import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReservationService, ReservationStatusEnum, SpinnerService } from '../core';
import { MixpanelService } from '../core/services/mixpanel.service';

@Component({
  selector: 'app-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.scss']
})
export class PassportComponent implements OnInit {

  reservationId: number = 0;
  passportDetail: any;
  reservationStatus = ReservationStatusEnum;
  qrCodeUrl: string = '';
  constructor(
    private route: ActivatedRoute,
    private reservationService: ReservationService,
    private spinner: SpinnerService,
    private mixpanelService: MixpanelService
  ) {
    this.reservationId = this.route.snapshot.params['rid'];
  }

  ngOnInit(): void {
    this.getPassportDetail();
    this.mixpanelService.sendScreenVisitEvent("PassportDetail");
  }

  getPassportDetail() {
    this.spinner.show();
    this.reservationService.getReservationPassport(this.reservationId)
      .subscribe({
        next: (response) => {
          if (response && response.code == HttpStatusCode.Ok) {
            this.passportDetail = response.data;
            if (this.passportDetail.reservationStatus == this.reservationStatus[ReservationStatusEnum.InStay] && this.passportDetail.qrCodeUrl) {
              this.qrCodeUrl = this.passportDetail.qrCodeUrl.replace('/qr/', '/');
            }
          }
        },
        error: (e) => {
          this.spinner.hide();
        },
        complete: () => this.spinner.hide()
      })
  }
}
