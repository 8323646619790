<app-confirm-dialog></app-confirm-dialog>
<div class="title-bar">
    <div class="container">
      <div class="title-bar-box">
        <a routerLink="/home/{{reservationId}}" class="back-page"></a>
        <h2>Guest Information</h2>
      </div>
    </div>
  </div>

  <div class="title-bar-space d-block footer_space">
  
      <div class="container">
        <form class="mt-5"  [formGroup]="guestForm"  (ngSubmit)="submitForm()" autocomplete="off">
            <div class="form-group d-block mt-4">
                <label class="form-label">First Name</label>
                <input type="text" class="form-control" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors['required']">First Name is required</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Last Name</label>
                <input type="text" class="form-control" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors['required']">Last Name is required</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Email</label>
                <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" autocomplete="off" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors['required']">Email is required</div>
                    <div *ngIf="f.email.errors['email'] || f.email.errors['pattern']">Email must be a valid email address</div>
                </div>                
            </div>
            <div class="form-group d-block mt-4">
                <label class="form-label">Phone</label>
                <div class="with-country-code">
                    <span class="custom-select">                                
                        <img [src]="selectedCountryFlag" *ngIf="selectedCountryFlag" alt="" class="country-flag" />
                        <input type="text" value="{{selectedCountryCode}}" class="selectform_control" />
                        <select class="form-control" formControlName="countryId" (change)="onCountryChange($event)">
                            <option *ngFor="let country of countryCodes" [ngValue]="country.countryID" >{{country.countryName}}</option>
                        </select>
                    </span>
                    <input type="text" class="form-control" formControlName="phoneNumber"  [maxLength]="13" numbersOnly [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" autocomplete="off" />
                </div>
                
                <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNumber.errors['required']">Phone is required</div>
                    <div *ngIf="f.phoneNumber.errors['minlength']">Invalid phone number</div>
                </div>                    
            </div>
            
            
            
          <div class="form-group mt-5" >
            <button class="btn btn-primary w-100" *ngIf="reservationAdditionalGuestId==0">Invite Guest</button>
            <ng-container *ngIf="!primaryGuest && guest">
                <button class="btn btn-primary w-100" *ngIf="reservationAdditionalGuestId!=0">Update Guest</button>
                <button class="btn btn-secondary w-100 mt-3 red-text" type="button" *ngIf="reservationAdditionalGuestId!=0" (click)="removeAdditionalGuest()">Delete Guest</button>
                
            </ng-container>
          </div>
        </form>
          
      </div>
    
  </div>