import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CheckInModuleStageEnum, CheckInService, ConfirmDialogService, SpinnerService } from 'src/app/core';

@Component({
  selector: 'app-arrival-time',
  templateUrl: './arrival-time.component.html',
  styleUrls: ['./arrival-time.component.scss']
})
export class ArrivalTimeComponent implements OnInit {

  @Input() reservationId: number = 0;
  @Input() unitId: number = 0;
  @Input() arrivalTimeStageSkip:boolean = false;
  @Input() checkInForm:any;
  @Output() changeChildStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() skipParentModuleStep: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCommonTermsConditions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() subformInitialized: EventEmitter<any> = new EventEmitter<any>();
  @Output() getPreviousCheckInSequence: EventEmitter<any> = new EventEmitter<any>();
  checkInModuleStageEnum = CheckInModuleStageEnum
  arrivalTimeInfo:any;
  activeTab:number = 0;
  arrivalForm= new FormGroup({
    reservationId: new FormControl(0, Validators.required),
    arrivalTimeId: new FormControl(0, Validators.required),
  });
  public arrivalInfoForm: FormGroup;

  tabSelected:boolean = false;
  selectedArrivalTimeId:number = 0;

  constructor(
    private checkInService: CheckInService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
    private toastr: ToastrService,
    private fb:FormBuilder,
    private spinner: SpinnerService
  ) { 
    this.arrivalInfoForm = this.fb.group({
      arrivalTimeId: new FormControl(0, [Validators.required]),
      arrivalPrice: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.arrivalForm.patchValue({
      reservationId: this.reservationId
    })
    this.getArrivalTimeInfo();
    this.subformInitialized.emit(this.arrivalInfoForm);

    if(this.checkInForm && this.checkInForm.value && this.checkInForm.value.arrivalInfo && this.checkInForm.value.arrivalInfo.arrivalTimeId){
      this.selectedArrivalTimeId = this.checkInForm.value.arrivalInfo.arrivalTimeId
      this.arrivalForm.patchValue({
        arrivalTimeId: this.selectedArrivalTimeId
      });
      this.arrivalInfoForm.patchValue({
        arrivalTimeId: this.selectedArrivalTimeId,
        arrivalPrice: this.checkInForm.value.arrivalInfo.arrivalPrice
      });
    }
  }

  getArrivalTimeInfo(){
    this.checkInService.getArrivalTimeInfo(this.reservationId)
        .subscribe({
          next:(response) => {
              if(response && response.code == HttpStatusCode.Ok && response.data){
                this.arrivalTimeInfo = response.data.arrivalTimes;  
                if(!(this.checkInForm && this.checkInForm.value && this.checkInForm.value.arrivalInfo && this.checkInForm.value.arrivalInfo.arrivalTimeId)){
                  this.changeTab(0, this.arrivalTimeInfo[0].arrivalTimeId, this.arrivalTimeInfo[0].subLabel)
                }              
              }
          },
          error:(e) => {

          }
        })
  }

  changeTab(tabIndex:number, arrivalTimeId: number, price:any){
    this.activeTab = tabIndex;
    this.tabSelected = true;
    this.selectedArrivalTimeId = arrivalTimeId;
    this.arrivalForm.patchValue({
      arrivalTimeId: arrivalTimeId
    });
    this.arrivalInfoForm.patchValue({
      arrivalTimeId: arrivalTimeId,
      arrivalPrice: price
    });
  }

  selectArrivalTime(){
    if(this.arrivalForm.invalid){
      return;
    }
    if(this.arrivalForm.value.arrivalTimeId == 0){
      this.confirmDialogService.alertThis('You need to select an option before continuing.','','Ok',()=>{})
      return;
    }

    this.spinner.show();

    this.checkInService.selectArrivalTime(this.arrivalForm.value)
        .subscribe({
          next:(response) => {
            if(response && response.code == HttpStatusCode.Ok){
              this.skipParentModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.ArrivalTime],"stepValue":CheckInModuleStageEnum.ArrivalTime,"action":"complete"});
            }
          },
          error:(e) => {
            this.toastr.error(e.message);
            this.spinner.hide();
          },
          complete:()=>this.spinner.hide()
        })
  }

  goToBack(){
    this.getPreviousCheckInSequence.emit();
  }

  skipModule(){
    this.skipParentModuleStep.emit({"step":this.checkInModuleStageEnum[CheckInModuleStageEnum.ArrivalTime],"stepValue":CheckInModuleStageEnum.ArrivalTime});
  }

  
}
