<ng-container *ngIf="propertyUnit">
  <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.status && (propertyUnit.checkInSequence.securityTransactions.status=='DamageWaiverReceived' || propertyUnit.checkInSequence.securityTransactions.status=='SecurityDepositReceived'); then purchasedResult else toPurchaseContent"></ng-container>
    <ng-template #toPurchaseContent>
      <div class="title-bar">
        <div class="container">
          <div class="title-bar-box">
            <!-- <a href="javascript:void(0)" class="help_icon" (click)="showCommonTermsCondition()"></a> -->
            <a (click)="goToPage('home')" class="back-page"></a>
            <h2>Deposit</h2>
            <a class="skip" (click)="skipModule()" *ngIf="securityTransactionStageSkip">SKIP</a>
            <!-- <a (click)="goToPage('contact-host')" class="help_icon"></a> -->
          </div>
        </div>
      </div>
    
      <div class="title-bar-space d-block footer_space">
      
          <div class="container">
            <div class="support-host">
              <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled && propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled; then showBothDamageDepositTemplate else findOtherTemplate"></ng-container>
              <ng-template #findOtherTemplate>
                <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled && !propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled; then showDamageWaiverTemplate else showDepositTemplate"></ng-container>
              </ng-template>
              <ng-template #showBothDamageDepositTemplate>
                <h2 class="mt-3 supportmTitle">Your host requires a security deposit</h2>
                <h2 class="mt-3 mb-4 supportmTitle">Alternatively you can pay a one-time damage waiver fee.</h2>
              </ng-template>
              <ng-template #showDamageWaiverTemplate>
                <h2 class="mt-3 mb-4 supportmTitle">Your host requires a damage waiver at this property.</h2>
              </ng-template>
              <ng-template #showDepositTemplate>
                <h2 class="mt-3 mb-4 supportmTitle">Your host requires a one time damage waiver fee at this property.</h2>
              </ng-template>
                
                <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.isDamageWaiverEnabled">
                    <div class="linkBox outlined">
                        <!-- <a routerLink="/damage-waiver/payment/{{this.reservationId}}/{{this.unitId}}" class="anchor_cover"></a>                 -->
                        <a (click)="doChangeStep('damageWaiverPayment')" class="anchor_cover"></a>
                        <span class="linkBox_content">
                          <strong>Pay Damage Waiver</strong>
                        </span>
                      </div>
                    <p class="mt-2 mb-3 smptext">This is a non-refundable damage waiver fee that covers up to ${{damageWaiverCoverageAmount}} in accidental damage at the property during your stay.</p>
                </ng-container>
                <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.isSecurityDepositEnabled">
                    <div class="linkBox outlined">
                        <!-- <a routerLink="/deposit-waiver/payment/{{this.reservationId}}/{{this.unitId}}" class="anchor_cover"></a>                 -->
                        <a (click)="doChangeStep('depositWaiverPayment')" class="anchor_cover"></a>
                        <div class="linkBox_content">
                          <strong>Pay Deposit</strong>
                        </div>
                      </div>
                      <p class="mt-2 mb-3 smptext">
                        This is a ${{securityDepositFee}} hold placed on your credit card or account that will be released 7 days after checkout.
                        Any damages during your stay may be withheld from this amount.
                    </p>
                </ng-container>
            </div>
            
          </div>
        
      </div>
    </ng-template>
    <ng-template #purchasedResult>
      <div class="center_flex" style="min-height:calc(100vh - 150px)">
        <div class="w-100">
          <div class="container">
            <div class="mt-5 d-block text-center"><img src="../../assets/images/check_tick.svg" alt="check"/></div>
             <div class="request_result_box mt-5 text-start">
                <p><a href="javascript:void(0)" class="btn btn-sm btn-secondary receivebtn request-result-approved"><img src="../../assets/images/doller-icon.svg" alt="icon" />Approved</a></p>
                <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.status == 'DamageWaiverReceived'">
                  <p class="mt-3"><strong>Damage Waiver</strong></p>
                  <p class="sub-title colordark">Your host received your damage waiver. This covers accidental damages that may occur during your stay.</p>
                  <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span>${{propertyUnit.checkInSequence.securityTransactions.damageWaiverFee}}</span></p>
                </ng-container>
                <ng-container *ngIf="propertyUnit.checkInSequence.securityTransactions.status == 'SecurityDepositReceived'">
                  <p class="mt-3"><strong>Security Deposit</strong></p>
                  <p class="sub-title colordark">Your funds are now on hold. These funds will be released 7 days after your stay unless damage occurs at the property.</p>
                  <p class="sub-title colordark"><strong>Total :</strong> &nbsp;<span>${{propertyUnit.checkInSequence.securityTransactions.securityDepositFee}}</span></p>
                </ng-container>
                                
    
              </div>
          </div>
        </div>
        <div class="w-100">
          <div class="container">
            <a (click)="moduleComplete()" class="btn btn-primary mt-5 w-100">Done</a>
          </div>
        </div>
      </div>
    </ng-template>
    
  </ng-container>
  
  